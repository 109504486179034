import AddScheduledTask, { introductionIdentifier as addScheduledTaskIntroductionIdentifier } from '../pages/app/scheduled-tasks/add-scheduled-task';
import ScheduledTasks from '../pages/app/scheduled-tasks';
import StandardLoader from './standard-loader';

export default function ScheduledTasksRouter() {
    return {
        path: "/app/:appUUID/scheduled-tasks",

        children: [
            {
                index: true,
                element: <ScheduledTasks />,
                loader: (props) => StandardLoader({
                    ...props,
                    crumbs: [
                        {
                            "name": "Scheduled Tasks Management",
                        },
                    ]
                }),
            },

            {
                path: "/app/:appUUID/scheduled-tasks/add",

                children: [
                    {
                        index: true,
                        element: <AddScheduledTask />,
                        loader: (props) => StandardLoader({
                            ...props,
                            crumbs: [
                                {
                                    "name": "Scheduled Tasks Management",
                                },
                                {
                                    "name": "Add New Scheduled Task"
                                }
                            ],
                            introductionIdentifier: addScheduledTaskIntroductionIdentifier,
                        }),
                    },
                ]
            }
        ]
    };
}