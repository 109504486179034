const cronTimeOptions = {
    months: [
        { value: 1, label: "January" },
        { value: 2, label: "February" },
        { value: 3, label: "March" },
        { value: 4, label: "April" },
        { value: 5, label: "May" },
        { value: 6, label: "June" },
        { value: 7, label: "July" },
        { value: 8, label: "August" },
        { value: 9, label: "September" },
        { value: 10, label: "October" },
        { value: 11, label: "November" },
        { value: 12, label: "December" },
    ],
    days: [...Array(31).keys()].map((i) => ({
        value: i + 1,
        label: `${String(i + 1).padStart(2, '0')}`,
    })),
    weekDays: [
        { value: 0, label: "Sunday" },
        { value: 1, label: "Monday" },
        { value: 2, label: "Tuesday" },
        { value: 3, label: "Wednesday" },
        { value: 4, label: "Thursday" },
        { value: 5, label: "Friday" },
        { value: 6, label: "Saturday" },
    ],
    hours: [...Array(24).keys()].map((i) => ({
        value: i,
        label: `${String(i).padStart(2, '0')}`,
    })),
    minutes: [...Array(4).keys()].map((i) => ({
        value: i * 15,
        label: `${String(i * 15).padStart(2, '0')}`,
    })),
};

export default cronTimeOptions;