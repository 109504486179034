const SupportedWorkflowActions = {
    "reports": {
        "subKey": "", // We need to look at the action_configuration to get the subkey

        "default": {
            name: () => "Select the report to generate",
            description: "",
        },

        "render_report": {
            name: () => "Generate Report HTML",
            description: (mappedData) => mappedData?.name,
        },

        "save_as_pdf": {
            name: () => "Generate & Save Report PDF",
            description: (mappedData) => mappedData?.name,
        },
    },

    "communication": {
        "subKey": "type",
        "email": {
            name: () => "Send Email",
            description: (mappedData) => mappedData?.to.reduce((acc, email) => acc + email + ", ", "").slice(0, -2),
        },
    },

    "query": {
        "subKey": "operation",

        // This is the default for before the query is selected by the user
        "default": {
            name: () => "Run Query From Playground",
            description: "",
        },

        "read": {
            name: () => "Run Read Query From Playground",
            description: (mappedData) => mappedData?.name,
        },

        "aggregate": {
            name: () => "Run Aggregate Query From Playground",
            description: (mappedData) => mappedData?.name,
        },
    }
}

export default SupportedWorkflowActions;