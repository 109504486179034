export default function GenerateCronString({
    frequency,
    months,
    days,
    weekDays,
    hours,
    minutes,
}) {
    let cron = "*/15 * * * *"; // Defaults to every 15 minutes

    switch (frequency?.value) {
        case "minute": {
            cron = `*/15 * * * *`;
            break;
        }

        case "hour": {
            cron = `${minutes.map(opt => opt.value).join(",") || "0"}`;
            cron += " * * * *";
            
            break;
        }

        case "day": {
            cron = `${minutes.map(opt => opt.value).join(",") || "0"}`;
            cron += ` ${hours.map(opt => opt.value).join(",") || "*"}`;
            cron += " * * *";
            
            break;
        }

        case "week": {
            cron = `${minutes.map(opt => opt.value).join(",") || "0"}`;
            cron += ` ${hours.map(opt => opt.value).join(",") || "*"}`
            cron += ` * *`;
            cron += ` ${weekDays.map(opt => opt.value).join(",") || "*"}`;
            
            break;
        }

        case "month": {
            cron = `${minutes.map(opt => opt.value).join(",") || "0"}`;
            cron += ` ${hours.map(opt => opt.value).join(",") || "*"}`;
            cron += ` ${days.map(opt => opt.value).join(",") || "*"}`;
            cron += ` * *`;
            
            break;
        }

        case "year": {
            cron = `${minutes.map(opt => opt.value).join(",") || "0"}`;
            cron += ` ${hours.map(opt => opt.value).join(",") || "*"}`;
            cron += ` ${days.map(opt => opt.value).join(",") || "*"}`;
            cron += ` ${months.map(opt => opt.value).join(",") || "*"}`;
            cron += ` *`;
            
            break;
        }

        default: {
            break;
        }
    }

    return cron;
}