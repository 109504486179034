import React from "react";
import moment from "moment-timezone";
import { Link } from "react-router-dom";

import { connect } from "react-redux";

import axiosBackend from "../../../core/api/backend";

import withLoaderData from "../../../components/withLoaderData";

import * as DataDelegator from "../../../components/smart/delegator/DataDelegator";

import cronstrue from 'cronstrue';

import drawFlowchart from "./workflow/drawFlowchart";
import isMouseInComponent from "./workflow/utils/is-mouse-in-component";
import getMousePos from "./workflow/utils/get-mouse-pos";

import SupportedWorkflowActions from "./supported-workflow-actions";
import MakeFlowchartNeat from "./workflow/utils/make-flowchart-neat";

import LoadRolesList from "./workflow/service-helpers/users-and-roles/load-roles-list";
import LoadQueryFromPlayground from "./workflow/service-helpers/query-playground/load-query-from-playground";
import LoadReport from "./workflow/service-helpers/reports/load-report";

import { v4 as uuidv4 } from "uuid";

var HtmlToReactParser = require('html-to-react').Parser;
var htmlToReactParser = new HtmlToReactParser();

class ScheduledTasks extends React.Component {
    constructor(props) {
        super(props);

        this.COMPONENT_STATES = {
            LOADING: "LOADING",
            LOADED: "LOADED",

            ERROR: "ERROR",
        }

        this.tabs = {
            "CONFIG": "config",
            "LOGS": "logs",
        };

        this.state = {
            tasks: [],
            settings: {},
            roles: {},

            logs: [],

            selectedTab: this.tabs.CONFIG,

            selectedLogIndex: null,
            selectedTask: null,
            selectedTaskPipeline: [],
            mappedDataForPipelineItems: {},

            selectedTaskStatus: this.COMPONENT_STATES.LOADED,
            status: this.COMPONENT_STATES.LOADING,

            canvasRef: React.createRef(),
            canvasHeight: 0,

            clickedComponent: null,
            components: [],
            arrows: [],
        };

        this.testRun = this.testRun.bind(this);
        this.handleDeleteScheduledTask = this.handleDeleteScheduledTask.bind(this);
        this.selectTab = this.selectTab.bind(this);
        this.selectLog = this.selectLog.bind(this);

        this.loadTaskLogs = this.loadTaskLogs.bind(this);
    }

    componentDidMount() {
        DataDelegator.resolve(this.props, (err) => {
            if (err) {
                throw err;
            } else {
                this.loadData();
            }
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const canvas = this.state.canvasRef.current;

        if (canvas) {
            // Set the width to be the width of the parent container if any
            if (canvas.parentNode) {
                const dimensions = canvas.parentNode.getBoundingClientRect();
                if (Math.abs(canvas.width - dimensions.width) > 10) {
                    canvas.width = dimensions.width;
                }
            }

            if (this.state.canvasHeight != prevState.canvasHeight || this.state.selectedTask?.uuid != prevState.selectedTask?.uuid) {
                canvas.height = this.state.canvasHeight;
            }
        }
    }

    selectLog(index) {
        this.setState({
            selectedLogIndex: this.state.selectedLogIndex === index ? null : index,
        })
    }

    selectTab(tab) {
        let functionToRun = (tab === this.tabs.CONFIG) ? this.loadTaskPipeline : this.loadTaskLogs;

        this.setState({
            selectedTab: tab,
        }, functionToRun);
    }


    loadData() {
        Promise.all([
            axiosBackend({
                method: "POST",
                url: `/service/settings/${this.props.router.params.appUUID}/`,
            }),
            axiosBackend({
                method: "POST",
                url: `/service/cron-jobs/${this.props.router.params.appUUID}/`,
            }),
            LoadRolesList({
                appUUID: this.props.router.params.appUUID,
            }),
        ]).then(([settings, tasks, roles]) => {
            const mappedSettings = settings.data.results.reduce((acc, setting) => {
                acc[setting.name] = setting.value;

                return acc;
            }, {});

            this.setState({
                status: this.COMPONENT_STATES.LOADED,
                tasks: tasks.data.results,
                settings: mappedSettings,

                roles: roles.data.results.reduce((acc, role) => {
                    acc[role.uuid] = role.name;

                    return acc;
                }, {}),
            });
        })
            .catch((err) => {
                console.error(err);

                this.setState({
                    status: this.COMPONENT_STATES.ERROR,
                })
            })
    }

    loadTaskPipeline() {
        axiosBackend({
            method: "POST",
            url: `/service/cron-jobs/${this.props.router.params.appUUID}/${this.state.selectedTask.uuid}`,
        })
            .then(async (response) => {
                const dataToLoad = response.data.results.map((item) => {
                    if (item.actionToExecute == "query") {
                        return LoadQueryFromPlayground({
                            appUUID: this.props.router.params.appUUID,
                            queryUUID: item.action_uuid,
                        });
                    }

                    if (item.actionToExecute == "communication") {
                        return axiosBackend({
                            method: "POST",
                            url: `/service/communication-presets/${this.props.router.params.appUUID}/${item.action_uuid}`,
                        })
                    }

                    if (item.actionToExecute == "reports") {
                        return LoadReport({
                            appUUID: this.props.router.params.appUUID,
                            reportUUID: item.action_uuid,
                        })
                    }
                }).filter((item => item != undefined));


                const allData = await Promise.all(dataToLoad);
                const mappedData = allData.reduce((acc, result) => {
                    if (result?.data?.status == "success") {
                        if (result?.data?.results?.length > 0) {
                            const dataToUse = result?.data?.results[0];

                            acc[dataToUse.uuid] = dataToUse
                        }
                    }

                    return acc;
                }, {});

                this.setState({
                    selectedTaskStatus: this.COMPONENT_STATES.LOADED,

                    selectedTaskPipeline: response.data.results.sort((a, b) => {
                        return a.order > b.order;
                    }),
                    mappedDataForPipelineItems: mappedData,
                }, () => {
                    const canvas = this.state.canvasRef.current;
                    const context = canvas.getContext('2d');

                    let tzOffset = 0;
                    // if (this.state.settings.Timezone) {
                    //     tzOffset = moment.tz(this.state.settings.Timezone).utcOffset() / 60; // Because it comes back in minutes
                    // }

                    const startComponent = {
                        id: 'start',
                        type: 'rectangle',
                        x: 50,
                        y: 30,
                        text: 'Start',
                        description: cronstrue.toString(this.state.selectedTask.cron_schedule, {
                            tzOffset
                        }),
                        ports: [
                            {
                                x: 0.5,
                                y: 0,
                                id: uuidv4(),
                                type: "input",
                            },
                            {
                                x: 0.5,
                                y: 1,
                                id: uuidv4(),
                                type: "output",
                            },
                        ],
                    };

                    const endComponent = {
                        id: 'end',
                        type: 'rectangle',
                        width: 150,
                        height: 40,
                        text: 'End',
                        ports: [
                            {
                                x: 0.5,
                                y: 0,
                                id: uuidv4(),
                                type: "input",
                            },
                            {
                                x: 0.5,
                                y: 1,
                                id: uuidv4(),
                                type: "output",
                            },
                        ],
                    }

                    const componentsToMakeNeat = this.state.selectedTaskPipeline.map((item) => {
                        const mappedData = this.state.mappedDataForPipelineItems[item.action_uuid];

                        let text = "";
                        let description = "";

                        if (SupportedWorkflowActions[item.actionToExecute]) {
                            const subkey = SupportedWorkflowActions[item.actionToExecute].subKey;
                            // For communication -> email, communication -> sms and so on

                            if (SupportedWorkflowActions[item.actionToExecute][mappedData[subkey]]) {
                                text = SupportedWorkflowActions[item.actionToExecute][mappedData[subkey]].name(mappedData);
                                description = SupportedWorkflowActions[item.actionToExecute][mappedData[subkey]].description(mappedData);
                            } else if (item.action_configuration?.operation) {
                                // Check if there is an operation under the action_configuration and use that instead of the subkey
                                text = SupportedWorkflowActions[item.actionToExecute][item.action_configuration.operation].name(mappedData);
                                description = SupportedWorkflowActions[item.actionToExecute][item.action_configuration.operation].description(mappedData);
                            }
                        }

                        let componentProps = {
                            id: item.action_uuid,
                            type: "rectangle",
                            text: text,
                            description,
                            ports: [
                                {
                                    x: 0.5,
                                    y: 0,
                                    id: uuidv4(),
                                    type: "input",
                                },
                                {
                                    x: 0.5,
                                    y: 1,
                                    id: uuidv4(),
                                    type: "output",
                                },
                            ],
                            original: item,
                        };

                        return componentProps;
                    })

                    const {
                        components: generatedComponents,
                        arrows: generatedArrows,
                        startX,
                        startY,
                    } = MakeFlowchartNeat({
                        canvas,

                        startComponent,
                        endComponent,

                        components: componentsToMakeNeat,
                    });
                    // Handle showing the popup when double-clicking on the canvas
                    // or showing the properties box when double-clicking on a component
                    canvas.addEventListener('click', (e) => {
                        const mousePos = getMousePos({ event: e, canvas });

                        this.setState({
                            clickedComponent: null,
                        });

                        generatedComponents.forEach(component => {
                            if (isMouseInComponent({ mousePos, component })) {
                                // Do not highlight anything if the component is the end component
                                if (component.id !== 'end') {
                                    this.setState({
                                        clickedComponent: component,
                                    });
                                }

                                drawFlowchart({
                                    ctx: context,
                                    canvas,
                                    components: generatedComponents,
                                    arrows: generatedArrows,
                                    clickedComponent: component.id !== 'end' ? component : null,
                                });
                            }
                        });
                    });

                    this.setState({
                        canvasHeight: startY,
                    }, () => {
                        drawFlowchart({
                            ctx: context,
                            canvas,
                            components: generatedComponents,
                            arrows: generatedArrows,
                            clickedComponent: this.state.clickedComponent,
                        });
                    })
                });
            })
            .catch((err) => {
                console.error(err);

                this.setState({
                    selectedTaskStatus: this.COMPONENT_STATES.ERROR,
                })
            })
    }

    loadTaskLogs() {
        axiosBackend({
            method: "POST",
            url: `/service/cron-jobs/${this.props.router.params.appUUID}/${this.state.selectedTask.uuid}/logs`,
        })
            .then(async (response) => {
                this.setState({
                    selectedTaskStatus: this.COMPONENT_STATES.LOADED,

                    logs: response.data.results.reverse(),
                })
            })
            .catch((err) => {
                console.error(err);

                this.setState({
                    selectedTaskStatus: this.COMPONENT_STATES.ERROR,
                })
            })
    }

    selectTask(task) {
        if (this.state.selectedTask && this.state.selectedTask.uuid == task.uuid) {
            this.setState({
                selectedTask: null,
                selectedTaskStatus: this.COMPONENT_STATES.LOADED,
                selectedTaskPipeline: [],
            })
        } else {
            this.setState({
                selectedTask: task,
                selectedTaskStatus: this.COMPONENT_STATES.LOADING,
            }, () => {
                this.selectTab(this.state.selectedTab);
            })
        }
    }

    handleDeleteScheduledTask({ task, event }) {
        event.stopPropagation();

        this.props.dispatch({
            type: "ResolvedData",
            name: "ModalData",
            data: {
                "show": true,
                "type": "confirmation",
                "title": "Confirm Deletion",
                "message": [
                    "Are you sure you would like to remove this scheduled task?",
                    "‏‏‎ ‎",
                    "NOTE: This action cannot be undone"
                ],
                yesButtonText: "Yes",
                noButtonText: "No",
                onYes: (onClose) => {
                    this.props.dispatch({
                        type: "ResolvedData",
                        name: "ModalData",
                        data: {
                            "show": true,
                            "type": "processing",
                            "title": "Deleting Scheduled Task",
                            "message": [
                                "This might take a few seconds.",
                                "You will be shown a confirmation screen once completed."
                            ]
                        },
                    });

                    setTimeout(() => {
                        axiosBackend({
                            method: "DELETE",
                            url: `/service/cron-jobs/${this.props.router.params.appUUID}/`,
                            data: {
                                uuid: task.uuid,
                            }
                        })
                            .then((response) => {
                                this.props.dispatch({
                                    type: "ResolvedData",
                                    name: "ModalData",
                                    data: {
                                        show: true,
                                        type: "success",
                                        title: "Success",
                                        message: [
                                            "Your scheduled task was deleted successfully",
                                        ],
                                        okayButtonText: "Okay",
                                    },
                                });

                                this.loadData();
                            })
                            .catch((err) => {
                                console.error(err);
                                this.props.dispatch({
                                    type: "ResolvedData",
                                    name: "ModalData",
                                    data: {
                                        show: true,
                                        type: "error",
                                        title: "Could not remove scheduled task",
                                        message: [
                                            "Due to an error, we were unable to remove your scheduled task",
                                            "‏‏‎ ‎",
                                            "Please try again in a little while."
                                        ],
                                        okayButtonText: "Okay"
                                    },
                                });
                            })
                    }, 1000);
                }
            },
        });
    }

    testRun({ event, task }) {
        event.stopPropagation();

        if (this.state.selectedTask == null || this.state.selectedTask.uuid != task.uuid) {
            this.selectTask(task);
        }

        this.props.dispatch({
            type: "ResolvedData",
            name: "ModalData",
            data: {
                "show": true,
                "type": "confirmation",
                "title": "Confirm Test Run",
                "message": [
                    "Are you sure you would like to test run this scheduled task?",
                    "‏‏‎ ‎",
                    "NOTE: Any emails that are to be sent will be to your email address"
                ],
                yesButtonText: "Yes",
                noButtonText: "No",
                onYes: (onClose) => {
                    this.props.dispatch({
                        type: "ResolvedData",
                        name: "ModalData",
                        data: {
                            "show": true,
                            "type": "processing",
                            "title": "Running Task",
                            "message": [
                                "This might take a few seconds.",
                                "You will be shown a confirmation screen once completed."
                            ]
                        },
                    });

                    setTimeout(() => {
                        axiosBackend({
                            method: "POST",
                            url: `/service/cron-jobs/${this.props.router.params.appUUID}/${this.state.selectedTask.uuid}/test`,
                        })
                            .then((response) => {
                                this.props.dispatch({
                                    type: "ResolvedData",
                                    name: "ModalData",
                                    data: {
                                        show: true,
                                        type: "success",
                                        title: "Success",
                                        message: [
                                            "Your task was test run successfully but there might have been errors in some of the stages",
                                            "‏‏‎ ‎",
                                            "Please check the task logs for more information."
                                        ],
                                        okayButtonText: "Okay",
                                    },
                                });
                            })
                            .catch((err) => {
                                console.error(err);
                                this.props.dispatch({
                                    type: "ResolvedData",
                                    name: "ModalData",
                                    data: {
                                        show: true,
                                        type: "error",
                                        title: "Could not test run task",
                                        message: [
                                            "Due to an error, we were unable to test run your task successfully",
                                            "‏‏‎ ‎",
                                            "Please check the task logs for more information."
                                        ],
                                        okayButtonText: "Okay"
                                    },
                                });
                            })
                    }, 1000);
                }
            },
        });
    }

    render() {
        let tzOffset = 0;
        // if (this.state.settings.Timezone) {
        //     tzOffset = moment.tz(this.state.settings.Timezone).utcOffset() / 60; // Because it comes back in minutes
        // }

        const mappedData = this.state.mappedDataForPipelineItems[this.state.clickedComponent?.original?.action_uuid] || {};

        return (
            <>
                <div className="mt-1 mb-3 col-sm-12">
                    <div className="row">
                        <div className="col-sm-8">
                            <button id="toggle-help" className="btn btn-gray" onClick={this.toggleHelp}>
                                <span className="far fa-question-circle fa-lg"></span>
                            </button>
                        </div>

                        <div className="col-sm-4 text-right">
                            <Link to="./add">
                                <button type="button" className="mr-2 btn btn-primary float-right"><i className="fas fa-plus"></i>&nbsp;Add Scheduled Task</button>
                            </Link>
                        </div>
                    </div>

                </div>

                <div className="col-sm-12">
                    <div className="">
                        <div className="ibox-content">

                            <h2>Scheduled Tasks Management</h2>
                            <p>
                                Configure a workflow to run on a given schedule
                            </p>
                            <hr />

                            {this.state.tasks.length == 0 ?
                                <div>
                                    No scheduled tasks found
                                </div>
                                :
                                this.state.tasks.map((task) => {
                                    return (
                                        <div className="border-bottom mt-2 pb-2" key={task.uuid}>
                                            <div onClick={() => this.selectTask(task)} className="pointer">
                                                <span className="label label-primary">Active</span>&nbsp;
                                                <span>{task.name}</span><br />

                                                <div className="d-flex justify-content-between mt-3">
                                                    <div className="p-2 ">
                                                        <div className="mt-1 small">
                                                            <i className="fa fa-user" />&nbsp;
                                                            Run as role <u className="">{this.state.roles[task.role_to_run_as]}</u>
                                                        </div>

                                                        <div className="mt-1 small">
                                                            <i className="fa fa-calendar-alt" />&nbsp;
                                                            {cronstrue.toString(task.cron_schedule, {
                                                                tzOffset
                                                            })}
                                                        </div>
                                                    </div>

                                                    <div className="p-2 align-self-end ">
                                                        <button onClick={(event) => this.testRun({ task, event })} className="btn btn-primary btn-xs float-right ml-2">
                                                            <i className="fa fa-play"></i>&nbsp;
                                                            Test Run
                                                        </button>

                                                        <button className="btn btn-primary btn-xs ml-2">
                                                            <span className="fa fa-eye"></span>&nbsp;View
                                                        </button>

                                                        <button disabled={true} className="btn btn-success btn-xs ml-2">
                                                            <span className="fa fa-edit"></span>&nbsp;Edit
                                                        </button>

                                                        <button onClick={(event) => this.handleDeleteScheduledTask({ task, event })} className="btn btn-danger btn-xs ml-2">
                                                            <span className="fa fa-times"></span>&nbsp;Remove
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            {this.state.selectedTask && this.state.selectedTask.uuid == task.uuid &&
                                                <div className="row">
                                                    <div className="col">
                                                        <ul className="nav nav-tabs mt-4">
                                                            <li>
                                                                <a
                                                                    className={`nav-link ${this.state.selectedTab == this.tabs.CONFIG && "active show"}`}
                                                                    onClick={() => this.selectTab(this.tabs.CONFIG)}
                                                                >
                                                                    <i className="fa fa-stream"></i> Task Configuration
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a
                                                                    className={`nav-link ${this.state.selectedTab == this.tabs.LOGS && "active show"}`}
                                                                    onClick={() => this.selectTab(this.tabs.LOGS)}
                                                                >
                                                                    <i className="fa fa-folder-open"></i> Task Logs
                                                                </a>
                                                            </li>
                                                        </ul>
                                                        <div className="tab-content">
                                                            <div id="tab-config" className={`tab-pane ${this.state.selectedTab == this.tabs.CONFIG ? "active" : ""}`}>
                                                                <div className="row">
                                                                    <div className="col-md-8">
                                                                        <canvas ref={this.state.canvasRef} />

                                                                    </div>

                                                                    <div className="col-md-4 mt-2">
                                                                        {this.state.clickedComponent ?
                                                                            <>
                                                                                {this.state.clickedComponent.id == "start" &&
                                                                                    <>
                                                                                        <h4>Run Task Workflow</h4>
                                                                                        <p>
                                                                                            <i className="fa fa-clock"></i>&nbsp;{this.state.clickedComponent.description}
                                                                                        </p>
                                                                                    </>
                                                                                }

                                                                                {this.state.clickedComponent.original?.actionToExecute == "query" && <>
                                                                                    <h4>Run Query From Playground</h4>

                                                                                    <span className="badge badge-info text-uppercase">{mappedData.operation}</span>&nbsp;
                                                                                    {mappedData.name}
                                                                                </>
                                                                                }

                                                                                {this.state.clickedComponent.original?.actionToExecute == "reports" && <>
                                                                                    <h4>
                                                                                        {this.state.clickedComponent.original?.action_configuration?.operation == "render_report" && "Generate Report HTML"}
                                                                                        {this.state.clickedComponent.original?.action_configuration?.operation == "save_as_pdf" && "Generate & Save Report PDF"}
                                                                                    </h4>

                                                                                    {mappedData.name}
                                                                                </>
                                                                                }

                                                                                {this.state.clickedComponent.original?.actionToExecute == "communication" && mappedData.type == "email" && <>
                                                                                    <h4>Send Email</h4>

                                                                                    <div className="mb-2">
                                                                                        <span className="font-bold">To:</span> {mappedData.to}
                                                                                    </div>

                                                                                    <div className="mb-2">
                                                                                        <span className="font-bold">Subject:</span> {mappedData.subject}
                                                                                    </div>

                                                                                    <div className="mb-2">
                                                                                        <span className="font-bold">Template:</span> {htmlToReactParser.parse(
                                                                                            mappedData.template
                                                                                        )}
                                                                                    </div>

                                                                                    <div className="mb-2">
                                                                                        <span className="font-bold">Attachments:</span> {mappedData.attachments?.length > 0 ? JSON.stringify(mappedData.attachments) : "None"}
                                                                                    </div>


                                                                                </>
                                                                                }
                                                                            </>
                                                                            :
                                                                            <>
                                                                                Click on a workflow item to view more information about it
                                                                            </>}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div id="tab-logs" className={`tab-pane ${this.state.selectedTab == this.tabs.LOGS ? "active" : ""}`}>
                                                                {this.state.logs.length == 0 ? <div className="mt-2">No logs yet</div>
                                                                    :

                                                                    <>
                                                                        <table className="mb-0 table table-hover issue-tracker">

                                                                            <tbody>
                                                                                {this.state.logs.map((log, index) => {
                                                                                    return <tr onClick={() => this.selectLog(index)} className="pointer" key={index}>
                                                                                        <td>
                                                                                            {moment(log.createdOn).tz(this.state.settings.Timezone).format('LLL')}

                                                                                            {this.state.selectedLogIndex == index &&
                                                                                                <div className="mt-3">
                                                                                                    <code>
                                                                                                        <pre>
                                                                                                            {JSON.stringify(log, null, 4)}
                                                                                                        </pre>
                                                                                                    </code>
                                                                                                </div>
                                                                                            }
                                                                                        </td>
                                                                                    </tr>
                                                                                })}
                                                                            </tbody>
                                                                        </table>

                                                                        <button onClick={this.loadTaskLogs} className="mt-2 btn btn-default btn-block">
                                                                            <i className="fa fa-redo-alt"></i>&nbsp;Reload Logs
                                                                        </button>
                                                                    </>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>

                                    )
                                })}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default withLoaderData(connect(DataDelegator.mapStateToProps)(ScheduledTasks));