import React from "react";
import update from "immutability-helper";

import { map } from "async";

import * as ejs from "ejs";
import axiosBackend from "../../../../core/api/backend";

import equal from "fast-deep-equal";

import { Parser as FormulaParser } from 'hot-formula-parser';

import { connect } from "react-redux";
import { resolve as DataDelegatorResolve, mapStateToProps, getFromStore } from "../../../../components/smart/delegator/DataDelegator";

import * as TextField from "./types/text";
import * as TextareaField from "./types/textarea";

import * as NumberField from "./types/number";
import * as CurrencyField from "./types/currency";

import * as EmailField from "./types/email";
import * as FileField from "./types/file";

import * as CheckboxField from "./types/checkbox";

import * as DropdownField from "./types/dropdown";
import * as LookupField from "./types/lookup";

import * as DateTimeField from "./types/datetime";

import * as ArrayField from "./types/array";
import * as JSONField from "./types/json";

import RegularLabel from "./labels/regular";

import "../../../../scss/components/react/react-select.scss"; // Imported because Injected forms will have issues otherwise
import { Link } from "react-router-dom";
import { debounce } from "lodash";

const FormulaParserErrorMessages = {
    "#ERROR!": "An error occurred.", // General error;
    "#DIV/0!": "Division by 0", // Divide by zero error;
    "#NAME?": "The function name or one of the variable names could not be found", // Not recognised function name or variable name;
    "#N/A": "ONe of the values is not available", // Indicates that a value is not available to a formula;
    "#NUM!": "One of the formula arguments is not a valid number", // Occurs when formula encounters an invalid number;
    "#VALUE!": "One of the formula arguments is incorrect or not provided", // Occurs when one of formula arguments is of the wrong type.
}

export const DefaultClasses = {
    field: ["form-control"],

    submitButton: ["btn", "btn-success"],
    submitButtonIcon: ["fas", "fa-check"],

    cancelButton: ["btn", "btn-danger", "ml-1"],
    cancelButtonIcon: ["fas", "fa-times"],

    resetButton: ["btn", "btn-warning", "ml-1"],
    resetButtonIcon: ["fas", "fa-redo"],
}

export const defaultValidationMessages = {
    invalidData: "Some fields have incorrectly entered data. Please correct them and try again",

}

export const supportedFieldTypes = {
    "text": TextField,
    "textarea": TextareaField,

    "number": NumberField,
    "currency": CurrencyField,

    "email": EmailField,

    "file": FileField,

    "dropdown": DropdownField,
    "lookup": LookupField,

    "checkbox": CheckboxField,

    "datetime": DateTimeField,
    "date": DateTimeField,
    "time": DateTimeField,

    "array": ArrayField,
    "json": JSONField,
}

const allowedUpdateProps = ["error", "success", "warning", "manuallyChanged"];

class MagicForm extends React.Component {
    constructor(props) {
        super(props);

        console.log('init', props);

        // The first time a field gets rendered, it gets memoized
        this.memoizedFields = {};
        this.fieldsValidators = {};

        this.state = {
            fieldsToUpdateAllowedThrough: false, // used by shouldComponentUpdate

            fieldsToUpdate: {},
            updatedFieldConfiguration: {},

            values: {},

            submitting: false,

            success: "",
            error: "",

            requestId: "",

            idField: "id",
            insertIds: [],
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        let shouldUpdate = false;
        console.log(this.constructor.name, "shouldComponentUpdate");

        // Check if the form has been forced to submit
        // If so, allow the update to happen because validation, etc must go through
        if (nextProps.forceFormSubmission != this.props.forceFormSubmission) {
            console.log(this.constructor.name, "forceFormSubmission toggle");

            if (nextProps.forceFormSubmission === true) {
                // We are debouncing because onSubmit will trigger a setState which will trigger this function again
                // and we don't want to trigger the onSubmit more than once before a render happens
                debounce(() => {
                    this.onSubmit();
                }, 500)();
            }

            return true;
        }

        // Check if the fields changed
        // This is usually because of the DataDelegator
        // but can be caused by anything else
        if (equal(nextProps.fields, this.props.fields) === false) {
            console.log(this.constructor.name, "unmemoize");
            // Force all fields to rerender by removing their memoization
            this.memoizedFields = {};

            return true;
        }

        // Lets check if we have an error message
        if (nextState.error !== this.state.error) {
            shouldUpdate = true;
        }

        // Check if we just set all the fieldsToUpdate values to be false
        // If so, just mark this as done and don't update as the update happened the last 
        // time this function got invoked
        if (nextState.fieldsToUpdateAllowedThrough == true) {
            this.setState({
                fieldsToUpdateAllowedThrough: false,
            });

            // shouldUpdate = false; // Commented out because if anything before it overwrote to true, that needs to be respected
        }

        // Same as above but just ensuring that nothing changes since we setState above
        else if (this.state.fieldsToUpdateAllowedThrough === true && nextState.fieldsToUpdateAllowedThrough === false) {
            // shouldUpdate = false; // Commented out because if anything before it overwrote to true, that needs to be respected
        }

        // Check if any of the fields need to be updated
        const instances = Object.keys(nextState.fieldsToUpdate);

        let toUpdate = false;
        if (instances.length > 0) {
            let stateToUpdate = nextState;

            instances.forEach((fieldsInstanceKey, instanceIndex) => {
                const fieldsInstance = nextState.fieldsToUpdate[fieldsInstanceKey];

                Object.keys(fieldsInstance).forEach((fieldName) => {

                    if (nextState.fieldsToUpdate[instanceIndex][fieldName] === true) {
                        toUpdate = true;
                    }

                    stateToUpdate = update(stateToUpdate, {
                        fieldsToUpdate: {
                            [instanceIndex]: {
                                [fieldName]: {
                                    $set: false,
                                }
                            }
                        },
                    });
                });
            });

            stateToUpdate = update(stateToUpdate, {
                fieldsToUpdateAllowedThrough: {
                    $set: true,
                },
            });

            if (toUpdate) {
                shouldUpdate = true;

                this.setState(stateToUpdate);
            }
        }

        return shouldUpdate;
    }

    componentDidMount() {
        DataDelegatorResolve(this.props, (err, data) => {
            if (err) {
                throw err;
            } else {
                // Since the validation can be asynchronous, we will update it's state after it is done only
                if (this.props.validateOnStart === true) {
                    this.validateAll("fieldConfiguration");
                }
            }
        });
    }

    /**
     * Build the basic state for a field that needs to be updated
     * @param {Integer} instanceIndex Which instance of the fields is this referring to for rendering
     * @param {Integer} fieldName Which field instance is this referring to for rendering
     * @returns The state with the values and updateConfiguration set up
     */
    buildBasicFieldState(stateToUpdate, instanceIndex, fieldName) {
        // values
        if (stateToUpdate.values[instanceIndex] === undefined) {
            stateToUpdate = update(stateToUpdate, {
                values: {
                    [instanceIndex]: {
                        $set: {},
                    },
                },
            });
        }

        // fieldsToUpdate
        if (stateToUpdate.fieldsToUpdate[instanceIndex] === undefined) {
            stateToUpdate = update(stateToUpdate, {
                fieldsToUpdate: {
                    [instanceIndex]: {
                        $set: {},
                    }
                }
            })
        }

        if (stateToUpdate.fieldsToUpdate[instanceIndex][fieldName] == undefined) {
            stateToUpdate = update(stateToUpdate, {
                fieldsToUpdate: {
                    [instanceIndex]: {
                        [fieldName]: {
                            $set: false,
                        }
                    }
                }
            })
        }

        // updatedFieldConfiguration
        if (stateToUpdate.updatedFieldConfiguration[instanceIndex] === undefined) {
            stateToUpdate = update(stateToUpdate, {
                updatedFieldConfiguration: {
                    [instanceIndex]: {
                        $set: {},
                    }
                }
            })
        }

        if (stateToUpdate.updatedFieldConfiguration[instanceIndex][fieldName] == undefined) {
            stateToUpdate = update(stateToUpdate, {
                updatedFieldConfiguration: {
                    [instanceIndex]: {
                        [fieldName]: {
                            $set: {},
                        }
                    }
                }
            })
        }

        return stateToUpdate;
    }

    validateAll(valueFrom, validateCallback) {
        if (Array.isArray(this.props.fields)) {
            const fieldsToBeValidated = this.props.fields.map((fieldsInstance, instanceIndex) => {
                return Object.keys(fieldsInstance).map((fieldName) => {
                    let fieldConfiguration = this.buildFieldConfiguration(fieldsInstance, instanceIndex, fieldName);

                    if (supportedFieldTypes[fieldConfiguration.type]) {
                        const Field = supportedFieldTypes[fieldConfiguration.type];

                        let value;
                        if (valueFrom == "fieldConfiguration") {
                            value = fieldConfiguration.value;
                        } else {
                            value = this.getCorrectValue(instanceIndex, fieldName);
                        }

                        return (triggerOnChange = false, callbackFn) => {
                            Field.validate(fieldConfiguration, value, (err, updateProps) => {
                                if (triggerOnChange === true) {
                                    // We are calling this so that it will update trigger an update to the DOM if the updateProps changed
                                    this.onFieldValueChange({
                                        value, 
                                        updateProps, 
                                        updatePropsOnly: true
                                    });
                                }

                                callbackFn(err, updateProps);
                            });
                        }
                    } else {
                        this.setState({
                            error: "Unsupported field type - " + fieldConfiguration.type + ". Please check the form configuration and try again."
                        });
                    }
                });
            }).flat().filter(callbackFunction => callbackFunction !== undefined);

            map(fieldsToBeValidated, (fieldValidator, callback) => {
                fieldValidator(true, (err, updateProps) => {
                    callback(err, updateProps);
                })
            }, validateCallback);
        } else {
            typeof validateCallback === "function" && validateCallback(new Error("Fields are not provided"));
        }
    }

    onFieldValueChange({value, updateProps, callback, updatePropsOnly = false}) {
        console.log("onFieldValueChange", updateProps.fieldName);

        this.setState((state) => {
            let instanceIndex = updateProps.instanceIndex;
            let fieldName = updateProps.fieldName;

            state = this.buildBasicFieldState(state, instanceIndex, fieldName);
            
            if(!updatePropsOnly) {
                state = update(state, {
                    values: {
                        [instanceIndex]: {
                            [fieldName]: {
                                $set: value
                            },
                        },
                    }
                });
            }

            if (updateProps !== undefined) {
                // Only certain props are allowed to be updated
                const whichPropsToUpdate = allowedUpdateProps.filter((prop) => {
                    // Allow the update for this prop to roll through only if it's current value in the
                    // MagicForm's state is different from the new value that has been provided to this callback
                    if (updateProps[prop] !== undefined) {
                        if (this.state.updatedFieldConfiguration[instanceIndex] !== undefined) {
                            if (this.state.updatedFieldConfiguration[instanceIndex][fieldName] !== undefined) {
                                return this.state.updatedFieldConfiguration[instanceIndex][fieldName][prop] != updateProps[prop];
                            }
                        }
                    } else {
                        // the updateProp value is undefined
                        return false;
                    }

                    return true;
                });

                // Mark the field as needing to be updated since there were some props that were present
                // that mandated that the component needs to be re-rendered
                if (whichPropsToUpdate.length > 0 || updateProps.forceUpdate === true) {
                    state = update(state, {
                        fieldsToUpdate: {
                            [updateProps.instanceIndex]: {
                                [updateProps.fieldName]: {
                                    $set: true,
                                }
                            }
                        }
                    });

                    whichPropsToUpdate.forEach((prop) => {
                        state = this.updateField(state, {
                            instanceIndex: updateProps.instanceIndex,
                            fieldName: updateProps.fieldName,
                            updateSpec: {
                                [prop]: {
                                    $set: updateProps[prop],
                                }
                            }
                        })
                    });
                }
            }

            return state;
        }, () => {
            typeof callback === "function" && callback();
        })
    }

    buildFieldConfiguration(fieldsInstance, instanceIndex, fieldName) {
        let fieldConfiguration = { ...fieldsInstance[fieldName] };

        // For optimization through memoization
        fieldConfiguration.instanceIndex = instanceIndex;
        fieldConfiguration.fieldName = fieldName;

        // Properties common to all field types
        fieldConfiguration.key = `field_${instanceIndex}_${fieldName}`;
        fieldConfiguration.classes = DefaultClasses.field.concat(fieldConfiguration.classes);

        // Attach any currently set values
        if (this.state.updatedFieldConfiguration[instanceIndex] !== undefined) {
            if (this.state.updatedFieldConfiguration[instanceIndex][fieldName] !== undefined) {
                // Attach the allowed updated props to the field configuration if they are not already attached
                allowedUpdateProps.forEach((prop) => {
                    if (!fieldConfiguration.hasOwnProperty(prop)) {
                        fieldConfiguration[prop] = this.state.updatedFieldConfiguration[instanceIndex][fieldName][prop];
                    }
                });

                if (this.state.updatedFieldConfiguration[instanceIndex][fieldName].disabled !== undefined) {
                    fieldConfiguration.disabled = this.state.updatedFieldConfiguration[instanceIndex][fieldName].disabled;
                }
            }
        }

        // Get the correct value
        fieldConfiguration.value = this.getCorrectValue(instanceIndex, fieldName, fieldConfiguration.value);

        // Do we have an id or shall we generate one
        if (fieldConfiguration.id == undefined) {
            fieldConfiguration.id = `field-${instanceIndex}-${fieldName}`;
        }

        fieldConfiguration.inlineLabels = this.props.inlineLabels;
        fieldConfiguration.inlineLabelSize = this.props.inlineLabelSize;

        // This will come from the backend if there is any field whose value depends on another field's value
        // Example: One field dropdown triggering another field's values based on it
        fieldConfiguration.customOnChange = fieldConfiguration.onChange;

        fieldConfiguration.onChange = (value, updateProps) => {
            this.onFieldValueChange({value, updateProps, callback: () => {
                if (fieldConfiguration.customOnChange !== undefined) {
                    this.customOnChange(value, fieldConfiguration);
                }
            }});
        }

        return fieldConfiguration;
    }

    customOnChange(newValue, fieldConfiguration) {
        let value = newValue;

        // Check if this was a lookup
        if (newValue !== null && typeof newValue == "object") {
            value = newValue.value;
        }

        if (fieldConfiguration.customOnChange) {
            if (fieldConfiguration.customOnChange.robostackResolveData) {
                DataDelegatorResolve({
                    ...this.props,
                    ...fieldConfiguration.customOnChange,
                    value,
                }, (err, data) => {
                    if (err) {
                        this.props.dispatch({
                            type: "ResolvedData",
                            name: "ModalData",
                            data: {
                                show: true,
                                type: "error",
                                title: "Could not load data",
                                message: [
                                    "Due to an unexpected error, we were unable to load data from the server.",
                                    "Please try again in a little while."
                                ],
                                okayButtonText: "Okay"
                            },
                        });
                    } else {
                        if (Array.isArray(fieldConfiguration.customOnChange.instanceAction)) {
                            let stateToUpdate = this.state;

                            fieldConfiguration.customOnChange.instanceAction.forEach((instanceAction) => {
                                if (instanceAction.fields && Array.isArray(instanceAction.fields)) {
                                    if (instanceAction["$set"]) {
                                        let fieldsToSet = Object.keys(instanceAction["$set"]);

                                        let valuesToSet = {};
                                        fieldsToSet.forEach((thisFieldName) => {
                                            // Do we have to pickup resolved data from redux for this property?
                                            if (instanceAction["$set"][thisFieldName].$resolve !== undefined) {
                                                valuesToSet[thisFieldName] = {
                                                    "$set": getFromStore(instanceAction["$set"][thisFieldName].$resolve),
                                                }
                                            } else {
                                                valuesToSet[thisFieldName] = {
                                                    "$set": instanceAction["$set"][thisFieldName],
                                                }
                                            }
                                        });

                                        instanceAction.fields.forEach((thisFieldName) => {
                                            try {
                                                stateToUpdate = this.updateField(this.buildBasicFieldState(stateToUpdate, fieldConfiguration.instanceIndex, thisFieldName), {
                                                    instanceIndex: fieldConfiguration.instanceIndex,
                                                    fieldName: thisFieldName,
                                                    updateSpec: valuesToSet,
                                                });

                                            } catch (err) {
                                                console.log(err)
                                            }
                                        });
                                    }
                                }
                            })

                            this.setState(stateToUpdate);
                        }
                    }
                })
            }
        }
    }

    // Since some fields might have a default value that is computed and stored in its memoization, we need to check if the value was set
    // or get the value from the state
    // The reason this was done was because the default value gets set when the field gets memoized which occurs when the field renders
    getCorrectValue(instanceIndex, fieldName, currentValue) {
        let value = currentValue;

        if (this.state.values[instanceIndex] !== undefined) {
            // We are checking if the value was set in the state rather than compare it to undefined
            // because when edited, some values store empty values as undefined and we don't want to incorrectly pick up the default value
            if (this.state.values[instanceIndex].hasOwnProperty(fieldName)) {
                value = this.state.values[instanceIndex][fieldName];
            }

            // Check if there was a default value
            else if (this.memoizedFields[instanceIndex] !== undefined) {
                if (this.memoizedFields[instanceIndex][fieldName] !== undefined && this.memoizedFields[instanceIndex][fieldName] !== null && this.memoizedFields[instanceIndex][fieldName].component !== undefined) {
                    value = this.memoizedFields[instanceIndex][fieldName].fieldConfiguration.value;
                }

            }
        }

        return value;
    }

    /**
     * Renders a field
     * @param {Integer} fieldsInstance Which instance of the fields is this referring to for rendering
     * @param {Integer} instanceIndex Which field instance is this referring to for rendering
     * @returns The relevant input field
     */
    renderField(fieldsInstance, instanceIndex) {
        if (this.memoizedFields[instanceIndex] === undefined) {
            this.memoizedFields[instanceIndex] = {};
        }

        if (this.fieldsValidators[instanceIndex] === undefined) {
            this.fieldsValidators[instanceIndex] = {};
        }

        const field = Object.keys(fieldsInstance).sort((a, b) => {
            if (fieldsInstance[a].position === null) {
                return 1; // move `a` to a higher index
            } else if (fieldsInstance[b].position === null) {
                return -1; // move `b` to a higher index
            } else {
                return fieldsInstance[a].position - fieldsInstance[b].position;
            }
        }).map((fieldName) => {
            if (this.memoizedFields[instanceIndex][fieldName] === undefined) {
                this.memoizedFields[instanceIndex][fieldName] = null;
            }

            if (this.fieldsValidators[instanceIndex][fieldName] === undefined) {
                this.fieldsValidators[instanceIndex][fieldName] = null;
            }

            let Component = undefined;

            const isFieldMemoized = this.memoizedFields?.[instanceIndex]?.[fieldName]?.component != undefined;

            if (!isFieldMemoized || this.state.fieldsToUpdate?.[instanceIndex]?.[fieldName] === true) {
                let fieldConfiguration = this.buildFieldConfiguration(fieldsInstance, instanceIndex, fieldName);

                let defaultValueChanged = false;

                const updateFieldConfiguration = (fieldConfiguration, updateProps) => {
                    allowedUpdateProps.forEach((prop) => {
                        if (!fieldConfiguration.hasOwnProperty(prop)) {
                            fieldConfiguration[prop] = updateProps[prop];
                        }
                    });

                    return fieldConfiguration;
                }

                if (supportedFieldTypes[fieldConfiguration.type]) {
                    const Field = supportedFieldTypes[fieldConfiguration.type];

                    // The first time the field is rendered on the page, the data inside of it will be validated and then rendered
                    if (!isFieldMemoized) {
                        fieldConfiguration = updateFieldConfiguration(fieldConfiguration, {});
                    }

                    fieldConfiguration.lookup = this.props.lookup;

                    if (this.state.updatedFieldConfiguration?.[instanceIndex]?.[fieldName]) {
                        const updatedValues = this.state.updatedFieldConfiguration?.[instanceIndex]?.[fieldName];

                        fieldConfiguration = {
                            ...fieldConfiguration,
                            ...updatedValues, // This is second because we want the updated values to override the fieldConfiguration values that are already set
                        }
                    }

                    if (fieldConfiguration.manuallyChanged != true) {
                        if (fieldConfiguration.default !== undefined && fieldConfiguration.default !== null) {
                            fieldConfiguration.value = fieldConfiguration.default;

                            fieldConfiguration.value = Field.formatValue({
                                fieldConfiguration,
                                value: fieldConfiguration.value
                            });

                            defaultValueChanged = true;
                        }



                        if (fieldConfiguration.defaultComputedValue !== undefined && fieldConfiguration.defaultComputedValue !== null && fieldConfiguration.defaultComputedValue.length > 0) {

                            const parser = new FormulaParser();

                            // Get all the fields from the formula
                            const fieldsInString = fieldConfiguration.defaultComputedValue.match(/(\$[a-zA-Z0-9_]+)/g);
                            if (fieldsInString != null && fieldsInString.length > 0) {
                                // Set each field if it exists in the fields object otherwise default it to 0
                                fieldsInString.forEach((field) => {
                                    // Remove the $ sign
                                    const thisFieldName = field.substring(1);

                                    // Get the value from the field if it exists otherwise default it to 0
                                    let value = this.getCorrectValue(instanceIndex, thisFieldName);

                                    if (value !== undefined) {
                                        parser.setVariable(thisFieldName, value);
                                    } else {
                                        parser.setVariable(thisFieldName, 0);
                                    }
                                });
                            }

                            const formula = fieldConfiguration.defaultComputedValue.replaceAll("$", ""); // Because the library uses $ to reference cell values and ranges

                            const { error, result } = parser.parse(formula);

                            if (error === null) {
                                if (!fieldConfiguration.manuallyChanged) {
                                    fieldConfiguration.value = result;

                                    fieldConfiguration.value = Field.formatValue({
                                        fieldConfiguration,
                                        value: fieldConfiguration.value
                                    });


                                    defaultValueChanged = true;
                                }
                            } else {
                                fieldConfiguration.warning = FormulaParserErrorMessages[error] || error;
                            }
                        }
                    }

                    Component = Field.render({ ...fieldConfiguration });
                } else {
                    console.warn(`Unsupported field type \`${fieldConfiguration.type}\``);
                    Component = undefined;
                }

                // If the field value changed because of a default value, update the key so that it will render again
                // This is because some fields use defaultValue and will not update otherwise
                if (defaultValueChanged) {
                    fieldConfiguration.key = fieldConfiguration.key + `_${new Date().getTime()}`;
                } else {
                    // Use the existing key if any
                    if (this.memoizedFields[instanceIndex][fieldName] !== undefined && this.memoizedFields[instanceIndex][fieldName] !== null) {
                        if(this.memoizedFields[instanceIndex][fieldName]?.fieldConfiguration?.key !== undefined) {
                            fieldConfiguration.key = this.memoizedFields[instanceIndex][fieldName].fieldConfiguration.key;
                        }
                    }
                }

                // Add a label if the component is valid
                if (Component !== undefined) {
                    this.memoizedFields[instanceIndex][fieldName] = {
                        component: RegularLabel({ ...fieldConfiguration }, Component),
                        fieldConfiguration,
                    };
                } else {
                    this.memoizedFields[instanceIndex][fieldName] = {
                        component: undefined,
                        fieldConfiguration: undefined,
                    };
                }
            }

            return this.memoizedFields[instanceIndex][fieldName].component;
        })

        return field;
    }

    // Mark a field to be updated and update its field configuration as per the passed spec
    updateField(stateToUpdate, { instanceIndex, fieldName, updateSpec }) {
        stateToUpdate = update(stateToUpdate, {
            fieldsToUpdate: {
                [instanceIndex]: {
                    [fieldName]: {
                        $set: true,
                    }
                }
            },

            updatedFieldConfiguration: {
                [instanceIndex]: {
                    [fieldName]: {
                        ...updateSpec
                    }
                }
            },
        });

        return stateToUpdate;
    }

    /** Disable all the form fields 
    * @param {Boolean} disabled The state the fields should be set to (disabled/not)
    * @param {Function} callback The callback to run when the fields state has been updated
    */
    disableAllFields(disabled, callback) {
        console.log("disableAllFields", disabled);

        this.setState((state) => {
            this.props.fields.forEach((fieldsInstance, instanceIndex) => {
                Object.keys(fieldsInstance).forEach((fieldName) => {
                    state = this.buildBasicFieldState(state, instanceIndex, fieldName);

                    state = this.updateField(state, {
                        instanceIndex,
                        fieldName,
                        updateSpec: {
                            disabled: {
                                $set: disabled,
                            }
                        }
                    })
                })
            });

            state = update(state, {
                submitting: {
                    $set: disabled,
                }
            })

            return state;
        }, () => {
            typeof callback === "function" && callback(null, disabled);
        });
    }

    submitTupleToSubmitAPI({ tuple, submitApi, props }, callback) {
        let data = {};

        // Lets see if we need to attach all the data directly to any field.
        // This is needed in the event that the data from the form needs to be placed inside a nested key such as
        // { updation: "$fields", selection: { id: 1 }}

        let attachedTuple = false;

        // ?The legacy way was just to use the key "fields" but that has been updated to "$fields" to prevent issues with users that need the string value "fields"

        if (typeof submitApi.data === 'object') {
            if (Object.keys(submitApi.data).length > 0) {
                data = Object.keys(submitApi.data).reduce((acc, key) => {

                    // Attach the fields data to any key whose value is $fields
                    if (submitApi.data[key] == "$fields") {
                        attachedTuple = true;
                        acc[key] = tuple;
                    }

                    // Pickup values from the submitApi object itself if needed
                    //? The legacy way was to do it without the $

                    else if (typeof submitApi.data[key] == "string" && submitApi.data[key].substr(0, 1) == "$" && submitApi[submitApi.data[key].substr(1)] !== undefined) {
                        acc[key] = submitApi[submitApi.data[key].substr(1)];
                    }

                    // Attach the value directly from the data object
                    else {
                        acc[key] = submitApi.data[key];
                    }

                    return acc;
                }, {});
            }
        }

        // If there was data from submitApi.data loaded, lets now attach the tuple data
        // If there are conflicting keys, the priority will be given to the tuple data
        // since it is most likely the data that comes from the user
        // ?But we will ony do this if the tuple was not already attached elsewhere

        if (!attachedTuple) {
            data = {
                ...data,
                ...tuple,
            }
        }

        let axiosConfiguration = {
            method: submitApi.method,
            url: ejs.render(submitApi.endpoint, props), // ?Because somethings like site etc will come from props
            data,
        };

        axiosBackend(axiosConfiguration)
            .then((response) => {
                if (typeof props.onSubmitApiSuccess === "function") {
                    props.onSubmitApiSuccess(response.data, data);
                }

                if (Array.isArray(response.data?.results) && response.data.results.length > 0) {
                    // Get all the insert ids
                    const insertIds = response.data.results.map((result) => {
                        return result.insertId;
                    }).filter((insertId) => insertId !== undefined);

                    this.setState({
                        insertIds,
                    })
                }

                callback(null, response.data);
            })
            .catch((error) => {
                console.error(error);

                let errorData = error?.response?.data;

                if(!errorData) {
                    errorData = {
                        error: error?.message
                    };
                }

                if (typeof props.onSubmitApiFailure === "function") {
                    props.onSubmitApiFailure(errorData, data);
                }

                callback(errorData);
            });
    }

    /** Validate all fields, disables all the fields if validation is passed, toggle a loading state, and submit the form to either (in order of preference):
     * a custom onSubmit function
     * a custom onSubmit function after the Robostack form magic submission has been called 
     * a custom submit api endpoint after the Robostack form magic submission has been called 
     * @summary Function that gets called when the submit button is clicked
    *  @param {Event} event - The browser's DOM event - unused
    */
    onSubmit() {
        console.log("onSubmit");

        if (Array.isArray(this.props.fields)) {
            this.validateAll("state", (err, validationResults) => {
                if (err) {
                    console.error("Validation error");
                    console.error(err);

                    this.setState({
                        error: "Validation of the form failed",
                    });
                } else {
                    const hasErrors = validationResults.some(result => result.error !== undefined && result.error.length > 0);

                    if (hasErrors) {
                        this.setState({
                            error: defaultValidationMessages.invalidData,
                        });
                    } else {
                        this.disableAllFields(true, (err, disabled) => {
                            // Process all the fields and get the values from them
                            const fieldsToBeProcessed = this.props.fields.map((fieldsInstance, instanceIndex) => {
                                return Object.keys(fieldsInstance).map((fieldName) => {
                                    let fieldConfiguration = this.buildFieldConfiguration(fieldsInstance, instanceIndex, fieldName);

                                    if (supportedFieldTypes[fieldConfiguration.type]) {
                                        const Field = supportedFieldTypes[fieldConfiguration.type];

                                        return {
                                            Field,
                                            fieldConfiguration,
                                        };
                                    }
                                });
                            }).flat().filter((field) => field != undefined);

                            const fileFields = fieldsToBeProcessed.filter(({ fieldConfiguration }) => fieldConfiguration.type == "file");
                            const fieldsWithoutFileFields = fieldsToBeProcessed.filter(({ fieldConfiguration }) => fieldConfiguration.type != "file");

                            map(fieldsWithoutFileFields, ({ Field, fieldConfiguration }, callback) => {
                                const value = this.getCorrectValue(fieldConfiguration.instanceIndex, fieldConfiguration.fieldName);

                                Field.process({
                                    fieldConfiguration,
                                    value,
                                }, callback);
                            }, (err, results) => {
                                if (err) {
                                    console.error(err);

                                    this.setState({
                                        error: "There was an error in validating the form. Please try again.",
                                    }, () => {
                                        this.disableAllFields(false);
                                    });
                                } else {
                                    // Convert the results into a tuple
                                    const tuple = results.reduce((acc, currentValue) => {
                                        acc[currentValue.fieldConfiguration.fieldName] = currentValue.value;

                                        return acc;
                                    }, {});

                                    // Process the file fields now because the file name might depend on the value
                                    // given here

                                    // First lets get a list of all the file names because we might need it for the `filename` functionality
                                    const fileNames = fileFields.reduce((acc, currentFile) => {
                                        let value = currentFile.fieldConfiguration.value;

                                        if (value !== undefined && value !== null && value.name !== undefined) {
                                            value = decodeURI(value.name.split('/').pop());
                                        }

                                        acc[currentFile.fieldConfiguration.fieldName] = value;

                                        return acc;
                                    }, {});

                                    map(fileFields, ({ Field, fieldConfiguration }, callback) => {
                                        const value = this.getCorrectValue(fieldConfiguration.instanceIndex, fieldConfiguration.fieldName);

                                        if (value !== null) {
                                            Field.process({
                                                fieldConfiguration,
                                                value,
                                                fields: {
                                                    ...tuple,
                                                    ...fileNames,
                                                },
                                            }, callback);
                                        } else {
                                            callback(null, {
                                                valid: true,
                                                fieldConfiguration,
                                                value: null,
                                            })
                                        }
                                    }, (err, results) => {
                                        if (err) {
                                            console.error(err);

                                            let errorMessage = "There was an error in uploading all the files";
                                            let stateToUpdate = this.state;

                                            if (err.errorMessage === true) {
                                                errorMessage = err.error.message;
                                            }

                                            // Let us mark this field as having an error as well
                                            if (err.fieldConfiguration !== undefined) {
                                                stateToUpdate = this.updateField(stateToUpdate, {
                                                    instanceIndex: err.fieldConfiguration.instanceIndex,
                                                    fieldName: err.fieldConfiguration.fieldName,
                                                    updateSpec: {
                                                        error: {
                                                            $set: errorMessage,
                                                        }
                                                    }
                                                })
                                            }

                                            stateToUpdate = update(stateToUpdate, {
                                                error: {
                                                    $set: "There was an error in uploading all the files",
                                                },
                                            })

                                            this.setState(stateToUpdate, () => {
                                                this.disableAllFields(false);
                                            });
                                        } else {
                                            // Convert the results into a tuple
                                            const fileTuple = results.reduce((acc, currentValue) => {
                                                acc[currentValue.fieldConfiguration.fieldName] = currentValue.value;

                                                return acc;
                                            }, {});

                                            // Merge into the original tuple
                                            const finalTuple = {
                                                ...tuple,
                                                ...fileTuple
                                            };

                                            if (this.props.onSubmit !== undefined && typeof this.props.onSubmit == "function") {
                                                this.disableAllFields(true, () => {
                                                    this.props.onSubmit({
                                                        tuple: finalTuple,
                                                    }, () => {
                                                        this.disableAllFields(false, () => {

                                                        });
                                                    });
                                                });
                                            } else if (this.props.submitApi !== undefined && typeof this.props.submitApi == "object") {
                                                this.submitTupleToSubmitAPI({
                                                    tuple: finalTuple,
                                                    props: this.props,
                                                    submitApi: this.props.submitApi
                                                }, (err, response) => {
                                                    this.disableAllFields(false, () => {
                                                        let stateToUpdate = this.state;

                                                        stateToUpdate = update(stateToUpdate, {
                                                            error: {
                                                                $set: "",
                                                            },
                                                            success: {
                                                                $set: "",
                                                            },
                                                        });

                                                        if (err) {
                                                            if (err.requestId !== undefined) {
                                                                stateToUpdate = update(stateToUpdate, {
                                                                    requestId: {
                                                                        $set: err.requestId,
                                                                    }
                                                                })
                                                            }

                                                            if (err.error !== undefined) {
                                                                stateToUpdate = update(stateToUpdate, {
                                                                    error: {
                                                                        $set: err.error
                                                                    }
                                                                })
                                                            }

                                                            if (Array.isArray(err.messages) && err.messages.length > 0) {
                                                                stateToUpdate = update(stateToUpdate, {
                                                                    error: {
                                                                        $set: err.messages.join(". "),
                                                                    }
                                                                })
                                                            }
                                                        } else {
                                                            if (response?.message !== undefined) {
                                                                stateToUpdate = update(stateToUpdate, {
                                                                    success: {
                                                                        $set: response.message,
                                                                    }
                                                                })
                                                            } else {
                                                                stateToUpdate = update(stateToUpdate, {
                                                                    success: {
                                                                        $set: this.props.submitSuccessMessage || "The form was submitted successfully"
                                                                    }
                                                                })
                                                            }
                                                        }

                                                        if (err && Array.isArray(err.errors)) {
                                                            stateToUpdate = update(stateToUpdate, {
                                                                error: {
                                                                    $set: defaultValidationMessages.invalidData
                                                                }
                                                            })

                                                            Object.keys(err.errors).forEach((instanceIndex) => {
                                                                const instance = err.errors[instanceIndex];
                                                                Object.keys(instance).forEach((fieldName) => {
                                                                    const messages = instance[fieldName].messages;

                                                                    stateToUpdate = this.updateField(stateToUpdate, {
                                                                        instanceIndex: instanceIndex,
                                                                        fieldName: fieldName,
                                                                        updateSpec: {
                                                                            error: {
                                                                                $set: messages.join(""),
                                                                            }
                                                                        }
                                                                    })
                                                                })
                                                            })

                                                        }

                                                        this.setState(stateToUpdate);
                                                    });
                                                })
                                            } else if (this.props.forceFormSubmission === true) {
                                                console.log("props.onForcedFormSubmission");
                                                if (typeof this.props.onForcedFormSubmission === "function") {
                                                    this.props.onForcedFormSubmission(null, finalTuple, (err, response) => {
                                                        console.log(err, response);

                                                        this.disableAllFields(false);
                                                    });
                                                } else {
                                                    this.setState({
                                                        error: "The form was not configured with a forced submission handler",
                                                        success: ""
                                                    }, () => {
                                                        this.disableAllFields(false);
                                                    });
                                                }
                                            } else {
                                                this.setState({
                                                    error: "The form was not configured to be submitted anywhere",
                                                    success: ""
                                                }, () => {
                                                    this.disableAllFields(false);
                                                });
                                            }
                                        }
                                    });
                                }
                            })
                        });
                    }
                }
            })
        }
    }

    /**
     * Render the success or error messages that are currently available in the state
     * @returns {HTMLElement} div with the error or success message or undefined
     */
    renderMessages() {
        if (this.state.error.length > 0) {
            return (
                <div className="alert alert-danger" role="alert">
                    <div className="row">

                        <div className="col-9">
                            {this.state.error}
                        </div>

                        <div className="col-3 text-right text-80 align-self-center">
                            {this.state.requestId.length > 0 && <>Request ID: {this.state.requestId}</>}
                        </div>
                    </div>
                </div>
            );
        } else if (this.state.success.length > 0) {
            return (
                <div className="alert alert-success" role="alert">
                    {this.state.success}<br />

                    {this.props.resourceName && this.props.appUUID && Array.isArray(this.state.insertIds) && this.state.insertIds.length > 0 && <>
                        {this.state.insertIds.map((insertId) => {
                            return (
                                <Link
                                    key={insertId}
                                    to={`/app/${this.props.appUUID}/resources/${this.props.resourceName}/view/${this.state.idField}/${insertId}/`}
                                    className="text-success"
                                >
                                    Click here to view the record.
                                </Link>
                            )
                        })}
                    </>}

                </div>
            );
        } else {
            return undefined;
        }
    }

    render() {
        let fields = this.props.fields;

        console.log(this.constructor.name, "render");

        return (
            <form data-test="component-magic-form" >
                {Array.isArray(fields) ?
                    <>
                        <div className="row">
                            <div className="col-sm-12">
                                {this.renderMessages()}
                            </div>
                        </div>

                        <div data-test="component-magic-form-fields-parent">
                            {Array.isArray(fields) && fields.map(this.renderField.bind(this))}
                        </div>
                        <div data-test="component-magic-form-buttons-wrapper" className="row pad-top">

                            <div data-test="component-magic-form-error" className="col-sm-12">
                                {this.renderMessages()}
                            </div>

                            <div className="col-sm-12">
                                <div data-test="component-magic-form-buttons-parent" className="btn-group">
                                    {this.props.dynamicInstances !== undefined ?
                                        <button
                                            type="button"
                                            aria-label={!!this.props.addInstanceText ? this.props.addInstanceText : "Add Another Field"}
                                            className={Array.isArray(this.props.addInstanceButtonClasses) ? this.props.addInstanceButtonClasses.join(" ") : "btn btn-primary"}
                                            onClick={this.addInstance.bind(this)}
                                            disabled={this.state.submitting}
                                        >
                                            <i className="fas fa-plus"></i>&nbsp;{!!this.props.addInstanceText ? this.props.addInstanceText : "Add Another Field"}
                                        </button>
                                        :
                                        undefined
                                    }

                                    {fields.length > 0 && (this.props.showSubmitButton == undefined || this.props.showSubmitButton === true) ?
                                        <button
                                            data-test="component-magic-form-submit-button"
                                            type="button"
                                            disabled={this.state.submitting}
                                            onClick={this.onSubmit.bind(this)}
                                            {
                                            ...{
                                                className: DefaultClasses.submitButton.join(" "),
                                                ...this.props.submitButtonProps
                                            }
                                            }
                                        >
                                            {!!this.state.submitting ?
                                                <span>&nbsp;Submitting...</span>
                                                :
                                                <span><i className={DefaultClasses.submitButtonIcon.join(" ")}></i>
                                                    &nbsp;{this.props.submitButtonText || "Submit"}
                                                </span>
                                            }
                                        </button>
                                        :
                                        undefined
                                    }

                                    {fields.length > 0 && this.props.showResetButton === true ?

                                        <button
                                            onClick={this.props.onResetButtonClicked}
                                            data-test="component-magic-form-reset-button"
                                            type="button"
                                            disabled={this.state.submitting}
                                            {
                                            ...{
                                                className: DefaultClasses.resetButton.join(" "),
                                                ...this.props.resetButtonProps
                                            }
                                            }
                                        >
                                            <span><i className={DefaultClasses.resetButtonIcon.join(" ")}></i>
                                                &nbsp;{this.props.resetButtonText || "Reset"}
                                            </span>
                                        </button>
                                        :
                                        undefined
                                    }

                                    {fields.length > 0 && (this.props.showCancelButton === true) ?

                                        <button
                                            onClick={this.props.onCancelButtonClick}
                                            data-test="component-magic-form-cancel-button"
                                            type="button"
                                            disabled={this.state.submitting}
                                            {
                                            ...{
                                                className: DefaultClasses.cancelButton.join(" "),
                                                ...this.props.cancelButtonProps
                                            }
                                            }
                                        >
                                            <span><i className={DefaultClasses.cancelButtonIcon.join(" ")}></i>
                                                &nbsp;{this.props.cancelButtonText || "Cancel"}
                                            </span>
                                        </button>
                                        :
                                        undefined
                                    }
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <span data-test="component-magic-form-misconfiguration-error">This form is unable to be displayed because there are no fields configured.</span>
                }
            </form>
        );
    }
}

export default connect(mapStateToProps)(MagicForm);

/*
Regular: one field after the other vertically
fields: [{
    Name: {
        "label": "",
        "type": "text",
        "placeholder": "",
        "position": 1
    }]
}

Dynamic Regular: fields are different
fields: [
    {
        Name: {
            "label": "",
            "type": "text",
            "placeholder": "",
            "position": 1
        },
        Age: {
            "label": "",
            "type": "text",
            "placeholder": "",
            "position": 2
        }
    },
    {
        Name: {
            "label": "",
            "type": "text",
            "placeholder": "",
            "position": 1
        }
    },

    ]
}

Multiple: one set of fields rendered multiple times
fields: [{
    Name: {
        "label": "",
        "type": "text",
        "placeholder": "",
        "position": 1
    }, {
    Name: {
        "label": "",
        "type": "text",
        "placeholder": "",
        "position": 1
    }]
}

Multiple Dynamic:
fields: [
    {
        Name: {
            "label": "",
            "type": "text",
            "placeholder": "",
            "position": 1
        },
        Age: {
            "label": "",
            "type": "text",
            "placeholder": "",
            "position": 2
        }
    },
    {
        Name: {
            "label": "",
            "type": "text",
            "placeholder": "",
            "position": 1
        }
    },
]
*/