
import React from "react";
import { connect } from "react-redux";

import * as DataDelegator from "../delegator/DataDelegator";

import ToTitleCase from "../../../core/utils/toTitleCase";
import SimpleRenderField from "./utils/SimpleRenderField";

import { AUTOMATIC_TYPES_TO_SKIP } from "core/utils/datatypes/magic-field-types";

class RecordDiffViewer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            zoom: 1,
        };

        this.tableRef = React.createRef();

        this.changeFontSize = this.changeFontSize.bind(this);
    }

    componentDidMount() {
        DataDelegator.resolve(this.props);
    }

    componentDidUpdate(prevProps) {
        // Update only if the site and the resource name has changed
        if (this.props.site !== prevProps.site || this.props.resource !== prevProps.resource || this.props.identifier !== prevProps.identifier || this.props.value !== prevProps.value) {
            DataDelegator.resolve(this.props);
        }
    }

    changeFontSize(direction) {
        let sizeInEm = this.state.zoom;

        if (direction == "-") {
            sizeInEm -= .2;
        } else {
            sizeInEm += .2;
        }

        this.setState({
            zoom: sizeInEm
        }, () => {
            if (this.tableRef.current !== null) {
                this.tableRef.current.style.fontSize = `${sizeInEm}em`;
            }
        });
    }

    render() {
        return (
            <div className="panel">
                <div className="panel-body">
                    <div className="row mb-4 pb-4">
                        <div className="col-sm-4">
                            <a onClick={() => this.changeFontSize("-")} className="btn btn-default d-print-none"><i className="fas fa-search-minus"></i>&nbsp;Zoom Out</a>
                            <a onClick={() => this.changeFontSize("+")} className="btn btn-default d-print-none"><i className="fas fa-search-plus"></i>&nbsp;Zoom In</a>
                        </div>
                    </div>

                    <table ref={this.tableRef} id='data-record' className="table table-hover table-vcenter">
                        {!(this.props.new != undefined && this.props.new.length > 0 && this.props.old != undefined && this.props.old.length > 0 && this.props.old.length == this.props.new.length) ? <>
                            <tbody>
                                <tr>
                                    <th colSpan={2} className="text-center">
                                        Both the new data and old data to compate must be of the same length
                                    </th>
                                </tr>
                            </tbody>
                        </>
                            :
                            <>
                                {this.props.old.map((data, index) => { // In case there are multiple values returned
                                    const body = this.props.columns
                                        .filter((column) => {
                                            const oldValue = data[column.fieldName];
                                            const newValue = this.props.new[index][column.fieldName];

                                            if (
                                                column.fieldName != "robostack_id" &&
                                                AUTOMATIC_TYPES_TO_SKIP.indexOf(column.fieldType.toUpperCase()) == -1 &&
                                                oldValue != newValue
                                            ) {
                                                return true;
                                            }
                                        })
                                        .map((column) => {
                                            return (
                                                <tr key={column.fieldName}>
                                                    <td><strong>{ToTitleCase(column.fieldName)}</strong></td>
                                                    <td>
                                                        <div>
                                                            
                                                            <s>{data[column.fieldName] == null ? "null" : SimpleRenderField({
                                                                data: data,
                                                                column,
                                                                applicationUsers: this.props.applicationUsers,
                                                            })}</s>
                                                        </div>

                                                        <div>
                                                            <span>{this.props.new[index][column.fieldName] == null ? "null" : SimpleRenderField({
                                                                data: this.props.new[index],
                                                                column,
                                                                applicationUsers: this.props.applicationUsers,
                                                            })}</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        });

                                    return (
                                        <tbody key={index} className="m-b-xl">
                                            {body}
                                        </tbody>
                                    );
                                })}
                            </>}
                    </table>
                </div>
            </div>
        );
    }
}

export default connect(DataDelegator.mapStateToProps)(RecordDiffViewer);