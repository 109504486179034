import axiosBackend from "core/api/backend";

export default function LoadQueryFromPlayground({
    appUUID,
    queryUUID,
}) {
    return axiosBackend({
        method: "POST",
        url: `/service/query-playground/${appUUID}/${queryUUID}`,
    })
}