import React, { useEffect } from "react";
import * as ReactDOMServer from 'react-dom/server';
import { Link, useParams } from "react-router-dom";

import { FaSortAmountDownAlt, FaSortAmountUpAlt } from "react-icons/fa";
import { BiFilter, BiSortAlt2 } from "react-icons/bi";
import { VscChromeClose } from "react-icons/vsc";


import axiosBackend from "../../../../core/api/backend";

import API_STATES from "../../../../api-states";

import Loader from "../../../../components/Loader";
import ErrorPage from "../../../error";

import { CompactSelection, DataEditor, GridCellKind } from "@glideapps/glide-data-grid";
import "@glideapps/glide-data-grid/dist/index.css";

import { useLayer } from "react-laag";

import UrlCell from "./field-types/url";
import FileCell from "./field-types/file";
import SpinnerCell from "./field-types/spinner";

import Select from "react-select";

import update from "immutability-helper";

import isNumericField from "../../../../core/utils/datatypes/isNumericField";
import isUniqueField from "../../../../core/utils/datatypes/isUniqueField";

import getRecordUrl from "../../../../core/utils/records/getRecordUrl";

import "../../../../scss/pages/data-table.scss";
import getDisplayDataForLookupField from "../../../../core/utils/datatypes/lookup/getDisplayDataForLookupField";

import withLoaderData from "../../../../components/withLoaderData";
import { connect } from "react-redux";

import { Parser } from '@json2csv/plainjs';

function DataTable(props) {
    const params = useParams();

    const [status, setStatus] = React.useState(API_STATES.LOADING);

    const [columns, setColumns] = React.useState([]);
    const [uniqueFields, setUniqueFields] = React.useState([]);
    const [data, setData] = React.useState([]);
    const [users, setUsers] = React.useState({});

    const [originalData, setOriginalData] = React.useState([]);

    const [userLookupFieldsStatus, setUserLookupFieldsStatus] = React.useState(API_STATES.LOADING);
    const [lookupFieldsStatus, setLookupFieldsStatus] = React.useState(API_STATES.LOADING);
    const [aggregationFieldsStatus, setAggregationFieldsStatus] = React.useState(API_STATES.LOADING);

    const [lookedupData, setLookedupData] = React.useState({});
    const [aggregateFieldsData, setAggregateFieldsData] = React.useState({});
    const [secondaryLookupFields, setSecondaryLookupFields] = React.useState({});
    const [secondaryComputedLookupData, setSecondaryComputedLookupData] = React.useState({});

    const [sortFields, setSortFields] = React.useState([]);
    const [filterFields, setFilterFields] = React.useState([]);

    const [showViews, setShowViews] = React.useState(false);

    const apiStartUrl = props.apiStartUrl || "service/resources";
    const appUUID = props.appUUID || params.appUUID;
    const resourceName = props.resourceName || params.resourceName;

    const resourcesBaseUrl = `/${apiStartUrl}/${appUUID}/${resourceName}`;

    useEffect(() => {
        if (status == API_STATES.LOADING) {
            Promise.all([
                axiosBackend.post(`${resourcesBaseUrl}/fields`),
                axiosBackend({
                    method: "POST",
                    url: `${resourcesBaseUrl}`,
                    data: {

                    }
                }),
            ])
                .then(([columnsResponse, dataResponse]) => {
                    setColumns(() => {
                        const columns = columnsResponse.data.results.map((column) => {
                            column.title = column.fieldName;
                            column.id = column.fieldName;

                            column.hasMenu = true;

                            column.width = 250;

                            switch (column.fieldType) {
                                case "FILE": {
                                    // column.width = 120;
                                    // column.icon = "file";
                                    break;
                                }

                                case "MARKDOWN": {
                                    // column.icon = "markdown";
                                    break;
                                }

                                case "STRING": {
                                    // column.icon = "custom";
                                    break;
                                }
                            }

                            return column;
                        }).sort((a, b) => {
                            if (a.displayPosition === null) {
                                return 1; // move `a` to a higher index
                            } else if (b.displayPosition === null) {
                                return -1; // move `b` to a higher index
                            } else {
                                return a.displayPosition - b.displayPosition;
                            }
                        }).filter(column => column.fieldName !== "robostack_id");

                        const uniqueFieldsList = columns.filter((column) => isUniqueField(column.fieldType) && column.fieldName !== "robostack_id");
                        const uniqueFieldsNames = uniqueFieldsList.map((column) => column.fieldName);

                        setUniqueFields(uniqueFieldsNames);

                        return columns;

                    });

                    const autoNumberFields = columnsResponse.data.results.filter((field) => {
                        return field.fieldType == "AUTONUMBER";
                    })

                    setData(dataResponse.data.results);
                    setOriginalData(dataResponse.data.results);

                    if (sortFields.length == 0 && autoNumberFields.length > 0) {
                        setSortFields(autoNumberFields.map((field) => {
                            return {
                                ...field,
                                value: field.fieldName,
                                display: field.fieldName,
                                direction: -1,
                            }
                        }));
                    } else if (sortFields.length == 0) {
                        const autodateFields = columnsResponse.data.results.filter((field) => {
                            return field.fieldType == "AUTODATE";
                        })

                        if (autodateFields.length > 0) {
                            setSortFields(autodateFields.map((field) => {
                                return {
                                    ...field,
                                    value: field.fieldName,
                                    display: field.fieldName,
                                    direction: -1,
                                }
                            }));

                        }

                    } else {
                        applyFilterAndSort({ dataToUse: dataResponse.data.results });
                    }

                    setStatus(API_STATES.LOADED);
                })
                .catch((err) => {
                    console.error(err);

                    setStatus(API_STATES.ERROR);

                })
        }

    }, [status]);

    useEffect(() => {
        applyFilterAndSort({
            dataToUse: originalData,
        });
    }, [originalData, sortFields]);

    const applyFilterAndSort = ({event, dataToUse}) => {
        event?.preventDefault();

        applyFilter({
            event,
            dataToUse,
            callback: ({ data: filteredData }) => {
                applySort({ 
                    dataToUse: filteredData
                });
            }
        });
    }

    const reloadData = () => {
        setStatus(API_STATES.LOADING);
        setLookupFieldsStatus(API_STATES.LOADING);
        setUserLookupFieldsStatus(API_STATES.LOADING);
        setAggregationFieldsStatus(API_STATES.LOADING);
    }

    const onColumnMoved = React.useCallback((startIndex, endIndex) => {
        const newCols = [...columns];
        const [toMove] = newCols.splice(startIndex, 1);
        newCols.splice(endIndex, 0, toMove);
        setColumns(newCols);
    }, [columns]);

    const onColumnResize = React.useCallback((column, newSize) => {
        const index = columns.findIndex(ci => ci.title === column.title);

        if (["FILE"].includes(columns[index].fieldType) == false) {
            const newArray = [...columns];

            newArray.splice(index, 1, {
                ...columns[index],
                width: newSize,
            });

            setColumns(newArray);
        }
    }, [columns]);

    const getContent = React.useCallback((cell) => {
        const [col, row] = cell;
        const dataRow = data[row];
        // dumb but simple way to do this

        if (dataRow) {
            const indexes = columns.map(column => column.id);
            let value = dataRow[indexes[col]];

            // ?This should not load because it was ignored on top but just in case
            if (columns[col].fieldName == "robostack_id") {
                return {
                    kind: GridCellKind.RowID,
                    data: value,
                }
            }

            if (columns[col].fieldType == "BOOLEAN") {
                return {
                    kind: GridCellKind.Boolean,
                    data: value,
                }
            }

            if (columns[col].fieldType == "JSON_ARRAY") {
                if (value != null) {
                    return {
                        kind: GridCellKind.Bubble,
                        allowOverlay: true,
                        cursor: "pointer",
                        data: value,
                    }
                }
            }

            if (columns[col].fieldType == "JSON") {
                return {
                    kind: GridCellKind.Text,
                    displayData: JSON.stringify(value),
                    data: value,
                }
            }

            if (columns[col].fieldType == "CURRENCY") {
                if (value !== null && value !== undefined) {
                    return {
                        kind: GridCellKind.Text,
                        displayData: "₹" + value.toLocaleString("en-IN"),
                        data: value,
                    }
                }
            }

            // Everything below this is a string type
            if (value == null) {
                value = "";
            } else {
                value = value.toString();
            }

            if (columns[col].fieldType == "AUTONUMBER") {
                return {
                    kind: GridCellKind.RowID,
                    data: value.toString(),
                }
            }

            if (columns[col].fieldType == "AUTOUUID") {
                return {
                    kind: GridCellKind.RowID,
                    data: value.toString(),
                }
            }

            const defaultTextCell = {
                kind: GridCellKind.Text,
                allowOverlay: false,
                displayData: value,
                data: value,
                allowWrapping: true,
                themeOverride: {
                    cellVerticalPadding: 10,
                    lineHeight: 1.5
                }
            };

            if (columns[col].fieldType == "MARKDOWN") {
                return {
                    kind: GridCellKind.Markdown,
                    allowOverlay: true,
                    data: value,
                }
            }

            if (columns[col].fieldType == "FILE") {
                return {
                    kind: GridCellKind.Custom,
                    allowOverlay: true,
                    cursor: "pointer",
                    data: {
                        value,
                        kind: "file",
                    },
                }
            }

            if (columns[col].fieldType == "AGGREGATE") {
                const aggregatedDataForField = aggregateFieldsData[columns[col].fieldName];

                if (aggregatedDataForField !== undefined) {
                    const valueToReference = dataRow[columns[col].resourceFilterValue];
                    let aggregatedValue = aggregatedDataForField[valueToReference];

                    if (aggregatedValue !== null && aggregatedValue !== undefined) {
                        return {
                            kind: GridCellKind.Text,
                            displayData: "₹" + aggregatedValue.toLocaleString("en-IN"),
                            data: aggregatedValue,
                        }
                    }
                }
            }

            // Lookup fields
            if (columns[col].fieldType == "LOOKUP") {
                if (lookupFieldsStatus == API_STATES.LOADING) {
                    return {
                        kind: GridCellKind.Custom,
                        allowOverlay: true,
                        cursor: "pointer",
                        data: {
                            value,
                            kind: "spinner",
                        },
                    }
                }

                if (lookupFieldsStatus == API_STATES.LOADED) {
                    const siteUUIDToUse = columns[col].linksToSiteUUID || appUUID;
                    const lookedUpTuple = lookedupData[siteUUIDToUse][columns[col].fieldName][value];


                    if (lookedUpTuple != undefined) {
                        let displayData = getDisplayDataForLookupField({
                            currentColumn: columns[col],
                            lookedupData,
                            lookedUpTuple,
                            siteUUIDToUse,
                            secondaryLookupFields,
                            secondaryComputedLookupData,
                        });

                        if (displayData == null || displayData == undefined) {
                            displayData = "";
                        }

                        defaultTextCell.displayData = displayData;

                        return {
                            kind: GridCellKind.Custom,
                            allowOverlay: true,
                            displayData,
                            url: `/app/${siteUUIDToUse}/resources/${columns[col].linksToResource}/view/${columns[col].resourceLinkingKey}/${value}`,
                            copyData: displayData,
                            data: {
                                value,
                                kind: "url",
                            },
                        }
                    }

                    return defaultTextCell;
                }
            }

            // Auto generated field types
            if (["USER", "USER_UPDATE"].includes(columns[col].fieldType)) {
                if (userLookupFieldsStatus == API_STATES.LOADING) {
                    return {
                        kind: GridCellKind.Custom,
                        allowOverlay: true,
                        cursor: "pointer",
                        data: {
                            value,
                            kind: "spinner",
                        },
                    }
                }

                if (userLookupFieldsStatus == API_STATES.LOADED) {
                    if (value != "" && users[value] != undefined) {
                        defaultTextCell.displayData = users[value];
                    }

                    return defaultTextCell;
                }
            }

            return defaultTextCell;
        }

        return {
            kind: GridCellKind.Text,
            value: "",
        }
    }, [data, lookupFieldsStatus, userLookupFieldsStatus, aggregationFieldsStatus, lookedupData, secondaryLookupFields]);

    const loadLookupFields = async () => {

        const lookupResults = await axiosBackend({
            method: "POST",
            url: `${resourcesBaseUrl}/lookup`,
            data: {

            }
        });

        const lookupFields = columns.filter((column) => column.fieldType == "LOOKUP");

        let lookupFieldsMapped = lookupFields.reduce((acc, field) => {
            const siteUUIDToUse = field.linksToSiteUUID || appUUID;

            if (acc[siteUUIDToUse] == undefined) {
                acc[siteUUIDToUse] = {};
            }

            acc[siteUUIDToUse][field.fieldName] = {
                linksToSiteUUID: field.linksToSiteUUID,
                tableName: field.linksToResource,
                resourceLinkingKey: field.resourceLinkingKey,
            }

            return acc;
        }, {});

        if (lookupResults.data.results.length == 1) {
            const applications = lookupResults.data.results[0].secondaryLookupFields;

            lookupFieldsMapped = Object.keys(applications).reduce((appAcc, appUUID) => {
                const tables = applications[appUUID];

                Object.keys(tables).reduce((acc, table) => {
                    const fields = tables[table];

                    Object.keys(fields).reduce((innerAcc, field) => {
                        const fieldData = fields[field];
                        const siteUUIDToUse = field.linksToSiteUUID || appUUID;

                        if (innerAcc == undefined) {
                            innerAcc = {};
                        }

                        if (innerAcc[siteUUIDToUse] == undefined) {
                            innerAcc[siteUUIDToUse] = {};
                        }

                        if (innerAcc[siteUUIDToUse][field] == undefined) {
                            innerAcc[siteUUIDToUse][field] = {
                                linksToSiteUUID: fieldData.linksToSiteUUID,
                                tableName: fieldData.linksToResource,
                                resourceLinkingKey: fieldData.resourceLinkingKey,
                            }
                        }

                        return innerAcc;
                    }, acc);
                }, appAcc);

                return appAcc;
            }, lookupFieldsMapped);


            const resultsMappedToTable = lookupResults.data.results[0].result.reduce((acc, tableResult) => {
                if (acc[tableResult.siteUUID] == undefined) {
                    acc[tableResult.siteUUID] = {};
                }

                acc[tableResult.siteUUID][tableResult.tableName] = tableResult.results;
                return acc;
            }, {});

            const computedLookedupData = Object.keys(lookupFieldsMapped).reduce((acc, appUUID) => {
                const fields = lookupFieldsMapped[appUUID];

                Object.keys(fields).reduce((acc, fieldName) => {
                    const tableName = fields[fieldName].tableName;
                    const tableData = resultsMappedToTable[appUUID][tableName];

                    if (acc[appUUID] == undefined) {
                        acc[appUUID] = {};
                    }

                    acc[appUUID][fieldName] = tableData.reduce((innerAcc, row) => {
                        const linkingKey = fields[fieldName].resourceLinkingKey;

                        innerAcc[row[linkingKey]] = row;
                        return innerAcc;
                    }, {});

                    return acc;
                }, acc);

                return acc;
            }, {});

            const secondary = lookupResults.data.results[0].secondaryLookupFields;
            const secondaryComputedLookupData = Object.keys(secondary).reduce((appsAcc, appUUID) => {
                const tables = secondary[appUUID];

                appsAcc[appUUID] = Object.keys(tables).reduce((tablesAcc, table) => {
                    const fields = tables[table];

                    Object.keys(fields).forEach((field) => {
                        const {
                            fieldName,
                            linksToResource,
                            resourceDisplayKey,
                            resourceDisplayKeysMultiple,
                            resourceLinkingKey,
                            linksToSiteUUID,
                        } = fields[field];

                        const dataToMap = resultsMappedToTable?.[linksToSiteUUID]?.[linksToResource];

                        if(dataToMap) {
                            const mappedData = dataToMap.reduce((acc, row) => {
                                const key = row[resourceLinkingKey];

                                if (acc[key] == undefined) {
                                    acc[key] = row;
                                }

                                return acc;
                            }, {});

                            tablesAcc[linksToResource] = mappedData;
                        }
                    });

                    return tablesAcc;
                }, {});

                return appsAcc;
            }, {});

            setSecondaryComputedLookupData(secondaryComputedLookupData);

            setSecondaryLookupFields(lookupResults.data.results[0].secondaryLookupFields);
            setLookedupData(computedLookedupData);
        }

        setLookupFieldsStatus(API_STATES.LOADED);
    }

    const loadUsersIdentifiers = async () => {

        const usersFieldsResult = await axiosBackend({
            method: "POST",
            url: `${resourcesBaseUrl}/users`,
            data: {

            }
        });

        const applicationUsers = usersFieldsResult.data.results.reduce((acc, user) => {
            acc[user.uuid] = user.email;
            return acc;
        }, {});

        setUsers(applicationUsers);
        setUserLookupFieldsStatus(API_STATES.LOADED);
    }

    const loadAggregationValues = async () => {

        const usersFieldsResult = await axiosBackend({
            method: "POST",
            url: `${resourcesBaseUrl}/aggregation-fields`,
            data: {

            }
        });

        if (usersFieldsResult.data.results.length > 0) {
            const mapped = usersFieldsResult.data.results.reduce((acc, field) => {
                acc[field.fieldName] = field.results.reduce((innerAcc, row) => {
                    innerAcc[row._id] = row.value;

                    return innerAcc;
                }, {});
                return acc;
            }, {});

            setAggregateFieldsData(mapped);
        }

        setAggregationFieldsStatus(API_STATES.LOADED);
    }

    React.useEffect(() => {
        if (columns.length > 0 && data.length > 0) {
            if (userLookupFieldsStatus === API_STATES.LOADING) {
                loadUsersIdentifiers();
            }

            if (lookupFieldsStatus == API_STATES.LOADING) {
                loadLookupFields();
            }

            if (aggregationFieldsStatus == API_STATES.LOADING) {
                loadAggregationValues();
            }
        }

    }, [columns, data, status]);

    const [hoverRow, setHoverRow] = React.useState(undefined);
    const [selectedRow, setSelectedRow] = React.useState();
    const [selectedRows, setSelectedRows] = React.useState(CompactSelection.empty());

    const [temporaryGridSelection, setTemporaryGridSelection] = React.useState(undefined);
    const [gridSelection, setGridSelection] = React.useState(undefined);

    const onGridSelectionChange = React.useCallback((newSelection) => {
        if (newSelection.rows.length == data.length || newSelection.rows.length == 0) {
            setSelectedRows(newSelection.rows);
            setGridSelection(newSelection);
            setTemporaryGridSelection(newSelection);
        } else {
            setTemporaryGridSelection(newSelection);
        }

    }, [data])

    const onCellClicked = React.useCallback((item, event) => {
        const [col, row] = item;
        setSelectedRow(row);

        // The checkbox was clicked
        if (col == -1) {
            setGridSelection(_ => {
                let newSelection = { ...temporaryGridSelection };

                if (temporaryGridSelection.rows.length != 0) {
                    if (selectedRows.hasIndex(row)) {
                        newSelection.rows = selectedRows.remove(row);
                    } else {
                        newSelection.rows = selectedRows.add([row, row + 1]);
                    }
                }

                setSelectedRows(newSelection.rows);

                return newSelection;
            });
        }


    });

    const highlights = React.useMemo(() => {
        if (selectedRow === undefined) {
            return undefined;
        }

        return [
            {
                color: "#cae6fe",
                range: {
                    x: -1,
                    y: selectedRow,
                    width: columns.length,
                    height: 1,
                },
                style: "solid",
            },
        ];
    }, [selectedRow]);

    const getRowThemeOverride = React.useCallback((row) => {
        let bgCell = undefined
        let bgCellMedium = undefined;

        if (row == selectedRow) {
            return undefined;
        }

        if (row % 2 === 0) {
            bgCell = "#F2F2F2";
        }

        if (row == hoverRow) {
            bgCell = "#EBEBEB";
            bgCellMedium = "#f0f0f0";
        }

        return {
            bgCell,
            bgCellMedium,
        }
    }, [hoverRow, selectedRow]);

    const onItemHovered = React.useCallback((args) => {
        const [_, row] = args.location;
        setHoverRow(args.kind !== "cell" ? undefined : row);
    }, []);

    const [menu, setMenu] = React.useState();
    const isOpen = menu !== undefined;

    const { layerProps, renderLayer } = useLayer({
        isOpen,
        auto: true,
        placement: "bottom-end",
        triggerOffset: 2,
        onOutsideClick: () => {
            // this is a fix for https://github.com/glideapps/glide-data-grid/issues/553
            if (performance.now() - menu.timestamp > 100) {
                setMenu(undefined)
            }
        },
        trigger: {
            getBounds: () => ({
                left: menu?.bounds.x ?? 0,
                top: menu?.bounds.y ?? 0,
                width: menu?.bounds.width ?? 0,
                height: menu?.bounds.height ?? 0,
                right: (menu?.bounds.x ?? 0) + (menu?.bounds.width ?? 0),
                bottom: (menu?.bounds.y ?? 0) + (menu?.bounds.height ?? 0),
            }),
        },
    });

    const onHeaderMenuClick = React.useCallback((col, bounds) => {
        if (menu?.col == col) {
            setMenu(undefined)
        } else {
            setMenu({
                col,
                bounds,
                timestamp: performance.now(),
            });
        }
    }, [menu]);

    const onHeaderClicked = React.useCallback((columnIndex) => {

        const field = columns[columnIndex];

        if (sortFields.length > 0) {
            const existingSortFieldIndex = sortFields.findIndex((existingField) => existingField.value == field.fieldName);

            if (existingSortFieldIndex > -1) {
                const existingSortFields = [...sortFields];

                // This was ascending order so lets remove it since the previous one by default was descending
                if (existingSortFields[existingSortFieldIndex].direction == 1) {
                    const newSortFields = JSON.parse(JSON.stringify(sortFields));
                    newSortFields.splice(existingSortFieldIndex, 1);

                    return setSortFields([
                        ...newSortFields,
                    ]);
                } else {
                    existingSortFields[existingSortFieldIndex] = {
                        ...existingSortFields[existingSortFieldIndex],
                        direction: existingSortFields[existingSortFieldIndex].direction * -1,
                    };
                }

                return setSortFields(existingSortFields);
            }
        }

        setSortFields([
            ...sortFields,
            {
                ...field,
                value: field.fieldName,
                display: field.fieldName,
                direction: -1,
            }
        ]);
    }, [columns, sortFields]);

    // This is to get rid of the ugly select color shown in the header when the column is selected
    const drawHeader = React.useCallback(args => {
        const { ctx, rect } = args;
        ctx.rect(rect.x, rect.y, rect.width, rect.height);

        const lg = ctx.createLinearGradient(0, rect.y, 0, rect.y + rect.height);
        lg.addColorStop(0, "#ffffff");

        ctx.fillStyle = lg;
        ctx.fill();

        return false;
    }, []);

    const [isDropdownShown, setDropdownShown] = React.useState({
        filter: false,
        sort: false,
        records: false,
        columns: false,
    });

    const toggleDropdown = React.useCallback((dropdownName) => {
        setDropdownShown((existingState) => {
            let newState = { ...existingState };

            newState[dropdownName] = !newState[dropdownName];

            if (newState[dropdownName] == true) {
                // Hide everything else 
                newState = Object.keys(newState).filter(name => name != dropdownName).reduce((acc, name) => {
                    acc[name] = false;

                    return acc;
                }, newState);
            }

            return newState;
        })
    })

    const addFilterField = () => {
        setFilterFields((existingFilterFields) => {
            return [
                ...existingFilterFields,
                {
                    searchValue: "",
                }
            ]
        });
    }

    const removeFilterField = (index) => {
        setFilterFields((existingFilterFields) => {
            const newFilterFields = [...existingFilterFields];

            newFilterFields.splice(index, 1);

            return newFilterFields;
        });
    }

    const changeFilter = ({ index, value, searchValue }) => {
        setFilterFields((existingFilterFields) => {
            const newFilterFields = [...existingFilterFields];

            let newFilter = {

            };

            if (value !== undefined) {
                newFilter = {
                    ...value
                }
            } else {
                newFilter = {
                    ...newFilterFields[index],
                }
            }

            if (searchValue !== undefined) {
                newFilter.searchValue = searchValue;
            }

            newFilterFields[index] = newFilter;

            return newFilterFields;
        });
    }

    const applyFilter = ({dataToUse, event, callback}) => {
        event?.preventDefault();

        let dataToFilter = dataToUse;

        if (filterFields.length > 0) {
            // Because after using the setState hook, react will have attached it's own data structure to the data array
            dataToFilter = dynamicFilter(JSON.parse(JSON.stringify(dataToUse)), filterFields);
        }

        setData(dataToFilter)

        callback && callback({
            data: dataToFilter,
        });
    }

    function dynamicFilter(arr, filterFields) {
        let filteredData = arr;

        for (let index = 0; index < filterFields.length; index++) {
            filteredData = filteredData.filter((tuple) => {
                const key = filterFields[index].fieldName;
                const searchValue = filterFields[index].searchValue;

                let value = tuple[key];

                if (filterFields[index].fieldType == "LOOKUP") {
                    if (value !== null) {
                        const siteUUIDToUse = filterFields[index].linksToSiteUUID || appUUID;
                        const lookedUpTuple = lookedupData[siteUUIDToUse][filterFields[index].fieldName][value];

                        if (lookedUpTuple != undefined) {
                            value = getDisplayDataForLookupField({
                                currentColumn: filterFields[index],
                                lookedupData,
                                lookedUpTuple,
                                siteUUIDToUse,
                                secondaryLookupFields,
                            })
                        }
                    }
                }

                if (filterFields[index].fieldType == "AGGREGATE") {
                    // We are not checking for null here because aggregate field values are usually null when we get it from the api
                    const aggregatedDataForField = aggregateFieldsData[filterFields[index].fieldName];

                    if (aggregatedDataForField !== undefined) {
                        const valueToReference = tuple[filterFields[index].resourceFilterValue];
                        let aggregatedValue = aggregatedDataForField[valueToReference];

                        if (aggregatedValue !== undefined) {
                            value = aggregatedValue;
                        }
                    }
                }

                if (value == null || value == undefined) {
                    value = "";
                }

                if (filterFields[index].fieldType == "JSON_ARRAY") {
                    value = value.toString();
                }

                if (typeof value == "string") {
                    return value.toLowerCase().includes(searchValue.toLowerCase());
                } else {
                    return value == searchValue;
                }

            })
        }

        return filteredData;
    }

    const addSortField = () => {
        setSortFields((existingSortFields) => {
            return [
                ...existingSortFields,
                {
                    direction: 1,
                }
            ]
        });
    }

    const moveSortField = (index, direction) => {
        setSortFields((existingSortFields) => {
            const movement = direction == "up" ? -1 : 1;
            const newPosition = index + movement;

            // Move element at existingSortFields[index] up
            if (newPosition < 0 || newPosition > existingSortFields.length - 1) {
                return existingSortFields;
            } else {
                const a = existingSortFields[index];
                const b = existingSortFields[newPosition];

                existingSortFields = update(existingSortFields, {
                    [index]: {
                        $set: b,
                    },
                    [newPosition]: {
                        $set: a,
                    }
                });
            }

            return existingSortFields;
        })
    }

    function dynamicSort(arr, sortFields) {
        return arr.sort((a, b) => {
            for (let index = 0; index < sortFields.length; index++) {
                const key = sortFields[index].fieldName;
                const direction = sortFields[index].direction;

                let valueA = a[key];
                let valueB = b[key];

                if (sortFields[index].fieldType == "LOOKUP") {
                    if (valueA !== null) {

                        const siteUUIDToUse = sortFields[index].linksToSiteUUID || appUUID;
                        const lookedUpTuple = lookedupData[siteUUIDToUse][sortFields[index].fieldName][valueA];

                        if (lookedUpTuple != undefined) {
                            valueA = getDisplayDataForLookupField({
                                currentColumn: sortFields[index],
                                lookedupData,
                                lookedUpTuple,
                                siteUUIDToUse,
                                secondaryLookupFields,
                            })
                        }
                    }

                    if (valueB !== null) {
                        const siteUUIDToUse = sortFields[index].linksToSiteUUID || appUUID;
                        const lookedUpTuple = lookedupData[siteUUIDToUse][sortFields[index].fieldName][valueB];

                        if (lookedUpTuple != undefined) {

                            valueB = getDisplayDataForLookupField({
                                currentColumn: sortFields[index],
                                lookedupData,
                                lookedUpTuple,
                                siteUUIDToUse,
                                secondaryLookupFields,
                            })
                        }
                    }
                }

                if (sortFields[index].fieldType == "AGGREGATE") {
                    // We are not checking for null here because aggregate field values are usually null when we get it from the api
                    const aggregatedDataForField = aggregateFieldsData[sortFields[index].fieldName];

                    if (aggregatedDataForField !== undefined) {
                        const valueToReferenceA = a[sortFields[index].resourceFilterValue];
                        let aggregatedValueA = aggregatedDataForField[valueToReferenceA];

                        if (aggregatedValueA !== undefined) {
                            valueA = aggregatedValueA;
                        }

                        const valueToReferenceB = b[sortFields[index].resourceFilterValue];
                        let aggregatedValueB = aggregatedDataForField[valueToReferenceB];

                        if (aggregatedValueB !== undefined) {
                            valueB = aggregatedValueB;
                        }
                    }
                }

                if (valueA == null) {
                    return 1;
                } else if (valueB == null) {
                    return -1;
                }

                if (valueA < valueB) {
                    return -1 * direction
                }

                if (valueA > valueB) {
                    return 1 * direction;
                }
            }

            return 0;
        });
    }

    const applySort = ({ dataToUse, callback }) => {
        let sortedData = dataToUse;

        if (sortFields.length > 0) {
            // Because after using the setState hook, react will have attached it's own data strucure to the data array
            sortedData = dynamicSort(JSON.parse(JSON.stringify(sortedData)), sortFields);
        }

        setData(sortedData)

        const newColumns = JSON.parse(JSON.stringify(columns));
        const columnsToSet = newColumns.map((column) => {
            const existingColumn = sortFields.find(sortField => sortField.fieldName == column.fieldName);

            if (existingColumn) {
                column.icon = existingColumn.direction == -1 ? "descending" : "ascending";
            } else {
                delete column.icon;
            }

            return column;
        });

        setColumns(columnsToSet);

        callback && callback({
            columns: columnsToSet,
            data: sortedData
        })
    }

    const removeSortField = (index) => {
        setSortFields((existingSortFields) => {
            const newSortFields = [...existingSortFields];

            newSortFields.splice(index, 1);

            return newSortFields;
        });
    }

    const changeSort = ({ index, value, direction }) => {
        setSortFields((existingSortFields) => {
            const newSortFields = [...existingSortFields];

            let newSort = {

            };

            if (value !== undefined) {
                newSort = {
                    ...value
                }
            } else {
                newSort = {
                    ...newSortFields[index],
                }
            }

            if (direction != undefined) {
                newSort.direction = direction;
            } else {
                newSort.direction = newSortFields[index].direction;
            }

            newSortFields[index] = newSort;

            return newSortFields;
        });
    }

    const performActionOnAll = (action) => {
        return () => {
            if (gridSelection !== undefined && gridSelection.rows.items.length > 0) {

                const selectedRows = [];

                for (let index = 0; index < gridSelection.rows.items.length; index++) {
                    const currentSelection = gridSelection.rows.items[index];

                    data.slice(currentSelection[0], currentSelection[1]).forEach((item) => selectedRows.push(item));
                }

                selectedRows.forEach((row) => {
                    let url = "";

                    if (action == "print") {
                        url = getRecordUrl(uniqueFields, row, "view");
                        url += "/#print";
                    } else {
                        url = getRecordUrl(uniqueFields, row, action);
                    }

                    window.open(url, "_blank");
                });
            }
        };
    };

    const viewAll = performActionOnAll("view");
    const editAll = performActionOnAll("edit");
    const printAll = performActionOnAll("print");

    const deleteAll = (event, selectedRecord) => {
        if (gridSelection !== undefined && gridSelection.rows.items.length > 0) {

            const dataToBeDeleted = [];

            for (let index = 0; index < gridSelection.rows.items.length; index++) {
                const currentSelection = gridSelection.rows.items[index];

                data.slice(currentSelection[0], currentSelection[1]).forEach((item) => dataToBeDeleted.push(item));
            }

            props.dispatch({
                type: "ResolvedData",
                name: "ModalData",
                data: {
                    "show": true,
                    "type": "confirmation",
                    "title": "Confirm Delete",
                    "message": [
                        "Are you sure you would like to delete these records?"
                    ],
                    yesButtonText: "Yes",
                    noButtonText: "No",
                    modalSize: "large",
                    components: [{
                        "component": "SimpleTable",
                        "size": 12,
                        "props": {
                            "filterable": true,
                            "className": "-striped -highlight",
                            "columns": columns.filter(column => column.hide !== true),
                            "data": dataToBeDeleted,
                        }
                    }],
                    onYes: (onClose) => {
                        props.dispatch({
                            type: "ResolvedData",
                            name: "ModalData",
                            data: {
                                "show": true,
                                "type": "processing",
                                "title": "Deleting Records",
                                "message": [
                                    "This might take a few seconds.",
                                    "You will be shown a confirmation screen once completed."
                                ]
                            },
                        });

                        setTimeout(() => {
                            axiosBackend({
                                method: "DELETE",
                                url: `${resourcesBaseUrl}`,
                                data: {
                                    selection: dataToBeDeleted
                                },
                            })
                                .then((response) => {
                                    props.dispatch({
                                        type: "ResolvedData",
                                        name: "ModalData",
                                        data: {
                                            show: true,
                                            type: "success",
                                            title: "Success",
                                            message: [
                                                response.data.results.reduce((acc, result) => {
                                                    return acc + result.recordsDeleted;
                                                }, 0) + " records were deleted successfully",
                                            ],
                                            okayButtonText: "Okay",
                                        },
                                    });

                                    setGridSelection(undefined);
                                    setSelectedRows(CompactSelection.empty());
                                    setTemporaryGridSelection(undefined)
                                    reloadData();
                                })
                                .catch((err) => {
                                    console.error(err);
                                    props.dispatch({
                                        type: "ResolvedData",
                                        name: "ModalData",
                                        data: {
                                            show: true,
                                            type: "error",
                                            title: "Could not delete records",
                                            message: [
                                                "Due to an error, we were unable to delete the records you had selected",
                                                "‏‏‎ ‎",
                                                "Please try again in a little while."
                                            ],
                                            okayButtonText: "Okay"
                                        },
                                    });
                                })
                        }, 1000);
                    }
                },
            });
        }
    }

    const exportData = (appUUID, resource, format) => {
        props.dispatch({
            type: "ResolvedData",
            name: "ModalData",
            data: {
                "show": true,
                "type": "processing",
                "title": "Processing Export",
                "message": [
                    "This might take a few seconds.",
                    "You will be shown a confirmation screen once the export file starts to download."
                ]
            },
        });

        const formattedData = data.map((tuple) => {
            const newTuple = columns.reduce((acc, column) => {
                acc[column.fieldName] = tuple[column.fieldName];

                if (column.fieldType == "LOOKUP") {
                    if (lookupFieldsStatus == API_STATES.LOADED) {
                        const siteUUIDToUse = column.linksToSiteUUID || appUUID;
                        const lookedUpTuple = lookedupData[siteUUIDToUse][column.fieldName][tuple[column.fieldName]];

                        if (lookedUpTuple != undefined) {
                            let displayData = getDisplayDataForLookupField({
                                currentColumn: column,
                                lookedupData,
                                lookedUpTuple,
                                siteUUIDToUse,
                                secondaryLookupFields,
                            });

                            if (displayData == null || displayData == undefined) {
                                displayData = "";
                            }

                            acc[column.fieldName] = displayData;
                        }
                    }
                }

                if (column.fieldType == "AGGREGATE") {
                    const aggregatedDataForField = aggregateFieldsData[column.fieldName];

                    if (aggregatedDataForField !== undefined) {
                        const valueToReference = tuple[column.resourceFilterValue];
                        let aggregatedValue = aggregatedDataForField[valueToReference];

                        if (aggregatedValue !== undefined) {
                            acc[column.fieldName] = aggregatedValue;
                        }
                    }

                }

                if (["USER", "USER_UPDATE"].includes(column.fieldType)) {
                    if (userLookupFieldsStatus == API_STATES.LOADED) {
                        if (tuple[column.fieldName] != "") {
                            acc[column.fieldName] = users[tuple[column.fieldName]];
                        }
                    }
                }

                return acc;
            }, {})

            return newTuple;
        })

        setTimeout(() => {
            try {
                if (format == "csv") {
                    const parser = new Parser({
                        eol: "\r\n",
                    });
                    const csv = parser.parse(formattedData);

                    const url = window.URL.createObjectURL(new Blob([csv]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", `${resource}.${format}`);
                    document.body.appendChild(link);
                    link.click();
                }

                if (format == "json") {
                    const downloadData = "text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(formattedData));

                    const link = document.createElement('a');
                    link.href = 'data:' + downloadData;
                    link.setAttribute("download", `${resource}.${format}`);

                    document.body.appendChild(link);
                    link.click();
                }

                props.dispatch({
                    type: "ResolvedData",
                    name: "ModalData",
                    data: {
                        show: true,
                        type: "success",
                        title: "Export Started",
                        message: [
                            "The export process has started.",
                            "You will see the exported file in the Downloads section of your browser."
                        ],
                        okayButtonText: "Okay",
                    },
                });
            } catch (error) {
                console.error(error);
                props.dispatch({
                    type: "ResolvedData",
                    name: "ModalData",
                    data: {
                        show: true,
                        type: "error",
                        title: "Export Failed",
                        message: [
                            "Due to a server error, we were unable to export your data.",
                            "Please try again in a little while."
                        ],
                        okayButtonText: "Okay"
                    },
                });
            }
        }, 2000);
    }

    const headerIcons = React.useMemo(() => {
        return {
            descending: p => ReactDOMServer.renderToString(<FaSortAmountDownAlt />),
            ascending: p => ReactDOMServer.renderToString(<FaSortAmountUpAlt />),
        };
    }, []);

    return (
        <>
            <div className="col">
                {status == API_STATES.LOADING && <Loader />}

                {status == API_STATES.ERROR && <ErrorPage hideSidebar={true} className="text-default" />}

                {status == API_STATES.LOADED &&

                    <div className="">
                        <div className="bg-white p-2">
                            <div className="inline">
                                {/* <button onClick={() => setShowViews(!showViews)} className="btn border-right pr-3">
                                    <i className="fas fa-bars"></i>&nbsp;Views | Default View
                                </button> */}
                                <button
                                    onClick={editAll}
                                    type="button"
                                    title="Edit"
                                    className="ml-2 btn"
                                    disabled={gridSelection == undefined || gridSelection.rows.items.length == 0}

                                ><i className="fas fa-edit"></i>
                                </button>

                                <button
                                    onClick={viewAll}
                                    type="button"
                                    title="View"
                                    className="btn"
                                    disabled={gridSelection == undefined || gridSelection.rows.items.length == 0}

                                >
                                    <i className="fas fa-eye"></i>
                                </button>

                                <button
                                    onClick={printAll}
                                    type="button"
                                    title="Print"
                                    className="btn"
                                    disabled={gridSelection == undefined || gridSelection.rows.items.length == 0}

                                >
                                    <i className="fas fa-print"></i>
                                </button>

                                <button
                                    onClick={deleteAll}
                                    type="button"
                                    title="Delete"
                                    className="btn border-right pr-3"
                                    disabled={gridSelection == undefined || gridSelection.rows.items.length == 0}
                                >
                                    <i className="fas fa-trash"></i>
                                </button>
                            </div>


                            <div className="inline">

                                <div className={`dropdown inline ${isDropdownShown.columns && "show"}`}>
                                    <div onClick={() => toggleDropdown("columns")} type="button" className="btn dropdown-toggle ml-2">
                                        <i className="far fa-eye-slash"></i>&nbsp;Hide Columns<i className="dropdown-caret"></i>
                                        <div onClick={event => event.stopPropagation()} className={`cursor-default dropdown-menu dropdown-menu-lg ${isDropdownShown.columns && "show"}`} id="columns-dropdown" data-container="body">
                                            <ul>
                                                {columns.map((column, index) => {
                                                    return (
                                                        <li key={`columns_dropdown_${column.fieldName}`} className="dropdown-item d-flex align-items-center pointer mt-1 mb-1">
                                                            {column.fieldName}
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className={`dropdown inline ${isDropdownShown.sort && "show"}`}>

                                    <div onClick={() => toggleDropdown("sort")} type="button" className="btn dropdown-toggle">
                                        <BiSortAlt2 size="1rem" />&nbsp;

                                        <span>
                                            Sort&nbsp;
                                        </span>

                                        {sortFields.length > 0 && `(${sortFields.length})`}
                                        <i className="dropdown-caret"></i>

                                        <div onClick={event => event.stopPropagation()} className={`cursor-default dropdown-menu dropdown-menu-lg ${isDropdownShown.sort && "show"}`} id="sort-dropdown" data-container="body">
                                            <span className="dropdown-header">Sort by</span>

                                            <div className="container pb-3">
                                                <div className="p-2 pt-2 mt-1 b-r-sm" style={{ "borderTop": "1px solid #e7eaec" }}>
                                                    {sortFields.length == 0 && "Not sorted currently"}

                                                    {sortFields.map((field, index) => {
                                                        const sortOptions = isNumericField(field.fieldType) ? [
                                                            {
                                                                value: -1,
                                                                display: "9 -> 0",
                                                            },
                                                            {
                                                                value: 1,
                                                                display: "0 -> 9",
                                                            },
                                                        ] : [
                                                            {
                                                                value: -1,
                                                                display: "Z -> A",
                                                            },
                                                            {
                                                                value: 1,
                                                                display: "A -> Z",
                                                            },
                                                        ];

                                                        const selectedSortOption = sortOptions.find((option) => option.value == field.direction);

                                                        return (
                                                            <div key={field?.fieldName || index} className="row mb-2">
                                                                <div className="col-5 pr-0">
                                                                    <Select
                                                                        menuPortalTarget={document.body}
                                                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                                        placeholder="Select field to sort by"
                                                                        getOptionValue={(option) => option.value}
                                                                        getOptionLabel={(option) => option.display}
                                                                        value={field?.fieldName ? field : undefined}
                                                                        options={columns.map((column) => {
                                                                            column.value = column.fieldName;
                                                                            column.display = column.fieldName;

                                                                            return column;
                                                                        })}
                                                                        onChange={(newValue) => changeSort({ index, value: newValue })}
                                                                    />
                                                                </div>

                                                                <div className="col-3 p-0 pl-2">
                                                                    <Select
                                                                        menuPortalTarget={document.body}
                                                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                                        placeholder="Sort order"
                                                                        getOptionValue={(option) => option.value}
                                                                        getOptionLabel={(option) => option.display}
                                                                        value={selectedSortOption}
                                                                        options={sortOptions}
                                                                        onChange={(newValue) => changeSort({ index, direction: newValue.value })}
                                                                    />
                                                                </div>

                                                                <div className="col pl-0 text-right align-self-center">
                                                                    <button className="btn btn-default btn-sm mr-2" onClick={() => removeSortField(index)}>
                                                                        <VscChromeClose />
                                                                    </button>

                                                                    <button
                                                                        onClick={() => moveSortField(index, "up")}
                                                                        className="btn btn-sm btn-primary mr-1"
                                                                        disabled={index === 0}
                                                                    >
                                                                        <i className="fa fa-arrow-up" />
                                                                    </button>

                                                                    <button
                                                                        onClick={() => moveSortField(index, "down")}
                                                                        className="btn btn-sm btn-primary mr-1"
                                                                        disabled={index === (sortFields.length - 1)}
                                                                    >
                                                                        <i className="fa fa-arrow-down" />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>

                                                <div className="ml-2 text-muted text-xs">
                                                    <a onClick={addSortField}>
                                                        <i className="fas fa-plus"></i>&nbsp;Add another sort
                                                    </a>
                                                </div>

                                                <div className="mt-2 text-right">
                                                    <button onClick={() => toggleDropdown("sort")} className="btn btn-default mr-2">Close</button>
                                                    <button onClick={() => applyFilterAndSort({ dataToUse: originalData })} className="btn btn-primary mr-2">Apply</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={`dropdown inline ${isDropdownShown.filter && "show"}`}>
                                    <div onClick={() => toggleDropdown("filter")} type="button" className="btn dropdown-toggle border-right pr-3">
                                        <BiFilter size="1rem" />&nbsp;
                                        <span>
                                            Filter
                                        </span>

                                        {filterFields.length > 0 && `(${filterFields.length})`}
                                        <i className="dropdown-caret"></i>

                                        <div onClick={event => event.stopPropagation()} className={`dropdown-menu dropdown-menu-lg ${isDropdownShown.filter && "show"}`} id="filter-dropdown" data-container="body">
                                            <span className="dropdown-header">Filter by</span>

                                            <div className="container pb-3">
                                                <form onSubmit={(event) => applyFilterAndSort({event, dataToUse: originalData})}>
                                                    <div className="p-2 pt-2 mt-1 b-r-sm" style={{ "borderTop": "1px solid #e7eaec" }}>
                                                        {filterFields.length == 0 && "Not filtered currently"}

                                                        {filterFields.map((field, index) => {
                                                            return (
                                                                <div key={field?.fieldName || index} className="row mb-2">
                                                                    <div className="col-5 pr-0">
                                                                        <Select
                                                                            isClearable
                                                                            menuPortalTarget={document.body}
                                                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                                            placeholder="Select field to filter by"
                                                                            getOptionValue={(option) => option.value}
                                                                            getOptionLabel={(option) => option.display}
                                                                            value={field?.fieldName ? field : undefined}
                                                                            options={columns.map((column) => {
                                                                                column.value = column.fieldName;
                                                                                column.display = column.fieldName;

                                                                                return column;
                                                                            })}
                                                                            onChange={(newValue) => changeFilter({ index, value: newValue })}
                                                                        />
                                                                    </div>

                                                                    <div className="col-5 p-0 pl-2">

                                                                        <input
                                                                            className="form-control"
                                                                            placeholder="Type to search"
                                                                            disabled={!(field?.fieldName)}
                                                                            onChange={(event) => changeFilter({ index, searchValue: event.target.value })}
                                                                            value={field?.searchValue}
                                                                        />
                                                                    </div>

                                                                    <div className="col pl-0 text-right align-self-center">
                                                                        <button type="button" className="btn btn-default btn-sm mr-2" onClick={() => removeFilterField(index)}>
                                                                            <VscChromeClose />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>

                                                    <div className="ml-2 text-muted text-xs">
                                                        <a onClick={addFilterField}>
                                                            <i className="fas fa-plus"></i>&nbsp;Add another field to filter by
                                                        </a>
                                                    </div>

                                                    <div className="mt-2 text-right">
                                                        <button type="button" onClick={() => toggleDropdown("filter")} className="btn btn-default mr-2">Close</button>
                                                        <button type="submit" onClick={(event) => applyFilterAndSort({event, dataToUse: originalData})} className="btn btn-primary mr-2">Apply</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <button
                                    type="button"
                                    className="btn"
                                    title="Reload"
                                    onClick={reloadData}
                                >
                                    <i className="fas fa-redo-alt"></i>&nbsp;Reload
                                </button>
                            </div>


                            <div className="inline">

                                <div className="float-right">

                                    <Link className="ml-2 border-right" to="./edit-resource/">
                                        <button type="button" className="btn"><i className="fas fa-edit">
                                        </i>&nbsp;Add/Remove Columns</button>
                                    </Link>

                                    <div className="dropdown inline">
                                        <button type="button" className="btn dropdown-toggle ml-2" data-toggle="dropdown">
                                            <i className="fas fa-file-export"></i>&nbsp;I<span className="d-none d-xl-inline-block">mport</span>/E<span className="d-none d-xl-inline-block">xport </span><i className="dropdown-caret"></i>
                                        </button>

                                        <ul className="dropdown-menu" id="import-export-data-dropdown" data-container="body">
                                            <li className="dropdown-header">Export</li>
                                            <li className="dropdown-item" onClick={() => exportData(appUUID, resourceName, "csv")}><a>Export to CSV</a></li>
                                            <li className="dropdown-item"><a onClick={() => exportData(appUUID, resourceName, "json")}>Export to JSON</a></li>
                                            {/* <li className="dropdown-header">Import</li> */}
                                            {/* <li className="dropdown-item" onClick={() => this.importData("csv")}><a>Import from CSV</a></li> */}
                                            {/* <li className="dropdown-item" onClick={() => this.importData("json")}><a>Import from JSON</a></li> */}
                                            {/* <li className="dropdown-item disabled"><a>Import using ETL Pipeline</a></li> */}
                                        </ul>

                                    </div>

                                    <Link to="./add" className="">
                                        <button type="button" className="btn">
                                            <i className="fas fa-plus"></i>&nbsp;
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className={`${showViews ? "col-2 pl-3 mr-0 pr-0" : "d-none"}`}>
                                <div className="full-window-height bg-white pl-2 pt-2" style={{ "borderRight": "2px solid #EBEBEB", "borderTop": "1px solid #EBEBEB" }}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12">

                                                <input type="text" className="form-control" placeholder="Find a view" autoFocus={true} />
                                            </div>
                                            <div className="col-12">

                                                <div className="mt-2 p-2">
                                                    <i className="fas fa-table mr-2"></i>
                                                    <span>List Item with Table Icon</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={`${showViews ? "col-10 pl-0 ml-0" : "col-12"}`}>
                                <div id="datatable" className="mt-2">
                                    <div id="portal" style={{ "position": "fixed", "left": "0", "top": 0, "zIndex": 9999 }} />
                                    <div style={{ "height": "75vh", border: "2px solid #EBEBEB" }}>

                                        <DataEditor
                                            onItemHovered={onItemHovered}

                                            theme={{
                                                textHeader: "#464749",
                                                textHeaderSelected: "#212121",
                                            }}
                                            getRowThemeOverride={getRowThemeOverride}
                                            rowHeight={50}

                                            getCellsForSelection={true} // for copy paste

                                            onCellClicked={onCellClicked}
                                            onGridSelectionChange={onGridSelectionChange}
                                            gridSelection={gridSelection}
                                            highlightRegions={highlights}

                                            getCellContent={getContent}
                                            customRenderers={[
                                                FileCell,
                                                SpinnerCell,
                                                UrlCell,
                                            ]}

                                            drawHeader={drawHeader}
                                            headerHeight={50}
                                            onHeaderMenuClick={onHeaderMenuClick}
                                            onHeaderClicked={onHeaderClicked}
                                            headerIcons={headerIcons}

                                            rows={data.length}
                                            columns={columns}

                                            onColumnResize={onColumnResize}
                                            onColumnMoved={onColumnMoved}

                                            smoothScrollX={true}

                                            // showSearch={true}
                                            // freezeColumns={1}

                                            width="calc(100vw - 4.5rem)"
                                            height="100vh"

                                            rowMarkers={"checkbox-visible"}

                                        // rightElementProps={{ sticky: true }}
                                        // rightElement={
                                        //     selectedRow !== undefined && <div
                                        //         className="panel"
                                        //         style={{
                                        //             height: "100%",
                                        //             border: "1px solid #EBEBEB",
                                        //             padding: "20px 20px 40px 20px",
                                        //             width: "20vw",
                                        //             color: "black",
                                        //             whiteSpace: "pre-wrap",
                                        //             // backgroundColor: "rgba(240, 240, 250, 0.2)",
                                        //             display: "flex",
                                        //             // boxShadow: "0 0 10px rgba(0, 0, 0, 0.15)",
                                        //             // backdropFilter: "blur(12px)",
                                        //             overflow: "scroll"
                                        //         }}
                                        //         >
                                        //             {JSON.stringify(data[selectedRow], null, 4)}
                                        //     </div>
                                        // }
                                        />
                                        {isOpen &&
                                            renderLayer(
                                                <div className="panel" {...layerProps}>
                                                    <ul className="list-group list-group-flush ">
                                                        <li className="list-group-item list-group-item-action">Hide column</li>
                                                        <li className="list-group-item list-group-item-action">Pin column</li>
                                                        <li className="list-group-item list-group-item-action">Rename column</li>
                                                        <li className="list-group-item list-group-item-action">Change column type</li>
                                                        <li className="list-group-item list-group-item-action">Delete column</li>
                                                    </ul>
                                                </div>
                                            )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                }
            </div >
        </>
    )
}

export default withLoaderData(connect()(DataTable));