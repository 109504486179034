export default function getDisplayDataForLookupField(props) {
    const { 
        currentColumn, 
        lookedupData, 
        lookedUpTuple, 
        siteUUIDToUse, 
        secondaryLookupFields,
        secondaryComputedLookupData,
     } = props;

    let displayData;

    if (currentColumn.resourceDisplayKey !== undefined && currentColumn.resourceDisplayKey !== null) {
        displayData = lookedUpTuple[currentColumn.resourceDisplayKey];

        if (displayData == null || displayData == undefined) {
            displayData = "";
        }

        const secondaryLookup = secondaryLookupFields?.[currentColumn.linksToSiteUUID]?.[currentColumn.linksToResource]?.[currentColumn.resourceDisplayKey];
        if(secondaryLookup) {
            let alternativeDisplayData = secondaryComputedLookupData[currentColumn?.linksToSiteUUID]?.[secondaryLookup.linksToResource]?.[displayData]?.[secondaryLookup.resourceDisplayKey];

            if(alternativeDisplayData != null && alternativeDisplayData != undefined) {
                displayData = alternativeDisplayData;
            }
        }

        if (typeof displayData !== "string") {
            displayData = displayData.toString();
        }
    } 
    
    else if (Array.isArray(currentColumn.resourceDisplayKeysMultiple) && currentColumn.resourceDisplayKeysMultiple.length > 0) {
        displayData = currentColumn.resourceDisplayKeysMultiple.reduce((acc, key) => {
            if (key[0] == "$") {
                const correctKey = key.substr(1);
                let lookedUpValue = lookedUpTuple[correctKey];

                const secondaryLookupInformation = secondaryLookupFields?.[siteUUIDToUse]?.[currentColumn.linksToResource]?.[correctKey];

                if (secondaryLookupInformation) {
                    const secondaryLookupResource = secondaryLookupInformation.linksToResource;
                    const secondaryLookupKey = secondaryLookupInformation.resourceDisplayKey;

                    lookedUpValue = lookedupData[siteUUIDToUse][correctKey]?.[lookedUpValue]?.[secondaryLookupKey];

                    if(lookedUpValue == undefined) {
                        lookedUpValue = secondaryComputedLookupData?.[siteUUIDToUse]?.[secondaryLookupResource]?.[lookedUpTuple[correctKey]]?.[secondaryLookupKey];
                    }
                }

                return `${acc}${lookedUpValue}`;
            }

            return `${acc}${key}`;
        }, "");
    }

    return displayData;
}