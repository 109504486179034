export default function CalculateComponentWidth(component) {
    let textLength = 0;
    let descriptionLength = 0;

    if(component.text) {
        textLength = component.text.length;
    }

    if(Array.isArray(component.description)) {
        descriptionLength = 0;
        component.description.forEach(description => {
            descriptionLength = Math.max(description.length, descriptionLength);
        });
    } else if(component.description) {
        descriptionLength = component.description.length;
    }

    return Math.max(textLength, descriptionLength) * 10;
}