
import React from "react";
import { connect } from "react-redux";

import { Link } from "react-router-dom";

import Loader from "../../Loader";
import * as DataDelegator from "../delegator/DataDelegator";

import ToTitleCase from "../../../core/utils/toTitleCase";
import SimpleRenderField from "./utils/SimpleRenderField";

class RecordViewer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            zoom: 1,
        };

        this.tableRef = React.createRef();

        this.changeFontSize = this.changeFontSize.bind(this);
    }

    componentDidMount() {
        DataDelegator.resolve(this.props);
    }

    componentDidUpdate(prevProps) {
        // Update only if the site and the resource name has changed
        if (this.props.site !== prevProps.site || this.props.resource !== prevProps.resource || this.props.identifier !== prevProps.identifier || this.props.value !== prevProps.value) {
            DataDelegator.resolve(this.props);
        }
    }

    changeFontSize(direction) {
        let sizeInEm = this.state.zoom;

        if (direction == "-") {
            sizeInEm -= .2;
        } else {
            sizeInEm += .2;
        }

        this.setState({
            zoom: sizeInEm
        }, () => {
            if (this.tableRef.current !== null) {
                this.tableRef.current.style.fontSize = `${sizeInEm}em`;
            }
        });
    }

    print() {
        window.print();
    }

    render() {
        return (
            <div className="panel">
                <div className="panel-body">
                    <div className="row mb-4 pb-4">
                        <div className="col-sm-4">
                            <a onClick={() => this.changeFontSize("-")} className="btn btn-default d-print-none"><i className="fas fa-search-minus"></i>&nbsp;Zoom Out</a>
                            <a onClick={() => this.changeFontSize("+")} className="btn btn-default d-print-none"><i className="fas fa-search-plus"></i>&nbsp;Zoom In</a>
                        </div>

                        <div className="col-sm-8">
                            {this.props.hidePrintButton !== true && <>
                                <a onClick={print} className="btn btn-default btn d-print-none float-right"><i className="fas fa-print"></i>&nbsp;Print</a>
                            </>}

                            {this.props.hideEditButton !== true && <>
                                <Link className="btn-link" to={`./../../../edit/${this.props.identifier}/${this.props.value}`}>
                                    <button className="btn btn-default d-print-none float-right text-default"><i className="fas fa-edit"></i>&nbsp;Edit</button>
                                </Link>
                            </>}
                        </div>
                    </div>

                    {this.props.hideTitle !== true && <>
                        <div className="text-center ">
                            <h1 className="mar-no">{this.props.resourceName} </h1>
                            <h3 className="pad-btm">{this.props.identifier} : {this.props.value} </h3>
                        </div>
                    </>}

                    <table ref={this.tableRef} id='data-record' className="table table-hover table-vcenter">
                        {this.props.data.length == 0 && <tbody>
                            <tr>
                                <td className="no-borders no-pointer-events">
                                    <Loader />
                                </td>
                            </tr>
                        </tbody>
                        }

                        {this.props.data.map((data, index) => { // In case there are multiple values returned
                            const body = this.props.columns.filter((column) => column.fieldName != "robostack_id").map((column) => {
                                const value = SimpleRenderField({
                                    data,
                                    column,
                                    applicationUsers: this.props.applicationUsers,
                                })

                                return (
                                    <tr key={column.fieldName}>
                                        <td><strong>{ToTitleCase(column.fieldName)}</strong></td>
                                        <td>{value}</td>
                                    </tr>
                                )
                            });

                            return (
                                <tbody key={index} className="m-b-xl">
                                    {body}
                                </tbody>
                            );
                        })}
                    </table>
                </div>
            </div>
        );
    }
}

export default connect(DataDelegator.mapStateToProps)(RecordViewer);