import ReportsManagement, { ReportsManagementLoader } from "../pages/app/reports";
import ReportBuilder from "../pages/app/reports/builder";
import ReportViewer from "../pages/app/reports/viewer";

import StandardLoader from "./standard-loader";

export default function ReportsRouter() {
    return {
        path: "/app/:appUUID/reports",

        children: [
            {
                index: true,
                element: <ReportsManagement />,
                loader: ReportsManagementLoader,
            },

            {
                path: "/app/:appUUID/reports/add",

                children: [
                    {
                        index: true,
                        element: <ReportBuilder />,
                        loader: (props) => StandardLoader({
                            ...props,
                            crumbs: [
                                {
                                    "name": "Reports",
                                },
                                {
                                    "name": "Add",
                                },
                            ]
                        }),
                    }
                ]
            },

            {
                path: "/app/:appUUID/reports/view/:reportUUID",

                children: [
                    {
                        index: true,
                        element: <ReportViewer />,
                        loader: (props) => StandardLoader({
                            ...props,
                            crumbs: [
                                {
                                    "name": "Reports",
                                    "url": "../../",
                                },
                                {
                                    "name": "View",
                                    url: "./",
                                },
                            ]
                        }),
                    }
                ]
            },
        ]
    };
}