// Draw a rectangle with ports and highlighted if clicked
export function drawRectangle({
    ctx,
    x,
    y,
    width,
    height,
    text,
    description,
    ports,
    isClicked,
    hoveredPortId,
    hasError,
}) {
    ctx.beginPath();
    ctx.rect(x, y, width, height);

    ctx.lineWidth = 2;

    // Draw a border around the component if it is clicked
    if (isClicked) {
        ctx.strokeStyle = 'blue';
    } else {
        ctx.strokeStyle = 'black';
    }

    ctx.stroke();

    // Make the text red if there is an error with it (e.g. missing data)
    if(hasError) {
        ctx.fillStyle = 'red';
    } else {
        ctx.fillStyle = 'black';
    }

    ctx.font = '14px Arial';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillText(text, x + width / 2, y + height / (description ? 3 : 2));

    if (description) {
        ctx.font = '12px Arial';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';

        if (Array.isArray(description)) {
            description.forEach((desc, index) => {
                ctx.fillText(desc, x + width / 2, y + height / 3 + 22 + 15 * index);
            })
        } else {
            ctx.fillText(description, x + width / 2, y + height / 3 + 22);
        }
    }

    ports.forEach(port => {
        ctx.beginPath();

        if (hoveredPortId == port.id) {
            ctx.fillStyle = 'blue';
            ctx.arc(x + port.x * width, y + port.y * height, 7, 0, 2 * Math.PI);
        } else {
            ctx.fillStyle = 'black';
            ctx.arc(x + port.x * width, y + port.y * height, 5, 0, 2 * Math.PI);
        }

        ctx.fill();
    });
}