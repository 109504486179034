const steps = [
    {
        target: `#workflow`,
        title: "Workflow Designer",
        placement: "right",
        content: (
            <div>
                Design what you want your scheduled task to do here.<br />
                <br />
                You can chose between running queries from the Query Playground, generating saved Reports and sending emails.
                <br />
            </div>
        ),
        disableBeacon: true,
    },
    {
        target: `#add-workflow-item`,
        title: "Add Workflow Item",
        content: (
            <div>
                Click on this button to add a new item to the workflow.
                <br />
                <br />
                You can also quickly add a new item by double clicking on any free space in the Workflow Designer.
            </div>
        ),
        disableBeacon: true,
    },
    {
        target: `#add-workflow-item-popup`,
        title: "Select the type of item to add to the workflow",
        content: (
            <div>
                You can chose between running queries from the Query Playground, generating saved reports and sending emails.
            </div>
        ),
        disableBeacon: true,
    },
    {
        target: `#workflow-item-properties`,
        title: "Configure Workflow Items",
        content: (
            <div>
                Once you add a workflow item, you can change its configuration here.
            </div>
        ),
        disableBeacon: true,
    },
    {
        target: `#delete-item`,
        title: "Remove the Workflow Item",
        content: (
            <div>
                If you want to remove an item, click on this button to delete it.<br />
                <br />
                Any connections to this item will also be removed.
            </div>
        ),
        disableBeacon: true,
    },
    {
        target: `#workflow`,
        title: "Connect Workflow Items",
        placement: "right",
        content: (
            <div>
                Once you configure your workflow item, you can pass its output to the next item in the workflow. <br />
                <br />
                To do this, click on the output connector at the bottom of the item and drag it to the input connector at the top of the next item.
            </div>
        ),
        disableBeacon: true,
    },
    {
        target: `#workflow-data-available-to-item`,
        title: "View Data Available",
        content: (
            <div>
                Once you have connected the workflow items to the start item, you can view the data that is available to the selected workflow item here.<br />
                <br />
                To do this, click on the Run button and the workflow will run upto the previous workflow item.<br />
            </div>
        ),
        disableBeacon: true,
    },
    {
        target: `#test-run-pipeline`,
        title: "Test Run Pipeline",
        placement: "left-start",
        content: (
            <div>
                Once all the items are connected to the Start and the End, you can test run the workflow to see if it works as expected.<br />
                <br />
            </div>
        ),
        disableBeacon: true,
    },
    {
        target: `#save-scheduled-task`,
        title: "Save Scheduled Task",
        placement: "left-start",
        content: (
            <div>
                Once the workflow is working as expected, you can save the scheduled task.
            </div>
        ),
        disableBeacon: true,
    },
    {
        target: `#workflow`,
        title: "Set the Schedule",
        placement: "top-start",
        content: (
            <div>
                The schedule can be set to run at a specific time or at regular intervals. To set the schedule, click on the Start item.<br />
                <br />
                You can also set the role the workflow will have the permissions of in the Start item.
            </div>
        ),
        disableBeacon: true,
    },
    {
        target: "#toggle-help",
        content: (
            <div>
                Click on this button to view this tutorial again at any time.<br />
            </div>
        )
    }


];

export default steps;