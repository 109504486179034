import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

import simpleAuth from "../core/auth/simpleAuth";
import * as DataDelgator from "./smart/delegator/DataDelegator";
import axiosBackend from "../core/api/backend";

class Navbar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            billing: false,
            toggle: false
        };
    }

    componentDidMount() {
        DataDelgator.resolve(this.props, (err) => {
            if (err) {
                throw err;
            } else {
                if (simpleAuth.isAuthenticated) {
                    this.setState({
                        loading: false,
                    }, async () => {
                        await axiosBackend({
                            method: "POST",
                            url: "/company/billing/invoices",
                        });

                        this.setState({
                            billing: true,
                        });
                    });
                }
            }
        });
    }

    changeToggle = () => {
        this.setState(({ toggle }) => {
            return {
                toggle: !toggle
            }
        })
    }

    render() {
        return (
            <>
                <header>
                    <nav className="navbar navbar-static-top light-bg">
                        {simpleAuth.isAuthenticated ? (

                            <>
                                <div className="navbar-header">
                                    <button className="d-block d-sm-none hamburger btn btn-primary text-white" onClick={this.changeToggle}>
                                        <i className="fa fa-bars"></i>
                                    </button>

                                    <Link id="brand-logo-dark" className="hamburger ml-2" to="/">
                                        <h3 id="brand-logo">ROBOSTACK</h3>
                                    </Link>
                                </div>

                                <div className="nav-user">
                                    <ul className="nav nav-user-menu pt-2 d-flex navbar-right">
                                        {simpleAuth.isAdmin() === true &&
                                            <li className="d-none d-sm-block nav-item align-self-center">
                                                <Link to="/stack-management" className="nav-link d-print-none" aria-disabled="true">
                                                    Stack Management
                                                </Link>
                                            </li>
                                        }

                                        {this.state.billing === true &&
                                            <li className="d-none d-sm-block nav-item align-self-center">
                                                <Link to="/company" className="nav-link d-print-none" aria-disabled="true">
                                                    Company Dashboard
                                                    </Link>
                                            </li>
                                        }

                                        <li className="d-none d-sm-block nav-item align-self-center">
                                            <Link to="/support" className="nav-link d-print-none" aria-disabled="true">Support &amp; Training</Link>
                                        </li>

                                        <li id="dropdown-user" className="dropdown">
                                            <Link className="dropdown-toggle align-self-center d-print-none" data-toggle="dropdown">
                                                <i className="fa fa-user"></i> <span className="m-r-1 text-clear welcome-message"> {simpleAuth.username()}</span>
                                            </Link>
                                            <ul className="dropdown-menu dropdown-alerts">
                                                {this.state.billing === true ?
                                                    <li>
                                                        <Link to="/company" className="dropdown-item d-print-none"><span className="nav-label">Company Dashboard</span></Link>
                                                    </li>
                                                    :
                                                    undefined
                                                }

                                                <li>
                                                    <Link to="/my-account/" className="dropdown-item">
                                                        <i className="fa fa-user icon-fw"></i> My Account
                                                    </Link>
                                                </li>

                                                <li className="dropdown-divider"></li>

                                                <li>
                                                    <a onClick={() => simpleAuth.logout()} className="dropdown-item">
                                                        <i className="fa fa-sign-out-alt icon-fw"></i> Logout</a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>

                            </>
                        ) : (
                            <Link id="brand-logo-dark" className="hamburger" to="/">
                                <h3 id="brand-logo">ROBOSTACK</h3>
                            </Link>
                        )}
                    </nav>
                </header>
                <div className={"overlay " + `${this.state.toggle}`}>
                    {this.state.toggle && <div className="nav-sidebar hidden">
                        <ul className="nav metismenu active" id="side-menu">
                            <button className="hamburger btn btn-primary text-white" onClick={this.changeToggle}>
                                <i className="fa fa-bars"></i>
                            </button>
                            <li className="">
                                <Link onClick={this.changeToggle} to="/"><i className="fa fa-home"></i> <span className="nav-label">Home</span></Link>
                            </li>

                            {simpleAuth.isAdmin() &&
                                <li className="">
                                    <Link onClick={this.changeToggle} to="/stack-management">
                                        <i className="fa fa-lock"></i>
                                        <span className="nav-label">Stack Management</span>
                                    </Link>
                                </li>
                            }

                            {/* <li className="">
                                <Link onClick={this.changeToggle} to="/"><i className="fa fa-sitemap"></i> <span className="nav-label">Applications </span><span
                                    className="fa arrow"></span>
                                </Link>

                                <ul className="nav nav-second-level collapse">
                                    {Array.isArray(this.props.apps) && this.props.apps.map((app, index) => {
                                        return (
                                            <li className="" key={`app_${index}`}>
                                                <Link to={`/app/${app.uuid}/`} onClick={() => this.fetchServicesForSite(app.uuid)}>
                                                    <div className="row">
                                                        <div className="col-1">
                                                            <i className="fa fa-industry"></i>
                                                        </div>
                                                        <div className="col-8">
                                                            {app.name}
                                                        </div>
                                                        <div className="col-1">
                                                            <span className="fa arrow"></span>
                                                        </div>
                                                    </div>
                                                </Link>
                                                <ul className="nav nav-third-level">
                                                    <li>
                                                        <Link to={`/app/${app.uuid}/`}>
                                                            <div className="row">
                                                                <div className="col-1">
                                                                    <i className="fa fa-th-large"></i>
                                                                </div>
                                                                <div className="col-9">
                                                                    Dashboard
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </li>

                                                    {!Array.isArray(this.state[app.uuid]) ? undefined : this.state[app.uuid].map((service, index) => {
                                                        return (
                                                            <li key={`sidebar_service_${app.uuid}_${index}`}>
                                                                <Link to={`/app/${app.uuid}/${service.name.toLowerCase()}/`}>
                                                                    <div className="row">
                                                                        <div className="col-1">
                                                                            <i className={`fa ${service.icon}`}></i>
                                                                        </div>
                                                                        <div className="col-9">
                                                                            {service.description}
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </li> */}

                            <li className="">
                                <Link onClick={this.changeToggle} to="/my-account"><i className="fa fa-user"></i> <span className="nav-label">My Account</span></Link>
                            </li>

                            {this.state.billing === true ?
                                <li className="">
                                    <Link onClick={this.changeToggle} to="/company"><i className="fa fa-receipt"></i> <span className="nav-label">Company Dashboard</span></Link>
                                </li>
                                :
                                undefined
                            }

                            <li className="">
                                <Link onClick={this.changeToggle} to="/support"><i className="fa fa-question-circle"></i> <span className="nav-label">Support &amp; Training</span></Link>
                            </li>
                        </ul>
                    </div>}
                </div>
            </>
        );
    }
}

export default Navbar;