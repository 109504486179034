import BreadcrumbsTemplate from '../pages/templates/breadcrumbs';

import AddToResource from "../pages/app/resources/add";
import DataTable from "../pages/app/resources/data-table";

import StackManagement from "../pages/stack-management/stack-management";

import Page from '../components/smart/Page';

import SiteSeeder from '../pages/stack-management/playground/site-seeder';
import AdminSiteCloner from '../pages/stack-management/management/sites/site-cloner';
import AdminCompanyDashboard from '../pages/stack-management/management/companies';

import CacheStackManagement from '../pages/stack-management/cache';
import BillingStackManagement from "../pages/stack-management/billing";
import MysqlStackManagement from "../pages/stack-management/mysql";
import DatalogStackManagement from "../pages/stack-management/datalog";

import AdminAPIKeysManagement from "../pages/stack-management/management/api-keys";

import SlateDesigner from "../pages/stack-management/mass-email-editor";

import StandardLoader from "./standard-loader";

export default function StackManagementRouter() {
    const date = new Date();
    const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 2);


    return {
        path: "stack-management",

        children: [
            {
                element: <BreadcrumbsTemplate
                    title="Stack Management"
                />,

                
                children: [
                    {
                        index: true,
                        element: <StackManagement
                            robostackResolveData={[
                                {
                                    "name": "StackManagement_SSLCertificatesStatus",
                                    "api": {
                                        "method": "POST",
                                        "endpoint": "/administration/ssl-checker",
                                        "data": {}
                                    },
                                    "transform": {
                                        "certificates": "`data.results | deep-clone | map | momentify: validity`"
                                    }
                                },
                                {
                                    "name": "StackManagement_ServiceCheckerStatus",
                                    "api": {
                                        "method": "POST",
                                        "endpoint": "/administration/service-checker",
                                        "data": {}
                                    },
                                    "transform": {
                                        "serviceChecker": "`data.results`"
                                    }
                                },
                            ]}
                        />,
                        loader: (props) => StandardLoader({
                            ...props
                        }),
                    },
        
                    {
                        path: "/stack-management/billing",
                        element: <BillingStackManagement
                            robostackResolveData={[
                                {
                                    "name": "StackManagement_CountOfInvoices",
                                    "api": {
                                        "method": "POST",
                                        "endpoint": "/administration/resources/invoices/aggregation",
                                        "data": [
                                            {
                                                "$group": {
                                                    "_id": "uuid",
                                                    "count": {
                                                        "$count": 1
                                                    }
                                                }
                                            }
                                        ]
                                    },
                                    "transform": {
                                        "numberOfInvoices": "`data.results[0].count`"
                                    }
                                },
                                {
                                    "name": "StackManagement_CountOfUnpaidInvoices",
                                    "api": {
                                        "method": "POST",
                                        "endpoint": "/administration/resources/invoices/aggregation",
                                        "data": [
                                            {
                                                "$match": {
                                                    $or: [
                                                        { "isPaid": false },
                                                        { "isPaid": null },
                                                    ],
                                                }
                                            },
                                            {
                                                "$group": {
                                                    "_id": "uuid",
                                                    "count": {
                                                        "$count": 1
                                                    }
                                                }
                                            }
                                        ]
                                    },
                                    "transform": {
                                        "numberOfUnpaidInvoices": "`data.results[0].count`"
                                    }
                                },
                                {
                                    "name": "StackManagement_SumofInvoicesLastMonth",
                                    "api": {
                                        "method": "POST",
                                        "endpoint": "/administration/resources/invoices/aggregation",
                                        "data": [
                                            {
                                                "$match": {
                                                    "$and": [
                                                        {
                                                            "invoiceSentDate": {
                                                                "$gte": {
                                                                    "value": startOfMonth,
                                                                    "type": "DATE",
                                                                    "operation": "subtract",
                                                                    "units": [
                                                                        {
                                                                            "number": 1,
                                                                            "type": "month"
                                                                        }
                                                                    ]
                                                                }
                                                            },
                                                        },
                                                        {
                                                            "invoiceSentDate": {
                                                                "$lte": {
                                                                    "value": startOfMonth,
                                                                    "type": "DATE",
                                                                    "operation": "add",
                                                                    "units": [
                                                                        {
                                                                            "number": "3",
                                                                            "type": "days"
                                                                        }
                                                                    ]
                                                                }
                                                            }
                                                        }
                                                    ]
                                                }
                                            },
                                            {
                                                "$group": {
                                                    "sum": {
                                                        "$sum": "$total"
                                                    }
                                                }
                                            }
                                        ]
                                    },
                                    "transform": {
                                        "monthlyInvoicesTotal": "`data.results[0].sum`"
                                    }
                                },
                                {
                                    "name": "StackManagement_SumofUnpaidInvoices",
                                    "api": {
                                        "method": "POST",
                                        "endpoint": "/administration/resources/invoices/aggregation",
                                        "data": [
                                            {
                                                "$match": {
                                                    $or: [
                                                        { "isPaid": false },
                                                        { "isPaid": null },
                                                    ],
                                                }
                                            },
                                            {
                                                "$group": {
                                                    "sum": {
                                                        "$sum": "$total"
                                                    }
                                                }
                                            }
                                        ]
                                    },
                                    "transform": {
                                        "pendingInvoicesTotal": "`data.results[0].sum`"
                                    }
                                },
                                {
                                    "name": "StackManagement_CountOfBillingCustomers",
                                    "api": {
                                        "method": "POST",
                                        "endpoint": "/administration/resources/billing-customers/aggregation",
                                        "data": [
                                            {
                                                "$group": {
                                                    "_id": "uuid",
                                                    "count": {
                                                        "$count": 1
                                                    }
                                                }
                                            }
                                        ]
                                    },
                                    "transform": {
                                        "numberOfCustomers": "`data.results[0].count`"
                                    }
                                },
                                {
                                    "name": "StackManagement_CountOfBillingCustomersAccess",
                                    "api": {
                                        "method": "POST",
                                        "endpoint": "/administration/resources/billing-access/aggregation",
                                        "data": [
                                            {
                                                "$group": {
                                                    "_id": "uuid",
                                                    "count": {
                                                        "$count": 1
                                                    }
                                                }
                                            }
                                        ]
                                    },
                                    "transform": {
                                        "numberOfCustomersAccess": "`data.results[0].count`"
                                    }
                                },
                                {
                                    "name": "StackManagement_CountOfBillingBillingSubscriptions",
                                    "api": {
                                        "method": "POST",
                                        "endpoint": "/administration/resources/billing-subscriptions/aggregation",
                                        "data": [
                                            {
                                                "$group": {
                                                    "_id": "uuid",
                                                    "count": {
                                                        "$count": 1
                                                    }
                                                }
                                            }
                                        ]
                                    },
                                    "transform": {
                                        "numberOfBillingSubscriptions": "`data.results[0].count`"
                                    }
                                },
                                {
                                    "name": "StackManagement_CountOfBillingTiers",
                                    "api": {
                                        "method": "POST",
                                        "endpoint": "/administration/resources/billing-tiers/aggregation",
                                        "data": [
                                            {
                                                "$group": {
                                                    "_id": "uuid",
                                                    "count": {
                                                        "$count": 1
                                                    }
                                                }
                                            }
                                        ]
                                    },
                                    "transform": {
                                        "numberOfBillingTiers": "`data.results[0].count`"
                                    }
                                },
                                {
                                    "name": "StackManagement_InvoicesLast6Months",
                                    "api": {
                                        "method": "POST",
                                        "endpoint": "/administration/resources/invoices/aggregation",
                                        "data": [
                                            {
                                                "$match": {
                                                    "$and": [
                                                        {
                                                            "invoiceSentDate": {
                                                                "$gte": {
                                                                    "value": startOfMonth,
                                                                    "type": "DATE",
                                                                    "operation": "subtract",
                                                                    "units": [
                                                                        {
                                                                            "number": 6,
                                                                            "type": "month"
                                                                        },
                                                                    ]
                                                                }
                                                            },
                                                        },
                                                    ]
                                                }
                                            },
                                            {
                                                "$group": {
                                                    "_id": "$invoiceSentDate",
                                                    "sum": {
                                                        "$sum": "$total"
                                                    }
                                                }
                                            }
                                        ]
                                    },
                                    "transform": {
                                        "invoicesList6Months": "`data.results`"
                                    }
                                },
                            ]}
                        />,
                        loader: (props) => StandardLoader({
                            ...props,
                            crumbs: [
                                {
                                    "name": "Billing",
                                },
                            ]
                        }),
                    },
        
                    {
                        path: "/stack-management/mysql",
                        element: <MysqlStackManagement />,
                        loader: (props) => StandardLoader({
                            ...props,
                            crumbs: [
                                {
                                    "name": "MySQL Performance",
                                },
                            ]
                        }),
                    },

                    {
                        path: "/stack-management/companies",
                        element: <AdminCompanyDashboard />,
                        loader: (props) => StandardLoader({
                            ...props,
                            crumbs: [
                                {
                                    "name": "Companies Dashboard",
                                },
                            ]
                        }),
                    },

                    {
                        path: "/stack-management/datalog",
                        element: <DatalogStackManagement />,
                        loader: (props) => StandardLoader({
                            ...props,
                            crumbs: [
                                {
                                    "name": "Datalog",
                                },
                            ]
                        }),
                    },

                    {
                        path: "/stack-management/management/api-keys",
                        element: <AdminAPIKeysManagement />,
                        loader: (props) => StandardLoader({
                            ...props,
                            crumbs: [
                                {
                                    "name": "API Keys Management",
                                },
                            ]
                        }),
                    },

                    {
                        path: "/stack-management/playground/site-seeder",
                        element: <SiteSeeder />,
                        loader: (props) => StandardLoader({
                            ...props,
                            crumbs: [
                                {
                                    "name": "Playground",
                                },
                                {
                                    "name": "Site Seeder",
                                },
                            ]
                        }),
                    },

                    {
                        path: "/stack-management/playground/site-cloner",
                        element: <AdminSiteCloner />,
                        loader: (props) => StandardLoader({
                            ...props,
                            crumbs: [
                                {
                                    "name": "Site Cloner",
                                },
                            ]
                        }),
                    },

                    {
                        path: "/stack-management/bulk/email",
                        element: <SlateDesigner />,
                        loader: (props) => StandardLoader({
                            ...props,
                            crumbs: [
                                {
                                    "name": "Mass Email Sender",
                                },
                            ]
                        }),
                    },

                    {
                        path: "/stack-management/cache",
                        element: <CacheStackManagement />,
                        loader: (props) => StandardLoader({
                            ...props,
                            crumbs: [
                                {
                                    "name": "Cache Management",
                                },
                            ]
                        }),
                    },

                    {
                        path: "/stack-management/resources/:resourceName",

                        children: [
                            {
                                index: true,
                                element: <DataTable
                                    appUUID="resources"
                                    apiStartUrl="administration"
                                />,
                                loader: (props) => StandardLoader({
                                    ...props,
                                    crumbs: [
                                        {
                                            "name": "Resources",
                                            url: "../../"
                                        },
                                        {
                                            "name": props.params.resourceName,
                                        },
                                    ]
                                }),
                            },

                            {
                                path: "/stack-management/resources/:resourceName/add",

                                children: [
                                    {
                                        index: true,
                                        element: <AddToResource
                                            appUUID="resources"
                                            apiStartUrl="administration"
                                        />,
                                        loader: (props) => StandardLoader({
                                            ...props,
                                            crumbs: [
                                                {
                                                    "name": "Resources",
                                                    "url": "../../../"
                                                },
                                                {
                                                    "name": props.params.resourceName,
                                                },
                                                {
                                                    "name": "Add"
                                                }
                                            ]
                                        }),
                                    }
                                ]
                            },

                            {
                                path: "/stack-management/resources/:resourceName/view/:identifier/:value",
                
                                children: [
                                    {
                                        index: true,
                                        loader: (props) => StandardLoader({
                                            ...props,
                                            rootUrl: `/stack-management/`,
                                            crumbs: [
                                                {
                                                    "name": "Resources",
                                                    url: "../../../../"
                                                },
                                                {
                                                    "name": props.params.resourceName,
                                                    url: "../../../"
                                                },
                                                {
                                                    "name": "View",
                                                },
                                            ]
                                        }),
                                        element: <Page
                                            robostackResolveData={[
                                                {
                                                    "component": "RecordViewer",
                                                    "size": 12,
                                                    "props": {
                                                        "data": [],
                                                        "columns": [],
                                                        "robostackResolveData": [
                                                            {
                                                                "name": "DataForRecord_admin_<%= resourceName %>_view_<%= identifier %>_<%= value %>",
                                                                "api": {
                                                                    "method": "POST",
                                                                    "endpoint": "/administration/resources/<%= resourceName %>/view/<%= identifier %>/<%= value %>",
                                                                    "data": {}
                                                                },
                                                                "transform": {
                                                                    "data": "`data.results`"
                                                                }
                                                            },
                                                            {
                                                                "name": "ColumnsFor_admin_<%= resourceName %>",
                                                                "api": {
                                                                    "method": "POST",
                                                                    "endpoint": "/administration/resources/<%= resourceName %>/fields",
                                                                    "data": "{}"
                                                                },
                                                                "transformMerge": true,
                                                                "transform": {
                                                                    "columns": "`data.results | sort: displayPosition`"
                                                                }
                                                            }
                                                        ]
                                                    }
                                                }
                                            ]
                                            }
                                        />,
                                    }
                                ]
                            },

                            {
                                path: "/stack-management/resources/:resourceName/edit/:identifier/:value",

                                children: [
                                    {
                                        index: true,
                                        loader: (props) => StandardLoader({
                                            ...props,
                                            crumbs: [
                                                {
                                                    "name": "Resources",
                                                },
                                                {
                                                    name: props.params.resourceName
                                                },
                                                {
                                                    name: "Edit",
                                                },
                                            ]
                                        }),
                                        element: <Page
                                            robostackResolveData={[
                                                {
                                                    "component": "MagicForm",
                                                    "size": 12,
                                                    "props": {
                                                        "appUUID": "resources",
                                                        "fields": {},
                                                        "formClasses": [
                                                            "form-horizontal"
                                                        ],
                                                        "submitApi": {
                                                            "submitData": {
                                                                "selection": "$original",
                                                                "updation": "$fields"
                                                            }
                                                        },
                                                        "robostackResolveData": [
                                                            {
                                                                "name": "LookupDataForAdmin_<%= resourceName %>",
                                                                "api": {
                                                                    "method": "POST",
                                                                    "endpoint": "/administration/resources/<%= resourceName %>/lookup",
                                                                    "data": {}
                                                                },
                                                                "transformMerge": true,
                                                                "transform": {
                                                                    "lookup": "`data.results[0]`"
                                                                }
                                                            },
                                                            {
                                                                "name": "FormAdmin_<%= resourceName %>",
                                                                "api": {
                                                                    "method": "POST",
                                                                    "endpoint": "/administration/resources/<%= resourceName %>/form",
                                                                    "data": {}
                                                                },
                                                                "transformMerge": true,
                                                                "transform": {
                                                                    "fields": "`data.results`",
                                                                    "submitApi": "`set-prop:method,'PATCH' | set-prop:endpoint,'/administration/resources/<%= resourceName %>' | set-prop:data,'$submitApi.submitData' | remove-all-keys-except:'data,method,endpoint'`"
                                                                }
                                                            },
                                                            {
                                                                "name": "DataAdmin_<%= resourceName %>_<%= identifier %>_<%= value %>",
                                                                "api": {
                                                                    "method": "POST",
                                                                    "endpoint": "/administration/resources/<%= resourceName %>/view/<%= identifier %>/<%= value %>/form",
                                                                    "data": {}
                                                                },
                                                                "transformMerge": true,
                                                                "transformMergeIfArray": true,
                                                                "transformName": "View",
                                                                "transform": {
                                                                    "submitApi": "`set-prop:original,$data.results.0 | remove-all-keys-except:'data,method,endpoint,original'`",
                                                                    "fields": "`data.results | pop | key-value-to-object: value | unset-prop:robostack_id`",
                                                                }
                                                            },
                                                        ]
                                                    }
                                                }
                                            ]
                                            }
                                        />,
                                    }
                                ]
                            },
                        ]
                    }
                ]
            },
        ]
    };
}