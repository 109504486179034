export default function CalculateComponentHeight(component) {
    let height = 20;

    if(Array.isArray(component.text)) {
        height += component.text.length * 20;
    } else if (component.text.length > 0) {
        height += 20;
    }

    if(Array.isArray(component.description)) {
        height += component.description.length * 20;
    } else if(component.description.length > 0) {
        height += 20;
    } else {
        height += 10;
    }

    return height;
}