import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useLoaderData } from "react-router-dom";

import * as DataDelegator from "components/smart/delegator/DataDelegator";
import withLoaderData from "components/withLoaderData";

import ReactJoyride, { ACTIONS, EVENTS, STATUS, LIFECYCLE } from 'react-joyride';
import steps from "./help-steps-add-scheduled-task";
import MarkIntroductionTourDone from "../../../core/onboarding/mark-introduction-tour-done";

import Select from "react-select";
import SelectWithDescriptionQueryOption, { filterQueryOptionsWithDescription } from "../../../components/select-with-description/SelectWithDescriptionQueryOption";

import cronstrue from "cronstrue";

import GenerateCronString from "./cron/generate-cron-string";
import cronTimeOptions from "./cron/data/cron-time-options";

import getMousePos from "./workflow/utils/get-mouse-pos";
import isMouseInComponent from "./workflow/utils/is-mouse-in-component";
import isMouseInPort from "./workflow/utils/is-mouse-in-port";
import isMouseOverArrowDeleteIcon from "./workflow/utils/is-mouse-over-arrow-delete-icon";

import drawFlowchart from "./workflow/drawFlowchart";
import MakeFlowchartNeat from "./workflow/utils/make-flowchart-neat";

import ComponentTypesToAdd from "./component-types-to-add";
import SupportedWorkflowActions from "./supported-workflow-actions";

import CalculateComponentWidth from "./utils/calculate-component-width";
import CalculateComponentHeight from "./utils/calculate-component-height";

import LoadQueriesListFromPlayground from "./workflow/service-helpers/query-playground/load-queries-list-from-playground";
import LoadRolesList from "./workflow/service-helpers/users-and-roles/load-roles-list";
import LoadReportsList from "./workflow/service-helpers/reports/load-reports-list";

import TagsInput from "react-tagsinput";
import ReactJson from "react-json-view";
import { Editor } from "@monaco-editor/react";

import axiosBackend from "core/api/backend";
import { v4 as uuidv4 } from "uuid";

export const introductionIdentifier = "scheduled-tasks/add-scheduled-task";

function AddScheduledTask(props) {
    // Code for the introduction tour for this page
    const {
        shouldRun,
    } = useLoaderData();

    useEffect(() => {
        if (shouldRun) {
            setShowTour(true);
        }
    }, [shouldRun]);

    const [showTour, setShowTour] = useState(false);
    const [stepIndex, setStepIndex] = useState(0);

    const [originalComponents, setOriginalComponents] = useState([]);
    const [originalArrows, setOriginalArrows] = useState([]);
    const [originalClickedComponentIndex, setOriginalClickedComponentIndex] = useState(null);

    const toggleHelp = () => {
        setShowTour(true);
    }

    const handleJoyrideCallback = (data) => {
        if (ACTIONS.START == data.action && LIFECYCLE.INIT == data.lifecycle) {
            setOriginalComponents(components);
            setOriginalArrows(arrows);
            setOriginalClickedComponentIndex(clickedComponentIndex);
        }

        if (ACTIONS.NEXT == data.action && LIFECYCLE.COMPLETE == data.lifecycle) {
            if (data.step.target == "#add-workflow-item") {
                const addWorkflowItemButtonRect = document.getElementById("add-workflow-item").getBoundingClientRect();

                setPopupStyle({
                    ...popupStyle,
                    display: 'block',
                    left: addWorkflowItemButtonRect.x + 200 + 'px',
                    top: addWorkflowItemButtonRect.y + 200 + 'px',
                })
            }

            else if (data.step.target == "#add-workflow-item-popup") {
                // Add a new component to demonstrate how it works
                const newComponentType = ComponentTypesToAdd.find((componentType) => componentType.label == "Querying").options[0];
                addNewComponent(newComponentType)

            }
        }

        if ([STATUS.FINISHED, STATUS.SKIPPED, STATUS.CLOSE].includes(data.status) || ACTIONS.CLOSE == data.action) {
            // Need to set our running state to false, so we can restart if we click start again.
            setShowTour(false);
            setStepIndex(0);

            setComponents(originalComponents);
            setArrows(originalArrows);
            setClickedComponentIndex(originalClickedComponentIndex);
            setPopupStyle({
                ...popupStyle,
                display: 'none',
            })

            if (shouldRun) {
                MarkIntroductionTourDone({ identifier: introductionIdentifier });
            }
        } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(data.type)) {
            // Update state to advance the tour
            setStepIndex(data.index + (data.action === ACTIONS.PREV ? -1 : 1));
        }
    }

    const emailOptions = [
        { value: "digest", label: "One Grouped Email" },
        { value: "each", label: "One Email For Each Item" },
    ];

    // Cron time calculator
    const [frequency, setFrequency] = useState("minute");
    const [months, setMonths] = useState([]);
    const [days, setDays] = useState([]);
    const [weekDays, setWeekDays] = useState([]);
    const [hours, setHours] = useState([]);
    const [minutes, setMinutes] = useState([cronTimeOptions.minutes[0]]);

    // Roles to run the scheduled task under
    const [roles, setRoles] = useState([]);
    const [selectedRole, setSelectedRole] = useState(null);

    // For saving the cron schedule
    const [wasLastTestRunSuccessful, setWasLastTestRunSuccessful] = useState(false);
    const [wasEntirePipelineTestRun, setWasEntirePipelineTestRun] = useState(false);

    // Components for the workflow designer
    const [components, setComponents] = useState([]);
    const [clickedComponentIndex, setClickedComponentIndex] = useState(null);

    const [arrows, setArrows] = useState([]);
    const [newArrow, setNewArrow] = useState(null);

    const [hoveredPort, setHoveredPort] = useState(null);

    const [newComponentType, setNewComponentType] = useState(null);

    const [draggingComponentIndex, setDraggingComponentIndex] = useState(null);
    const [offsetXForDraggingComponent, setOffsetXForDraggingComponent] = useState(0);
    const [offsetYForDraggingComponent, setOffsetYForDraggingComponent] = useState(0);

    const [tzOffset, setTzOffset] = useState(0);

    const [popupStyle, setPopupStyle] = useState({
        "display": "none",
        "position": "absolute",
        "border": "1px solid #ddd",
        "background": "white",
        "padding": "10px",
        "width": "20%",
    });

    const canvasRef = useRef(null);

    // Data for the workflow items
    const [queryPlaygroundData, setQueryPlaygroundData] = useState([]);

    const [reportsData, setReportsData] = useState([]);

    const editorRef = useRef(null);

    // Pipeline results for the workflow
    const [pipelineResults, setPipelineResults] = useState({});

    // Add the start component 
    const startComponent = {
        id: 'start',
        type: 'rectangle',
        x: 50,
        y: 30,
        text: 'Start',
        description: "",
        ports: [
            {
                x: 0.5,
                y: 1,
                id: uuidv4(),
                type: "output",
            },
        ]
    };

    startComponent.width = CalculateComponentWidth(startComponent) * 2;
    startComponent.height = CalculateComponentHeight(startComponent);

    // Add the end component 
    const endComponent = {
        id: 'end',
        type: 'rectangle',
        x: 50,
        y: 250,
        text: 'End',
        description: "",
        ports: [
            {
                x: 0.5,
                y: 0,
                id: uuidv4(),
                type: "input",
            },
        ]
    };

    endComponent.width = CalculateComponentWidth(endComponent) * 2;
    endComponent.height = CalculateComponentHeight(endComponent);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");

        // Intial setup
        const scale = window.devicePixelRatio; // Change to 1 on retina screens to see blurry canvas.
        canvas.width = Math.floor(window.innerWidth * scale);
        canvas.height = Math.floor(window.innerHeight * scale);

        // Normalize coordinate system to use CSS pixels.
        ctx.scale(scale, scale);

        setComponents([
            startComponent,
            endComponent,
        ]);

        LoadRolesList({
            appUUID: props.router.params.appUUID,
        }).then((response) => {
            setRoles(response.data.results.map((role) => {
                role.label = role.name;
                role.value = role.uuid;

                return role;
            }));
        })
    }, []);

    // Handle showing the popup when double-clicking on the canvas
    // or showing the properties box when double-clicking on a component
    const showAddComponentPopup = (e) => {
        const canvas = canvasRef.current;

        const mousePos = getMousePos({ event: e, canvas });

        // If we are adding a new arrow, this should no longer be shown as we are in the process of adding a new component instead
        setNewArrow(null);

        components.forEach((component, index) => {
            if (isMouseInComponent({ mousePos, component })) {
                setClickedComponentIndex(index);
                return;
            }
        });

        if (!draggingComponentIndex) {
            // Show the add component popup
            setPopupStyle({
                ...popupStyle,
                display: 'block',
                left: mousePos.x + 'px',
                top: mousePos.y + 'px',
            });
        }
    };

    const onClick = (e) => {
        // Check if the user clicked on the delete icon of the arrow 
        const canvas = canvasRef.current;

        const mousePos = getMousePos({ event: e, canvas });

        let arrowIndexToDelete = null;
        for (let index = 0; index < arrows.length; index++) {
            const arrow = arrows[index];

            if (isMouseOverArrowDeleteIcon({ mousePos, arrow })) {
                arrowIndexToDelete = index;
                break;
            }
        }

        if (arrowIndexToDelete != null) {
            let newArrows = [...arrows];
            newArrows.splice(arrowIndexToDelete, 1);
            setArrows(newArrows);
        }
    }

    // Check if the port is clicked and start a new arrow if needed
    const onMouseDown = (e) => {
        const canvas = canvasRef.current;

        const mousePos = getMousePos({ event: e, canvas });
        let clickedOnPort = false;

        components.forEach((component, index) => {
            if (isMouseInComponent({ mousePos, component })) {
                // Do not highlight anything if the component is the end component
                if (component.id !== 'end') {
                    canvas.style.cursor = "grabbing";

                    setClickedComponentIndex(index);
                    setPipelineResults({});
                }
            }

            component.ports.forEach(port => {
                if (isMouseInPort({ mousePos, component, port })) {
                    if (newArrow) {
                        // Check if this port id and component id are already in the arrows list 
                        const hasExistingArrow = arrows.find((arrow) => arrow.from.componentId == newArrow.fromComponent.id && arrow.from.portId == newArrow.fromPort.id);

                        if (!hasExistingArrow && newArrow.fromPort.type == "output" && port.type == "input") {
                            // Finish the arrow
                            setArrows([
                                ...arrows,
                                {
                                    label: newArrow.label,
                                    from: {
                                        componentId: newArrow.fromComponent.id,
                                        portId: newArrow.fromPort.id
                                    },
                                    to: {
                                        componentId: component.id,
                                        portId: port.id
                                    },

                                    fromX: newArrow.fromX,
                                    fromY: newArrow.fromY,
                                    toX: newArrow.toX,
                                    toY: newArrow.toY,
                                }
                            ]);

                            setNewArrow(null);
                        }
                    } else {
                        // Start a new arrow
                        setNewArrow({
                            label: "",
                            fromComponent: component,
                            fromPort: port,

                            fromX: component.x + port.x * component.width,
                            fromY: component.y + port.y * component.height,

                            toX: mousePos.x,
                            toY: mousePos.y
                        });
                    }

                    clickedOnPort = true;
                }
            });
        });

        if (!clickedOnPort) {
            if (newArrow) {
                setNewArrow(null); // Cancel the new arrow
            } else {
                components.forEach((component, index) => {
                    if (isMouseInComponent({ mousePos, component })) {
                        setDraggingComponentIndex(index);

                        setOffsetXForDraggingComponent(mousePos.x - component.x);
                        setOffsetYForDraggingComponent(mousePos.y - component.y);
                    }
                });
            }
        }
    };

    /// Handle dragging the component or the new arrow
    const onMouseMove = (e) => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");

        const mousePos = getMousePos({ event: e, canvas });

        canvas.style.cursor = "default";


        if (draggingComponentIndex != null) {
            canvas.style.cursor = "grabbing";

            const component = components[draggingComponentIndex];

            const newComponents = [...components];
            newComponents[draggingComponentIndex] = {
                ...component,
                x: mousePos.x - offsetXForDraggingComponent,
                y: mousePos.y - offsetYForDraggingComponent
            };

            setComponents(newComponents);
        }

        if (newArrow) {
            setNewArrow({
                ...newArrow,
                toX: mousePos.x,
                toY: mousePos.y
            });
        }

        // Check if the mouse is hovering over a port so that we can highlight it
        setHoveredPort(null);
        components.forEach((component) => {
            component.ports.forEach((port) => {
                if (isMouseInPort({ mousePos, component, port })) {
                    setHoveredPort({ component, port });
                }
            });
        });

        if (!hoveredPort) {
            // Check if the mouse is hovering over an arrow so that we can highlight it
            arrows.forEach((arrow) => {
                if (isMouseOverArrowDeleteIcon({ mousePos, arrow })) {
                    canvas.style.cursor = "pointer";
                }
            })
        }
    };

    const onMouseUp = (e) => {
        const canvas = canvasRef.current;

        // Check if there is a dragging component
        if (draggingComponentIndex != null) {
            // Update the position of the component
            const mousePos = getMousePos({ event: e, canvas });

            const component = components[draggingComponentIndex];
            component.x = mousePos.x - offsetXForDraggingComponent;
            component.y = mousePos.y - offsetYForDraggingComponent;

            let newComponents = [...components];
            newComponents[draggingComponentIndex] = component;
            setComponents(newComponents);

            // Find any arrows connected to the component and update their positions
            const fromArrowsToUpdate = arrows.filter((arrow) => arrow.from.componentId == component.id);
            const toArrowsToUpdate = arrows.filter((arrow) => arrow.to.componentId == component.id);

            fromArrowsToUpdate.forEach((arrow) => {
                const fromPort = component.ports.find((port) => port.id == arrow.from.portId);

                arrow.fromX = component.x + fromPort.x * component.width;
                arrow.fromY = component.y + fromPort.y * component.height;

            });

            toArrowsToUpdate.forEach((arrow) => {
                const toPort = component.ports.find((port) => port.id == arrow.to.portId);

                arrow.toX = component.x + toPort.x * component.width;
                arrow.toY = component.y + toPort.y * component.height;
            });
        }

        canvas.style.cursor = "default";
        setDraggingComponentIndex(null);
    };

    const handleSaveScheduledTask = async () => {
        // Check if the pipeline is valid
        const pipeline = generateOrderedPipeline();

        let allComponentsReady = true;
        let messages = [];

        if (!Array.isArray(pipeline) || pipeline.length == 0) {
            props.dispatch({
                type: "ResolvedData",
                name: "ModalData",
                data: {
                    show: true,

                    type: "error",
                    title: "Invalid Workflow",
                    message: [
                        "Please ensure that the workflow is connected from the start to the end.",
                        "‏‏‎ ‎",
                        "Also, ensure that all the components are configured correctly."
                    ],
                    okayButtonText: "Okay"
                },
            });

            return;
        }

        // Check if the first element is the start and the last is the end
        if (pipeline[0]?.id != "start" || pipeline[pipeline.length - 1]?.id != "end") {
            allComponentsReady = false;
            messages.push("Please ensure that the first item in the workflow is the Start item and the last item is the End item.");
        } else if (pipeline.length == 2) {
            allComponentsReady = false;
            messages.push("Please ensure that there is at least one workflow item.");
        } else {
            // Check if there is a cron schedule for the start component
            if (!pipeline[0]?.workflowInformation?.schedule || !pipeline[0]?.workflowInformation?.role) {
                allComponentsReady = false;
                messages.push("Please ensure that the Start item has a schedule set and a role to run as.");
            }
        }

        if (!allComponentsReady) {
            props.dispatch({
                type: "ResolvedData",
                name: "ModalData",
                data: {
                    show: true,

                    type: "error",
                    title: "Invalid Workflow",
                    message: messages,
                    okayButtonText: "Okay"
                },
            });

            return;
        }

        // Check if all elements of the pipeline are ready to run except for the last element
        for (let i = 0; i < pipeline.length - 1; i++) {
            const component = pipeline[i];

            if (!component.workflowInformation || component.workflowInformation?.ready != true) {
                allComponentsReady = false;
                break;
            }
        }

        if (!allComponentsReady) {
            props.dispatch({
                type: "ResolvedData",
                name: "ModalData",
                data: {
                    show: true,

                    type: "error",
                    title: "Invalid Workflow",
                    message: [
                        "Please ensure that all the components are configured correctly.",
                    ],
                    okayButtonText: "Okay"
                },
            });
        } else {
            props.dispatch({
                type: "ResolvedData",
                name: "ModalData",
                data: {
                    "show": true,
                    "type": "data-collection",
                    "title": "Save Scheduled Task",
                    yesButtonText: "Save Scheduled Task",
                    noButtonText: "Cancel",
                    modelBodyClasses: [],
                    modalSize: "large",

                    robostackResolveData: [],
                    showSubmitButton: false,
                    fields: [
                        {
                            "name": {
                                "label": "Name",
                                "type": "text",
                                "placeholder": `What would you like to name this scheduled task?`,
                                "value": ``,
                                "required": true,
                                "position": 10,
                            },
                            "description": {
                                "label": "Description",
                                "type": "textarea",
                                "placeholder": `Describe what this scheduled task does`,
                                "value": ``,
                                "required": true,
                                "position": 20,
                            },

                        }
                    ],

                    onYes: (onClose, tuple) => {
                        props.dispatch({
                            type: "ResolvedData",
                            name: "ModalData",
                            data: {
                                "show": true,
                                "type": "processing",
                                "title": "Saving Scheduled Task",
                                "message": [
                                    "This might take a few seconds.",
                                    "You will be shown a confirmation screen once completed."
                                ]
                            },
                        });

                        setTimeout(() => {
                            (async function runInsideTimeout() {
                                try {
                                    const result = await axiosBackend({
                                        method: "PUT",
                                        url: `/service/cron-jobs/${props.router.params.appUUID}/`,
                                        data: {
                                            workflow: formatPipeline(pipeline),

                                            name: tuple.name,
                                            description: tuple.description,
                                        }
                                    });

                                    props.dispatch({
                                        type: "ResolvedData",
                                        name: "ModalData",
                                        data: {
                                            show: true,
                                            type: "success",
                                            title: "Success",
                                            message: [
                                                `The new scheduled task has been successfully saved.`,
                                            ],
                                            okayButtonText: "Okay",
                                        },
                                    });
                                } catch (err) {
                                    console.error(err);

                                    let messages = [
                                        "Due to an error, we were unable to save the scheduled task.",
                                        "‏‏‎ ‎",
                                        "Please try again in a little while."
                                    ];

                                    if (err?.response?.data?.messages) {
                                        messages = err.response.data.messages;
                                    }

                                    props.dispatch({
                                        type: "ResolvedData",
                                        name: "ModalData",
                                        data: {
                                            show: true,
                                            type: "error",
                                            title: "Could not save the scheduled task",
                                            message: messages,
                                            okayButtonText: "Okay"
                                        },
                                    });
                                }
                            })();
                        }, 1000);
                    }
                },
            });
        }
    }

    // Mark the pipeline as not ready to save if the user changes the workflow
    useEffect(() => {
        setWasLastTestRunSuccessful(false);
        setWasEntirePipelineTestRun(false);
    }, [components, arrows])

    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext("2d");

        drawFlowchart({
            ctx: context,
            canvas,

            components: components.map((component) => {
                component.hasError = false;

                if (component.id == "start") {
                    if (!component.workflowInformation || component.workflowInformation?.schedule == undefined || component.workflowInformation?.role == undefined) {
                        component.hasError = true;
                    }

                    return component;
                }

                if (component.id == "end") {
                    // Check if anything is connected to the end
                    const arrow = arrows.find((arrow) => arrow.to.componentId == component.id);

                    if (!arrow) {
                        component.hasError = true;
                    }

                    return component;
                }

                if (!component?.workflowInformation || !component?.workflowInformation?.ready || component?.workflowInformation?.ready != true) {
                    component.hasError = true;
                }

                return component;
            }),
            arrows: arrows,

            newArrow,
            hoveredPort,

            clickedComponent: clickedComponentIndex != null ? components[clickedComponentIndex] : null,

            renderDelete: true,
        });
    }, [
        components,
        arrows,
        clickedComponentIndex,
        popupStyle,
        newArrow,
        hoveredPort
    ]);

    useEffect(() => {
        // Generate the cron string and store it in the start component
        const cron = GenerateCronString({
            frequency,
            months,
            days,
            weekDays,
            hours,
            minutes,
        });

        const startComponentIndex = components.findIndex((component) => component.id == "start");
        if (startComponentIndex > -1) {
            const startComponent = components[startComponentIndex];

            if (startComponent.workflowInformation == undefined) {
                startComponent.workflowInformation = {};
            }

            startComponent.workflowInformation.schedule = cron;

            startComponent.description = [
                cronstrue.toString(cron, {
                    tzOffset
                })
            ];

            if (selectedRole) {
                startComponent.description.push(`Run as role - ${selectedRole.label}`);
                startComponent.workflowInformation.role = selectedRole.value;
            }

            startComponent.workflowInformation.ready = selectedRole && cron ? true : false;

            // Calculate the width and height of the component
            startComponent.width = CalculateComponentWidth(startComponent);
            startComponent.height = CalculateComponentHeight(startComponent);

            let newComponents = [...components];
            newComponents[startComponentIndex] = startComponent;

            setComponents(newComponents);
        }
    }, [frequency, months, days, weekDays, hours, minutes, selectedRole]);

    const addNewComponent = async (newComponentType) => {
        const componentType = newComponentType;

        const mousePos = {
            x: parseInt(popupStyle.left),
            y: parseInt(popupStyle.top)
        };

        // If the y position is negative, that means we clicked on the Add Workflow Item button
        // which is outside of the canvas. In that case, we need to set the y position to after the last component
        // so that the new component is added at the end of the workflow

        if (mousePos.y < 0) {
            const lastComponent = components[components.length - 1];
            mousePos.y = lastComponent.y +
                lastComponent.height + 50;
        }

        let componentToAdd = null;
        let componentDataLoaded = true;

        if (componentType) {

            const componentToAddDefaults = {
                id: 'component-' + uuidv4(),
                ports: [
                    {
                        x: 0.5,
                        y: 0,
                        id: uuidv4(),
                        type: "input",
                    },
                    {
                        x: 0.5,
                        y: 1,
                        id: uuidv4(),
                        type: "output",
                    },
                ],

                x: mousePos.x,
                y: mousePos.y,

                height: 50,

                text: "",
                description: "",

                workflowInformation: {

                },
            };
            switch (componentType.type) {
                case "query": {

                    const text = SupportedWorkflowActions.query.default.name({});

                    componentToAdd = {
                        ...componentToAddDefaults,

                        type: 'rectangle',

                        width: CalculateComponentWidth({
                            text,
                            description: ""
                        }),

                        text,
                        workflowInformation: {
                            type: componentType.type,
                        }
                    }

                    try {
                        const response = await LoadQueriesListFromPlayground({
                            appUUID: props.router.params.appUUID,
                        });

                        setQueryPlaygroundData(response.data.results.map((query) => {
                            query.label = query.name;
                            query.description = query.description;
                            query.value = query.uuid;
                            // query.badge = query.operation.toUpperCase();

                            return query;
                        }));
                    } catch (err) {
                        console.error(err);

                        // Since we could not load the queries, do not add the component
                        componentToAdd = null;
                        componentDataLoaded = false;

                        props.dispatch({
                            type: "ResolvedData",
                            name: "ModalData",
                            data: {
                                show: true,

                                type: "error",
                                title: "Could not load list of Query Playground queries",
                                message: [
                                    "Due to an error, we were unable to load the list of queries from the Query Playground.",
                                    "‏‏‎ ‎",
                                    "Please try again in a little while."
                                ],
                                okayButtonText: "Okay"
                            },
                        });
                    }

                    break;
                }

                case "reports": {
                    let text = "";
                    let validComponentType = false;

                    switch (componentType.value) {
                        case "render_report": {
                            validComponentType = true;
                            text = SupportedWorkflowActions.reports.render_report.name(componentToAddDefaults)

                            break;
                        }

                        case "save_as_pdf": {
                            validComponentType = true;
                            text = SupportedWorkflowActions.reports.save_as_pdf.name(componentToAddDefaults);
                            break;
                        }
                    }

                    if (validComponentType) {
                        componentToAdd = {
                            ...componentToAddDefaults,

                            type: 'rectangle',

                            width: CalculateComponentWidth({
                                text,
                                description: ""
                            }),

                            text,
                            workflowInformation: {
                                type: componentType.type,
                                operation: componentType.value,
                            }
                        }

                        try {
                            const response = await LoadReportsList({
                                appUUID: props.router.params.appUUID,
                            });

                            setReportsData(response.data.results.map((report) => {
                                report.label = report.name;
                                report.description = report.description;
                                report.value = report.uuid;
                                // report.badge = report.operation.toUpperCase();

                                return report;
                            }));
                        } catch (err) {
                            console.error(err);

                            // Since we could not load the reports, do not add the component
                            componentToAdd = null;
                            componentDataLoaded = false;

                            props.dispatch({
                                type: "ResolvedData",
                                name: "ModalData",
                                data: {
                                    show: true,

                                    type: "error",
                                    title: "Could not load list of Reports",
                                    message: [
                                        "Due to an error, we were unable to load the list of reports.",
                                        "‏‏‎ ‎",
                                        "Please try again in a little while."
                                    ],
                                    okayButtonText: "Okay"
                                },
                            });
                        }
                    }

                    break;
                }

                case "communication": {
                    switch (componentType.value) {
                        case "email": {
                            const text = SupportedWorkflowActions.communication.email.name(componentToAddDefaults);

                            componentToAdd = {
                                ...componentToAddDefaults,
                                type: 'rectangle',

                                width: CalculateComponentWidth({
                                    text,
                                    description: ""
                                }),

                                text,
                                workflowInformation: {
                                    type: componentType.type,
                                    operation: componentType.value,
                                }
                            }

                            break;
                        }
                    }
                }
            }
        }

        if (componentToAdd != null && componentDataLoaded) {
            // Make this the selected 
            setComponents([
                ...components,
                componentToAdd,
            ]);
            setClickedComponentIndex(components.length);

            // We are only resetting here as there could have been an error that prevented the component from being added
            // so we do not want to reset the popup if the component was not added
            setNewComponentType(null);
            setPopupStyle({
                ...popupStyle,
                display: 'none'
            });
        }
    }

    const handleEmailFieldsChange = ({ field, value }) => {
        const component = components[clickedComponentIndex];

        if (!component.workflowInformation) {
            component.workflowInformation = {
                data: {
                    action: {

                    }
                },
            };
        }

        if (!component.workflowInformation.data) {
            component.workflowInformation.data = {
                action: {

                }
            };
        }

        if (!component.workflowInformation.data.action) {
            component.workflowInformation.data.action = {};
        }

        component.workflowInformation.data.action[field] = value;

        // Assume the component is ready
        component.workflowInformation.ready = true;

        const data = component.workflowInformation.data.action;

        // Check if the component is ready to run
        if (!data.to || data.to?.length == 0) {
            component.workflowInformation.ready = false;
        }

        if (!data.subject || data.subject?.length == 0) {
            component.workflowInformation.ready = false;
        }

        if (!data.template || data.template?.length == 0) {
            component.workflowInformation.ready = false;
        }

        if (!data.previousDataKey) {
            component.workflowInformation.ready = false;
        }

        if (!data.mode) {
            component.workflowInformation.ready = false;
        }

        let newComponents = [...components];
        newComponents[clickedComponentIndex] = component;
        setComponents(newComponents);
    }

    const handleSelectedQueryChange = (selectedQuery) => {
        const component = components[clickedComponentIndex];
        component.workflowInformation.data = selectedQuery;
        component.description = selectedQuery?.name;

        component.width = CalculateComponentWidth(component);

        // For reports, we only need to select the report and the component is ready to run in the pipeline
        component.workflowInformation.ready = true;

        let newComponents = [...components];
        newComponents[clickedComponentIndex] = component;

        setComponents(newComponents);
    }

    const handleSelectedReportChange = (selectedReport) => {
        const component = components[clickedComponentIndex];
        component.workflowInformation.data = selectedReport;
        component.description = selectedReport?.name;

        component.width = CalculateComponentWidth(component);

        // For reports, we only need to select the report and the component is ready to run in the pipeline
        component.workflowInformation.ready = true;

        let newComponents = [...components];
        newComponents[clickedComponentIndex] = component;

        setComponents(newComponents);
    }

    // Check if we can run the pipeline to the previous component so that we can get the data output from it
    const checkIfRunnableToPreviousComponent = (currentComponent) => {
        if (!currentComponent) {
            return false;
        }

        // Find the previous component
        const arrow = arrows.find((arrow) => arrow.to.componentId == currentComponent.id);

        if (arrow) {
            const previousComponent = components.find((component) => component.id == arrow.from.componentId);

            if (previousComponent) {
                if (previousComponent.id == "start") {
                    return true;
                }

                if (previousComponent) {
                    if (previousComponent.workflowInformation?.ready === true) {
                        return checkIfRunnable(previousComponent);
                    }
                }
            }
        }

        return false;
    }

    // Check if we can run the pipeline to the current component
    const checkIfRunnable = (currentComponent, previousComponent) => {
        if (!currentComponent) {
            return false;
        }

        // Check if there is an arrow connected to the current component
        if (currentComponent.id == "start" && previousComponent) {
            return true; // The start component can always run so we only return true if there is a previous component
        }

        // We need the current component to be configured
        if (currentComponent?.workflowInformation?.ready !== true) {
            return false;
        }

        const arrow = arrows.find((arrow) => arrow.to.componentId == currentComponent.id);

        if (arrow) {
            // Check if we have the previous component
            const previousComponent = components.find((component) => component.id == arrow.from.componentId);

            if (previousComponent) {
                return checkIfRunnable(previousComponent, currentComponent);
            }
        }

        return false;
    }

    const formatPipeline = (pipeline) => {
        return JSON.parse(JSON.stringify(pipeline)).map((item) => {
            if (item.workflowInformation?.type) {
                if (item.workflowInformation.type == "communication") {
                    if (item.workflowInformation.operation == "email") {
                        delete item.workflowInformation.datasetOptions;

                        // Set the mode value
                        if (item.workflowInformation.data.action.mode) {
                            item.workflowInformation.data.action.mode = item.workflowInformation.data.action.mode.value;
                        }

                        // Set the previous data key
                        if (item.workflowInformation.data.action.previousDataKey) {
                            const previousDataKey = item.workflowInformation.data.action.previousDataKey;

                            // If the value is previous, we do not set anything as we will work on it directly
                            if (previousDataKey.type == "original" && previousDataKey.value == "previous") {
                                delete item.workflowInformation.data.action.previousDataKey;
                            } else {
                                item.workflowInformation.data.action.previousDataKey = previousDataKey.value;
                            }
                        }
                    }
                }
            }

            return item;
        });
    }

    const generateOrderedPipeline = () => {
        let pipeline = [];

        let currentComponent = components.find((component) => component.id == "start");
        let uninterruptedFlow = true;

        while (currentComponent) {
            let item = {
                ...currentComponent,
            };


            pipeline.push(item);

            if (currentComponent.id == "end") {
                break;
            }

            let arrow = arrows.find((arrow) => arrow.from.componentId == currentComponent.id);

            if (arrow) {
                currentComponent = components.find((component) => component.id == arrow.to.componentId);
            } else {
                currentComponent = null;
                uninterruptedFlow = false;
            }
        }

        if (uninterruptedFlow) {
            return pipeline;
        } else {
            return null;
        }
    }

    useEffect(() => {
        const currentComponent = components[clickedComponentIndex];

        if (currentComponent?.workflowInformation?.type == "communication") {
            if (currentComponent?.workflowInformation?.operation == "email") {
                if (typeof pipelineResults == "object" && pipelineResults?.previous != undefined && Object.keys(pipelineResults.previous).length > 0) {
                    const existingComponents = [...components];
                    const componentIndex = existingComponents.findIndex((component) => component.id == currentComponent.id);

                    if (componentIndex > -1) {
                        const component = existingComponents[componentIndex];

                        if (!component.workflowInformation) {
                            component.workflowInformation = {};
                        }

                        component.workflowInformation.datasetOptions = [
                            {
                                label: "Original Data",
                                options: [
                                    {
                                        type: "original",
                                        value: "previous",
                                        label: "Work on previous data directly"
                                    },
                                ]
                            },
                            {
                                label: "Datasets inside the results from the previous component",
                                options: Object.keys(pipelineResults.previous).map((key) => {
                                    return {
                                        type: "previous",
                                        value: key,
                                        label: key
                                    }
                                })
                            },
                        ]

                        existingComponents[componentIndex] = component;
                        setComponents(existingComponents);
                    }
                }
            }
        }

    }, [pipelineResults])

    const runPipeline = async (currentComponent) => {
        let pipeline = [];
        let isEndComponentBeingTested = false; // Check if the end component is tested as this would be the entire pipeline being tested

        if (currentComponent.id == endComponent.id) {
            isEndComponentBeingTested = true;

            // We do not want the pipeline results being shown incorrectly if the end component is being tested 
            // as that represents the entire pipeline
            setClickedComponentIndex(null);
        }

        let uninterruptedFlow = true;

        while (currentComponent) {
            if (currentComponent.id == startComponent.id) {
                break;
            }

            pipeline.push({
                id: currentComponent.id,
                text: currentComponent.text,
                description: currentComponent.description,

                workflowInformation: currentComponent.workflowInformation,
            });

            let arrow = arrows.find((arrow) => arrow.to.componentId == currentComponent.id);

            if (arrow) {
                currentComponent = components.find((component) => component.id == arrow.from.componentId);
            } else {
                currentComponent = null;
                uninterruptedFlow = false;
            }
        }

        if (uninterruptedFlow) {
            pipeline = pipeline.reverse();
            pipeline.pop(); // Remove the component that was the original current component 

            if (pipeline.length > 0) {
                setPipelineResults({});
                setWasEntirePipelineTestRun(isEndComponentBeingTested);

                props.dispatch({
                    type: "ResolvedData",
                    name: "ModalData",
                    data: {
                        "show": true,
                        "type": "processing",
                        "title": "Running",
                        "message": [
                            "This might take a few seconds.",
                        ]
                    },
                });

                setTimeout(async () => {
                    try {
                        // Format the pipeline as needed after deep cloning
                        const formattedPipeline = formatPipeline(pipeline);

                        const response = await axiosBackend({
                            method: "PUT",
                            url: `/service/cron-jobs/${props.router.params.appUUID}/run-pipeline`,
                            data: {
                                pipeline: formattedPipeline,
                                role: selectedRole?.value,
                            }
                        })

                        let result = [];

                        if (isEndComponentBeingTested) {
                            setWasLastTestRunSuccessful(response.data.status == "success");
                        }

                        // Because of how the backend returns results, we need to check if the result is an array and only use the first item
                        if (response.data.results.length == 1) {
                            result = response.data.results[0];
                        }

                        setPipelineResults(result);
                        props.dispatch({
                            type: "ResolvedData",
                            name: "ModalData",
                            data: {
                                "show": false,
                            },
                        });
                    } catch (err) {
                        console.error(err);

                        // Check if the error is due to a failure in the pipeline
                        if (err.response?.data?.status == "failure") {
                            setPipelineResults(err.response.data);

                            props.dispatch({
                                type: "ResolvedData",
                                name: "ModalData",
                                data: {
                                    show: false,
                                },
                            });
                        } else {
                            let messages = [
                                "Due to an error, we were unable to run the pipeline.",
                                "‏‏‎ ‎",
                                "Please try again in a little while."
                            ];

                            if (err.response?.data?.messages) {
                                messages = err.response.data.messages;
                            }

                            props.dispatch({
                                type: "ResolvedData",
                                name: "ModalData",
                                data: {
                                    show: true,

                                    type: "error",
                                    title: "Could not run pipeline",
                                    message: messages,
                                    okayButtonText: "Okay"
                                },
                            });
                        }
                    }
                }, 1000);


                return;
            } else {
                props.dispatch({
                    type: "ResolvedData",
                    name: "ModalData",
                    data: {
                        show: true,

                        type: "error",
                        title: "Not enough pipeline",
                        message: [
                            "There are no workflow items that can be run before this item.",
                            "‏‏‎ ‎",
                            "If this is the first item in the workflow, it will not have any data available to process.",
                        ],
                        okayButtonText: "Okay"
                    },
                });
            }


        } else {
            props.dispatch({
                type: "ResolvedData",
                name: "ModalData",
                data: {
                    show: true,

                    type: "error",
                    title: "Invalid workflow",
                    message: [
                        "Please ensure that the workflow is connected from the start to the end.",
                        "‏‏‎ ‎",
                        "Also, ensure that all the components are configured correctly."
                    ],
                    okayButtonText: "Okay"
                },
            });
        }
    }

    const handleEditorDidMount = (editor, monaco) => {
        editorRef.current = editor;
    }

    const deleteComponent = () => {
        if (clickedComponentIndex != null) {
            const component = components[clickedComponentIndex];

            if (!(component.id == "start" || component.id == "end")) {
                props.dispatch({
                    type: "ResolvedData",
                    name: "ModalData",
                    data: {
                        "show": true,
                        "type": "confirmation",
                        "title": "Confirm Removal",
                        "message": [
                            "Are you sure you would like to remove this workflow item?",
                            "‏‏‎ ‎",
                            "Any connections to this item will also be removed"
                        ],
                        yesButtonText: "Yes",
                        noButtonText: "No",
                        onYes: (onClose) => {
                            // Delete the arrows connected to the component
                            let newArrows = [...arrows].filter((arrow, index) => {
                                if (arrow.from.componentId != component.id && arrow.to.componentId != component.id) {
                                    return true;
                                }

                                return false;
                            });

                            setArrows(newArrows);

                            // Remove the component
                            let newComponents = [...components];
                            newComponents.splice(clickedComponentIndex, 1);
                            setComponents(newComponents);

                            setClickedComponentIndex(null);

                            onClose();
                        }
                    },
                });
            }
        }
    }

    const insertEJSForLoop = ({ }) => {
        let text = "";

        if (components[clickedComponentIndex]?.workflowInformation?.data?.action?.mode) {
            const mode = components[clickedComponentIndex]?.workflowInformation?.data?.action?.mode.value;
            if (mode == "each") {
                text = "<%- data %>";
            } else {
                text = "<% for (const index in locals.data) { %>\n    <%= locals.data[index] %>\n<% } %>\n";
            }

        }

        const selection = editorRef.current.getSelection();
        const id = { major: 1, minor: 1 };
        const op = { identifier: id, range: selection, text: text, forceMoveMarkers: true };
        editorRef.current.executeEdits("my-source", [op]);
    }

    const prettify = () => {
        const canvas = canvasRef.current;

        const existingComponents = generateOrderedPipeline();
        const currentClickedComponent = components[clickedComponentIndex];

        const {
            components: generatedComponents,
            arrows: generatedArrows,
            startX,
            startY,
        } = MakeFlowchartNeat({
            components: existingComponents.filter((component) => {
                return component.id != startComponent.id && component.id != endComponent.id;
            }),
            arrows,
            canvas,
            startComponent: existingComponents.find((component) => component.id == startComponent.id),
            endComponent: existingComponents.find((component) => component.id == endComponent.id),
        });

        canvas.height = Math.max(canvas.height, startY);

        if (currentClickedComponent) {
            const currentClickedComponentIndex = generatedComponents.findIndex((component) => component.id == currentClickedComponent.id);
            if (currentClickedComponentIndex > -1) {
                setClickedComponentIndex(currentClickedComponentIndex);
            }
        }

        setComponents(generatedComponents);
        setArrows(generatedArrows);
    }

    const runnableToEndComponent = checkIfRunnableToPreviousComponent(endComponent);

    return <>
        <ReactJoyride
            callback={handleJoyrideCallback}
            run={showTour}
            stepIndex={stepIndex}
            steps={steps}
            continuous={true}
            showSkipButton={true}
            showProgress={true}
            styles={{
                options: {
                    zIndex: 10000,
                },
            }}
        />

        <div className="mt-1 mb-2 col-sm-12">
            <div className="row">
                <div className="col-sm-8">
                    <button onClick={toggleHelp} id="toggle-help" className="btn btn-gray">
                        <span className="far fa-question-circle fa-lg"></span>
                    </button>
                </div>

                <div className="col-sm-4 text-right">
                    <button
                        id="test-run-pipeline"
                        disabled={!runnableToEndComponent}
                        onClick={() => runPipeline(endComponent)}
                        type="button"
                        className="mr-2 btn btn-primary float-right"
                    >
                        Test Run Workflow
                    </button>

                    <button
                        id="save-scheduled-task"
                        disabled={!runnableToEndComponent || !wasLastTestRunSuccessful}
                        onClick={handleSaveScheduledTask}
                        type="button"
                        className="mr-2 btn btn-primary float-right"
                    >
                        Save Scheduled Task
                    </button>
                </div>
            </div>

        </div>

        <div className="col-sm-12">
            <div className="">
                <div className="row">
                    <div className="col-sm-6 pr-0">
                        <div className="ibox-content p-1">
                            <div id="workflow" className={`panel panel-default mb-0`}>
                                <div className="panel-heading">
                                    <label className="font-bold">Workflow Designer</label>

                                    <button
                                        disabled={!runnableToEndComponent}
                                        onClick={prettify}
                                        className="btn btn-xs btn-primary float-right"
                                    >
                                        Prettify
                                    </button>
                                </div>
                                <div className="panel-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <button
                                                id="add-workflow-item"
                                                onClick={showAddComponentPopup}
                                                className="btn btn-xs btn-default"
                                            >
                                                <i className="fa fa-plus" />&nbsp;Add Workflow Item
                                            </button>
                                        </div>
                                    </div>

                                    <canvas
                                        id="workflow-canvas"
                                        ref={canvasRef}
                                        onMouseUp={onMouseUp}
                                        onMouseMove={onMouseMove}
                                        onMouseDown={onMouseDown}
                                        onClick={onClick}
                                        onDoubleClick={showAddComponentPopup}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {wasEntirePipelineTestRun ? <>
                        <div className="col-sm-6">
                            <div className="ibox-content p-1 mb-0">
                                <div className={`panel panel-default mb-0`}>
                                    <div className="panel-heading">
                                        <label className="font-bold">Pipeline Results</label>
                                    </div>
                                    <div className="panel-body">
                                        <ReactJson
                                            src={pipelineResults}
                                        />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                        :
                        <>

                            <div className="col-sm-3 pr-0">
                                <div className="ibox-content p-1">
                                    <div id="workflow-item-properties" className={`panel panel-default mb-0`}>
                                        <div className="panel-heading">
                                            <label className="font-bold">Properties</label>

                                            {clickedComponentIndex != null && components[clickedComponentIndex].id != "start" && components[clickedComponentIndex].id != "end" && <>
                                                <button
                                                    id="delete-item"
                                                    disabled={clickedComponentIndex === null}
                                                    onClick={deleteComponent}
                                                    className="btn btn-xs btn-danger float-right"
                                                >
                                                    <i className="fa fa-trash-alt" />
                                                </button>
                                            </>}
                                        </div>
                                        <div className="panel-body">

                                            {clickedComponentIndex != null ? <>
                                                {components[clickedComponentIndex].id == "start" &&
                                                    <>
                                                        <div>
                                                            <div>
                                                                <label>
                                                                    Which role should this task be run as?

                                                                    <Select
                                                                        placeholder="Select Role"
                                                                        options={roles}
                                                                        value={selectedRole}
                                                                        onChange={(selectedOption) => setSelectedRole(selectedOption)}
                                                                    />
                                                                </label>
                                                            </div>

                                                            <div>
                                                                <label>
                                                                    How often do you want to run this task?
                                                                    <Select
                                                                        placeholder="Select Frequency"
                                                                        value={frequency}
                                                                        onChange={setFrequency}
                                                                        style={{ marginLeft: "10px" }}
                                                                        options={[
                                                                            { value: "minute", label: "Every 15 Minutes" },
                                                                            { value: "hour", label: "Every Hour" },
                                                                            { value: "day", label: "Every Day" },
                                                                            { value: "week", label: "Every Week" },
                                                                            { value: "month", label: "Every Month" },
                                                                            { value: "year", label: "Every Year" },
                                                                        ]}
                                                                    />
                                                                </label>
                                                            </div>

                                                            {["year"].includes(frequency?.value) && (
                                                                <div>
                                                                    <label>
                                                                        On which months of the year?
                                                                        <Select
                                                                            isMulti
                                                                            options={cronTimeOptions.months}
                                                                            onChange={(selected) => setMonths(selected)}
                                                                            value={months}
                                                                        />
                                                                    </label>
                                                                </div>
                                                            )}

                                                            {["year", "month"].includes(frequency?.value) && (
                                                                <div>
                                                                    <label>
                                                                        On what days of the month?
                                                                        <Select
                                                                            isMulti
                                                                            options={cronTimeOptions.days}
                                                                            onChange={(selected) => setDays(selected)}
                                                                            value={days}
                                                                        />
                                                                    </label>
                                                                </div>
                                                            )}

                                                            {["week"].includes(frequency?.value) && (
                                                                <div>
                                                                    <label>
                                                                        On which days of the week?
                                                                        <Select
                                                                            isMulti
                                                                            options={cronTimeOptions.weekDays}
                                                                            onChange={(selected) =>
                                                                                setWeekDays(selected.map((opt) => opt))
                                                                            }
                                                                            value={weekDays}
                                                                        />
                                                                    </label>
                                                                </div>
                                                            )}

                                                            <div className="d-flex justify-content-start">
                                                                <div className="">
                                                                    {["year", "month", "week", "day"].includes(frequency?.value) && (
                                                                        <div>
                                                                            <label>
                                                                                Hours:
                                                                                <Select
                                                                                    isMulti
                                                                                    options={cronTimeOptions.hours}
                                                                                    onChange={(selected) => setHours(selected)}
                                                                                    value={hours}
                                                                                />
                                                                            </label>
                                                                        </div>
                                                                    )}
                                                                </div>

                                                                <div className="">
                                                                    {["year", "month", "week", "day", "hour"].includes(frequency?.value) && (
                                                                        <div>
                                                                            <label>
                                                                                Minutes:
                                                                                <Select
                                                                                    isMulti
                                                                                    options={cronTimeOptions.minutes}
                                                                                    onChange={(selected) =>
                                                                                        setMinutes(selected)
                                                                                    }
                                                                                    value={minutes}
                                                                                />
                                                                            </label>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                }

                                                {components[clickedComponentIndex].workflowInformation?.type == "query" &&
                                                    <>
                                                        What query do you want to run?
                                                        <Select
                                                            components={{ Option: SelectWithDescriptionQueryOption }}
                                                            filterOption={filterQueryOptionsWithDescription}
                                                            onChange={handleSelectedQueryChange}
                                                            options={queryPlaygroundData}
                                                            value={components[clickedComponentIndex]?.workflowInformation?.data}
                                                            noOptionsMessage={() => "No existing queries found"}
                                                            placeholder="Select the query from the query playground to run"
                                                        />
                                                    </>
                                                }

                                                {components[clickedComponentIndex].workflowInformation?.type == "reports" &&
                                                    <>
                                                        What report do you want to generate?
                                                        <Select
                                                            components={{ Option: SelectWithDescriptionQueryOption }}
                                                            filterOption={filterQueryOptionsWithDescription}
                                                            onChange={handleSelectedReportChange}
                                                            options={reportsData}
                                                            value={components[clickedComponentIndex]?.workflowInformation?.data}
                                                            noOptionsMessage={() => "No existing reports found"}
                                                            placeholder="Select the report to generate"
                                                        />
                                                    </>
                                                }

                                                {components[clickedComponentIndex].workflowInformation?.type == "communication" &&
                                                    <>
                                                        {components[clickedComponentIndex].workflowInformation?.operation == "email" &&
                                                            <>
                                                                {!checkIfRunnableToPreviousComponent(components[clickedComponentIndex]) ?
                                                                    <>
                                                                        Please ensure that this component is connected to a flowing workflow to see the data available to send email to.<br />
                                                                        <br />
                                                                        A flowing workflow is one the first component is the start component and all the components that lead to this component are configured correctly.
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {
                                                                            components[clickedComponentIndex]?.workflowInformation?.datasetOptions != undefined ?
                                                                                <>
                                                                                    <div>
                                                                                        <label>
                                                                                            Dataset To Use:
                                                                                        </label>

                                                                                        <Select
                                                                                            placeholder="Select dataset from previous component to send email to"
                                                                                            options={components[clickedComponentIndex].workflowInformation.datasetOptions}
                                                                                            onChange={(selected) => handleEmailFieldsChange({ field: "previousDataKey", value: selected })}
                                                                                            value={components[clickedComponentIndex]?.workflowInformation?.data?.action?.previousDataKey}
                                                                                        />

                                                                                    </div>

                                                                                    <div>
                                                                                        <label>
                                                                                            How many emails do you want to send?
                                                                                        </label>

                                                                                        <Select
                                                                                            options={emailOptions}
                                                                                            placeholder="Select email sending mode"
                                                                                            value={components[clickedComponentIndex]?.workflowInformation?.data?.action?.mode}
                                                                                            onChange={(selected) => handleEmailFieldsChange({ field: "mode", value: selected })}
                                                                                        />
                                                                                    </div>

                                                                                    {components[clickedComponentIndex]?.workflowInformation?.data?.action?.mode != undefined &&
                                                                                        <>

                                                                                            <div>
                                                                                                <label>
                                                                                                    To:
                                                                                                </label>
                                                                                                <TagsInput
                                                                                                    addOnBlur={true}
                                                                                                    addKeys={[188, 9, 13]} /* 188 is a comma, 9 is tab, 13 is enter */
                                                                                                    className="react-tagsinput no-borders p-0"
                                                                                                    value={components[clickedComponentIndex]?.workflowInformation?.data?.action?.to || []}
                                                                                                    onChange={(value) => handleEmailFieldsChange({ field: "to", value })}
                                                                                                    inputProps={{
                                                                                                        className: "form-control",
                                                                                                        placeholder: "Enter email addresseses"

                                                                                                    }}
                                                                                                />
                                                                                            </div>

                                                                                            <div>
                                                                                                <label>
                                                                                                    Subject:
                                                                                                </label>
                                                                                                <input
                                                                                                    type="text"
                                                                                                    className="form-control"
                                                                                                    placeholder="Subject"
                                                                                                    value={components[clickedComponentIndex]?.workflowInformation?.data?.action?.subject || ""}
                                                                                                    onChange={(event) => handleEmailFieldsChange({ field: "subject", value: event.target.value })}
                                                                                                />
                                                                                            </div>

                                                                                            <div>
                                                                                                <label>
                                                                                                    Email Body:
                                                                                                </label>

                                                                                                <button
                                                                                                    onClick={() => insertEJSForLoop({
                                                                                                    })}
                                                                                                    className="btn btn-xs"
                                                                                                >
                                                                                                    <i className="fa fa-code" />
                                                                                                </button>

                                                                                                <Editor
                                                                                                    onMount={handleEditorDidMount}
                                                                                                    ref={editorRef}
                                                                                                    language="html"
                                                                                                    height="10vh"
                                                                                                    value={components[clickedComponentIndex]?.workflowInformation?.data?.action?.template || ""}
                                                                                                    onChange={(value) => handleEmailFieldsChange({ field: "template", value: value })}
                                                                                                    options={{
                                                                                                        minimap: {
                                                                                                            enabled: false
                                                                                                        },
                                                                                                        lineNumbers: "off",
                                                                                                    }}
                                                                                                />
                                                                                            </div>
                                                                                        </>}
                                                                                </>
                                                                                :
                                                                                <div>
                                                                                    Please run the pipeline upto this component to see the datasets that will be available.
                                                                                </div>
                                                                        }
                                                                    </>
                                                                }

                                                            </>
                                                        }
                                                    </>}
                                            </>
                                                :
                                                <>
                                                    Click on a workflow item to view more information about it
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-3">
                                <div className="ibox-content p-1 mb-0">
                                    <div id="workflow-data-available-to-item" className={`panel panel-default mb-0`}>
                                        <div className="panel-heading">
                                            <label className="font-bold">Data Available To This Item</label>

                                            {checkIfRunnableToPreviousComponent(components[clickedComponentIndex]) &&
                                                <>
                                                    <button
                                                        onClick={() => runPipeline(components[clickedComponentIndex])}
                                                        className="btn btn-xs btn-primary float-right"
                                                    >
                                                        <i className="fa fa-play" />&nbsp;Run
                                                    </button>
                                                </>
                                            }
                                        </div>
                                        <div className="panel-body">
                                            {clickedComponentIndex == null ?
                                                <>
                                                    Click on a workflow item to view data that is available to it
                                                </>
                                                :
                                                <>
                                                    {components[clickedComponentIndex].id == "start" && "This is the start of the workflow and no data is available here"}

                                                    {components[clickedComponentIndex].id == "end" && "This is the end of the workflow and no data is available here"}

                                                    {components[clickedComponentIndex].id != "start" && components[clickedComponentIndex].id != "end" &&
                                                        <>
                                                            {checkIfRunnableToPreviousComponent(components[clickedComponentIndex]) ?
                                                                <>
                                                                    <ReactJson
                                                                        src={pipelineResults}
                                                                    />
                                                                </>
                                                                :
                                                                "Please ensure that this component is connected to a previous component in the workflow"
                                                            }
                                                        </>
                                                    }
                                                </>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>

                <div id="add-workflow-item-popup" className="popup" style={popupStyle}>
                    <Select
                        placeholder="What type of action do you want to add?"
                        value={newComponentType}
                        onChange={setNewComponentType}
                        className="pb-2"
                        options={ComponentTypesToAdd}
                    />
                    <button disabled={!newComponentType} onClick={() => addNewComponent(newComponentType)} className="btn btn-sm btn-primary" id="add-component">Add Action</button>
                    <button onClick={() => setPopupStyle({ ...popupStyle, "display": "none" })} className="btn btn-sm btn-danger" id="cancel-add-component">Cancel</button>
                </div>
            </div>
        </div >
    </>;

}

export default withLoaderData(connect(DataDelegator.mapStateToProps)(AddScheduledTask));