// Draw an arrow with a label
export function drawArrow({
    ctx,
    fromX,
    fromY,
    toX,
    toY,
    label,
    renderDelete = false,
}) {
    const headLength = 10;
    const angle = Math.atan2(toY - fromY, toX - fromX);

    const midX = (fromX + toX) / 2;
    const midY = (fromY + toY) / 2;

    // Draw the line
    ctx.strokeStyle = 'black';
    ctx.lineWidth = 1;
    ctx.beginPath();
    ctx.moveTo(fromX, fromY);
    ctx.lineTo(toX, toY);
    ctx.stroke();

    // Draw the arrow head
    ctx.beginPath();
    ctx.moveTo(toX, toY);
    ctx.lineTo(toX - headLength * Math.cos(angle - Math.PI / 6), toY - headLength * Math.sin(angle - Math.PI / 6));
    ctx.lineTo(toX - headLength * Math.cos(angle + Math.PI / 6), toY - headLength * Math.sin(angle + Math.PI / 6));
    ctx.lineTo(toX, toY);
    ctx.fill();

    // Draw the label
    ctx.font = '12px Arial';
    ctx.fillStyle = 'black';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.save();
    ctx.translate(midX, midY);
    ctx.rotate(angle);
    ctx.fillText(label, 0, -10);

    if (renderDelete) {
        ctx.restore();

        // Draw the delete icon (red X)
        const deleteIconSize = 10;
        const deleteIconX = midX - deleteIconSize / 2;
        const deleteIconY = midY - deleteIconSize;

        ctx.strokeStyle = 'red';
        ctx.lineWidth = 2;
        ctx.beginPath();

        ctx.moveTo(deleteIconX, deleteIconY);
        ctx.lineTo(deleteIconX + deleteIconSize, deleteIconY + deleteIconSize);

        ctx.moveTo(deleteIconX + deleteIconSize, deleteIconY);
        ctx.lineTo(deleteIconX, deleteIconY + deleteIconSize);

        ctx.stroke();
    }

    ctx.restore();
}