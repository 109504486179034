// Check if the mouse is in a component
export default function isMouseInComponent({ mousePos, component }) {
    if(!component) {
        return false;
    }
    
    if (component.type === 'rectangle') {
        return mousePos.x >= component.x && mousePos.x <= component.x + component.width &&
            mousePos.y >= component.y && mousePos.y <= component.y + component.height;
    }
}