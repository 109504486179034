import axiosBackend from "core/api/backend";

export default function LoadReport({
    appUUID,
    reportUUID,
}) {
    return axiosBackend({
        method: "POST",
        url: `/service/reports/${appUUID}/${reportUUID}/`,
    })
}