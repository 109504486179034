import { useState, useEffect } from 'react';
import withLoaderData from 'components/withLoaderData';

import { connect } from 'react-redux';
import * as DataDelegator from "components/smart/delegator/DataDelegator";

import axiosBackend from 'core/api/backend';

import Company from 'pages/company';

import Select from 'react-select';
import SelectWithDescriptionQueryOption, { filterQueryOptionsWithDescription } from 'components/select-with-description/SelectWithDescriptionQueryOption';

const AdminCompanyDashboard = (props) => {
    const [companies, setCompanies] = useState([]);
    const [errors, setErrors] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);

    useEffect(() => {
        axiosBackend({
            method: 'post',
            url: '/administration/resources/billing-customers',
        }).then((response) => {
            setCompanies(response.data.results.sort((a, b) => a.name.localeCompare(b.name)));
        }).catch((error) => {
            setErrors(error);
        });
    }, []);

    return (
        <div className='col-sm-12'>
            <div className='ibox-content mt-2'>
                <div className='row'>

                    <div className='col-sm-12'>
                        <Select
                            id="select-query"
                            components={{ Option: SelectWithDescriptionQueryOption }}
                            filterOption={filterQueryOptionsWithDescription}
                            options={companies && companies.map((company) => {
                                company.label = company.name;
                                company.value = company.uuid;

                                company.description = company.invoiceShortCode;

                                return company;
                            })}
                            value={selectedCompany}
                            onChange={(selectedCompany) => setSelectedCompany(selectedCompany)}
                            noOptionsMessage={() => "No existing customers found"}
                            placeholder="Select an existing customer to load"
                            className="mb-2"
                        />

                        {selectedCompany &&
                            <Company
                                baseUrl={`/administration/customers/${selectedCompany.value}`}
                            />
                        }
                    </div>

                    <div className='col-sm-12 mt-2'>
                        {selectedCompany == null && <>
                            <span>Select a customer to view their dashboard</span>
                        </>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withLoaderData(connect(DataDelegator.mapStateToProps)(AdminCompanyDashboard));