import JSON_ARRAY_Simple_Render from "../../../datatypes/json_array/simple";
import JSON_Simple_Render from "../../../datatypes/json/simple";
import File_Full_Render from "../../../datatypes/file/full";
import Email_Simple_Render from "../../../datatypes/email/simple";
import Currency_Simple_Render from "../../../datatypes/currency/simple";
import User_Simple_Render from "../../../datatypes/user/simple"; 
import Lookup_Simple_Render from "../../../datatypes/lookup/simple";
import Boolean_Simple_Render from "../../../datatypes/boolean/simple";
import Markdown_Simple_Render from "../../../datatypes/markdown/simple";

import JSON_ARRAY_Value_Render from "../../../datatypes/json_array/value";
import JSON_Value_Render from "../../../datatypes/json/value";
import Email_Value_Render from "../../../datatypes/email/value";
import Currency_Value_Render from "../../../datatypes/currency/value";
import User_Value_Render from "../../../datatypes/user/value"; 
import Lookup_Value_Render from "../../../datatypes/lookup/value";
import Boolean_Value_Render from "../../../datatypes/boolean/value";
import Markdown_Value_Render from "../../../datatypes/markdown/value";

export default function SimpleRenderField(props) {
    const simpleRender = {
        "JSON_ARRAY": JSON_ARRAY_Simple_Render,
        "JSON": JSON_Simple_Render,
        "FILE": File_Full_Render,
        "EMAIL": Email_Simple_Render,
        "CURRENCY": Currency_Simple_Render,
        "USER": User_Simple_Render,
        "USER_UPDATE": User_Simple_Render,
        "BOOLEAN": Boolean_Simple_Render,
        "MARKDOWN": Markdown_Simple_Render,
        "LOOKUP": Lookup_Simple_Render,
    };

    const valueRender = {
        "JSON_ARRAY": JSON_ARRAY_Value_Render,
        "JSON": JSON_Value_Render,
        "EMAIL": Email_Value_Render,
        "CURRENCY": Currency_Value_Render,
        "USER": User_Value_Render,
        "USER_UPDATE": User_Value_Render,
        "BOOLEAN": Boolean_Value_Render,
        "MARKDOWN": Markdown_Value_Render, 
        "LOOKUP": Lookup_Value_Render,
    }

    const { data, column, applicationUsers, render } = props;

    let renderer = render == "value" ? valueRender : simpleRender;

    let value = data[column.fieldName];

    if(["USER", "USER_UPDATE"].includes(column.fieldType)) {
        let userInfoToShow = data[column.fieldName];

        if(applicationUsers !== undefined) {
            userInfoToShow = applicationUsers[userInfoToShow];
        }

        value = renderer[column.fieldType](userInfoToShow, column.fieldName, data);
    } 

    else if (column.fieldType == "LOOKUP") {
        if (Array.isArray(column.resourceDisplayKeysMultiple)) {
            value = column.resourceDisplayKeysMultiple;
        }

        value = renderer["LOOKUP"](value, column.fieldName, data);
    }
    
    else if (renderer[column.fieldType] !== undefined) {
        value = renderer[column.fieldType](data[column.fieldName], column.fieldName, data);
    } 

    return value;
}