import CalculateComponentHeight from "../../utils/calculate-component-height";
import CalculateComponentWidth from "../../utils/calculate-component-width";

export default function MakeFlowchartNeat({
    canvas,
    components,
    arrows,
    startComponent,
    endComponent,
}) {
    const generatedComponents = [];
    const generatedArrows = [];

    let paddingBetweenComponents = 50;

    let height = CalculateComponentHeight(startComponent);
    let width = CalculateComponentWidth(startComponent);

    startComponent.width = width;
    startComponent.height = height;

    generatedComponents.push(startComponent);

    let startX = 50;
    let startY = startComponent.y + height + paddingBetweenComponents;


    // Iterate over all the components 
    generatedComponents.push(...components.map((component, index) => {
        let width = CalculateComponentWidth(component);
        let height = CalculateComponentHeight(component);

        if (width > (canvas.width - 80)) {
            width = canvas.width - 80;

            // Split the text into sentences
            const maxCharsForText = width / (14 / 2.25); // 14 px font width / 2.25
            const textSentences = [];

            while (true) {
                let sentence = component.text.substring(0, Math.min(component.text.length, maxCharsForText));

                if (sentence.length == text.length) {
                    textSentences.push(sentence);
                    break;
                }

                // Check if it cut off a word
                let lastSpace = (sentence.lastIndexOf(" "))
                if (lastSpace > -1) {
                    sentence = sentence.substring(0, sentence.lastIndexOf(" "));
                }

                textSentences.push(sentence);
                text = text.substring(sentence.length).trim();

                if (text.length == 0) {
                    break;
                }
            }

            component.text = textSentences;

            // height += textSentences.length * 20;

            // Split the description into sentences
            const maxCharsForDesc = width / (12 / 2.25); // 12 px font width / 2.25
            const descSentences = [];

            while (true) {
                let sentence = component.description.substring(0, Math.min(component.description.length, maxCharsForDesc));

                if (sentence.length == description.length) {
                    descSentences.push(sentence);
                    break;
                }

                // Check if it cut off a word
                let lastSpace = (sentence.lastIndexOf(" "))
                if (lastSpace > -1) {
                    sentence = sentence.substring(0, sentence.lastIndexOf(" "));
                }

                descSentences.push(sentence);
                description = description.substring(sentence.length).trim();

                if (description.length == 0) {
                    break;
                }
            }

            description = descSentences;

            // height += descSentences.length * 20;

            height = CalculateComponentHeight({
                text: textSentences,
                description: descSentences,
            });
        }

        let componentProps = {
            ...component,

            x: startX,
            y: startY,
            
            height,
            width,
        };

        startY += height + paddingBetweenComponents;

        return componentProps;
    }));

    // The end component should be the last one
    endComponent.x = startX;
    endComponent.y = startY;

    startY += endComponent.height + paddingBetweenComponents;

    generatedComponents.push(endComponent);

    // Align the components neatly

    // Find the component with the largest width
    const largestComponent = generatedComponents.reduce((acc, component) => {
        return component.width > acc.width ? component : acc;
    }, { width: 0 });

    const midPointForAll = (largestComponent.x + largestComponent.width) / 2;

    // Ensure all components are center aligned to the previous and next component
    generatedComponents.forEach((component, index) => {
        component.x = midPointForAll - component.width / 2;
    });

    // Starting from the bottom, connect each component to the previous one
    for (let i = 1; i < generatedComponents.length; i++) {
        let from = generatedComponents[i - 1];
        let to = generatedComponents[i];

        const fromPort = from.ports.find((port) => {
            return port.type == "output";
        })

        const toPort = to.ports.find((port) => {
            return port.type == "input";
        });

        generatedArrows.push({
            from: {
                componentId: from.id,
                portId: fromPort.id,
            },
            to: {
                componentId: to.id,
                portId: toPort.id,
            },
            label: ''
        });
    }

    return {
        components: generatedComponents,
        arrows: generatedArrows,
        startX,
        startY,
    };
}