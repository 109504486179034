const ComponentTypesToAdd = [
    {
        label: "Reporting",
        options: [
            { type: "reports", value: "render_report", label: "Generate Report HTML" },
            { type: "reports", value: "save_as_pdf", label: "Generate & Save Report PDF" },
        ]
    },
    {
        label: "Querying",
        options: [
            { type: "query", label: "Run query from Query Playground" },
        ]
    },
    {
        label: "Communication",
        options: [
            { type: "communication", value: "email", label: "Send Email" },
        ]
    }
];

export default ComponentTypesToAdd;