import { drawRectangle } from "./components/rectangle";
import { drawArrow } from "./components/arrow";

// Render the flowchart on the canvas
export default function drawFlowchart({
    components,
    clickedComponent,
    arrows,
    newArrow,
    hoveredPort,
    ctx,
    canvas,
    renderDelete = false,
}) {
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Render the components
    components.forEach(component => {
        if (component.type === 'rectangle') {
            let hoveredPortId = null;

            if (hoveredPort?.component?.id == component.id) {
                // Check if the hovered port is on the component
                const port = component.ports.find(port => port.id === hoveredPort.port.id);

                if (port) {
                    hoveredPortId = port.id;
                }
            }

            drawRectangle({
                ctx,
                canvas,
                x: component.x,
                y: component.y,
                width: component.width,
                height: component.height,
                text: component.text,
                description: component.description,
                ports: component.ports,
                isClicked: component.id == clickedComponent?.id,
                hoveredPortId,
                hasError: component.hasError,
            });
        }
    });

    // Render the arrows
    arrows.forEach(arrow => {
        const from = arrow.from;
        const to = arrow.to;

        // Get the fromX and fromY coordinates based on the component
        const fromComponent = components.find(component => component.id === from.componentId);
        const toComponent = components.find(component => component.id === to.componentId);

        if (fromComponent && toComponent) {
            const fromPort = fromComponent.ports.find(port => port.id === from.portId);
            const toPort = toComponent.ports.find(port => port.id === to.portId);

            const fromX = fromComponent.x + fromPort.x * fromComponent.width;
            const fromY = fromComponent.y + fromPort.y * fromComponent.height;

            const toX = toComponent.x + toPort.x * toComponent.width;
            const toY = toComponent.y + toPort.y * toComponent.height;

            drawArrow({
                ctx,
                canvas,
                fromX,
                fromY,
                toX,
                toY,
                label: arrow.label,
                renderDelete,
            });
        }
    });

    // Render the new arrow
    if (newArrow) {
        drawArrow({
            ctx,
            canvas,
            fromX: newArrow.fromX,
            fromY: newArrow.fromY,
            toX: newArrow.toX,
            toY: newArrow.toY,
            label: newArrow.label,
            renderDelete: false,
        });
    }
}