export default function isMouseOverArrowDeleteIcon({mousePos, arrow}) {
    if(!arrow) {
        return false;
    }

    const {fromX, fromY, toX, toY} = arrow;

    let deleteIconSize = 10;

    let midX = ((fromX + toX) / 2) - (deleteIconSize / 2);
    let midY = ((fromY + toY) / 2) - (deleteIconSize / 2);

    return mousePos.x >= (midX - deleteIconSize) && mousePos.x <= (midX + deleteIconSize) &&
            mousePos.y >= (midY - deleteIconSize) && mousePos.y <= (midY + deleteIconSize);
}