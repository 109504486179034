import * as DataDelegator from "../../../../components/smart/delegator/DataDelegator";
import withLoaderData from "../../../../components/withLoaderData";

import { connect } from "react-redux";

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import moment from "moment";

import axiosBackend from "../../../../core/api/backend";
import Select from "react-select";

import RecordViewer from "components/smart/supported/RecordViewer";
import RecordDiffViewer from "components/smart/supported/RecordDiffViewer";

function ResourceHistory(props) {
    const [history, setHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [users, setUsers] = useState({});

    const [startDate, setStartDate] = useState(new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]);
    const [endDate, setEndDate] = useState(new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]); // Adding 1 day because the date time field will default to 00:00:00 when we compare with a date value

    const [selectedDatalogItem, setSelectedDatalogItem] = useState(null);
    const [filterDatalogEvents, setFilterDatalogEvents] = useState([]);

    const [columns, setColumns] = useState([]);

    useEffect(() => {
        setLoading(true);

        Promise.all([

            axiosBackend({
                method: "POST",
                url: `/service/resources/${props.router.params.appUUID}/${props.router.params.resourceName}/history/users`,
                data: {

                }
            }),
            axiosBackend.post(`/service/resources/${props.router.params.appUUID}/${props.router.params.resourceName}/fields`),
        ])
            .then(([usersFields, columnsResponse]) => {

                const applicationUsers = usersFields.data.results.reduce((acc, user) => {
                    acc[user.uuid] = user.first_name + " " + user.last_name;
                    return acc;
                }, {});

                setUsers(applicationUsers);

                setColumns(() => {
                    const columns = columnsResponse.data.results.map((column) => {
                        column.title = column.fieldName;
                        column.id = column.fieldName;

                        return column;
                    }).sort((a, b) => {
                        if (a.displayPosition === null) {
                            return 1; // move `a` to a higher index
                        } else if (b.displayPosition === null) {
                            return -1; // move `b` to a higher index
                        } else {
                            return a.displayPosition - b.displayPosition;
                        }
                    }).filter(column => column.fieldName !== "robostack_id");

                    return columns;
                });

                setLoading(false);
            })
            .catch(error => {
                console.error(error);
                setError(error);
                setLoading(false);
            });
    }, [props.router.params.appUUID, props.router.params.resourceName]);

    useEffect(() => {
        setLoading(true);

        Promise.all([
            axiosBackend.post(`/service/resources/${props.router.params.appUUID}/${props.router.params.resourceName}/history`, {
                startDate,
                endDate,
            }),

        ])
            .then(([datalog]) => {
                setHistory([
                    ...JSON.parse(JSON.stringify(history)),
                    ...datalog.data.results.sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn))
                ]);

                setLoading(false);
            })
            .catch(error => {
                console.error(error);
                setError(error);
                setLoading(false);
            });
    }, [props.router.params.appUUID, props.router.params.resourceName, startDate, endDate]);

    const loadMoreHistory = (days) => {
        setEndDate(startDate);
        setStartDate(new Date(new Date(startDate).getTime() - days * 24 * 60 * 60 * 1000).toISOString().split('T')[0]);
    }

    const smallSelectStyling = {
        control: (base) => ({
            ...base,
            minHeight: 20,
        }),
        dropdownIndicator: (base) => ({
            ...base,
            paddingTop: 0,
            paddingBottom: 0,
        }),
        clearIndicator: (base) => ({
            ...base,
            paddingTop: 0,
            paddingBottom: 0,
        }),
    };

    const historyFilters = filterDatalogEvents.map((event) => event.value);

    return <>
        <div className="col ibox m-3">
            <div className="">
                {error && <div className="alert alert-danger">Error: {error.message}</div>}

                <div className="row">
                    <div className="col-lg-6">


                        <div className=" ">
                            <div className="ibox-content mt-0 no-top-border">

                                <div className="row">
                                    <div className="col">
                                        <h4>Resource History</h4>
                                    </div>

                                    <div className="col text-right">
                                        <small>{moment(startDate).format("MMMM Do YYYY")} - Today</small>
                                    </div>


                                </div>


                                <div className="mt-2">
                                    <div className="mb-4 d-flex justify-content-end">
                                        <div className="flex-grow-1 ">
                                            <Select
                                                value={filterDatalogEvents}
                                                onChange={setFilterDatalogEvents}
                                                styles={smallSelectStyling}
                                                placeholder="Filter events shown"
                                                options={[
                                                    {
                                                        badge: "CREATE",
                                                        value: "create",
                                                        label: "New record created",
                                                    },
                                                    {
                                                        badge: "UPDATE",
                                                        value: "update",
                                                        label: "Existing record updated",
                                                    },
                                                    {
                                                        badge: "DELETE",
                                                        value: "delete",
                                                        label: "Existing record deleted",
                                                    },
                                                    {
                                                        badge: "CREATE-RESOURCE",
                                                        value: "create-resource",
                                                        label: "New resource created",
                                                    },
                                                    {
                                                        badge: "DROP",
                                                        value: "drop",
                                                        label: "Existing resource destroyed",
                                                    },
                                                    {
                                                        badge: "EDIT-RESOURCE-ADD-FIELDS",
                                                        value: "edit-resource-add-fields",
                                                        label: "Fields added to resource",
                                                    },
                                                    {
                                                        badge: "EDIT-RESOURCE-CHANGE-FIELDS",
                                                        value: "edit-resource-change-fields",
                                                        label: "Field configuration changed for resource",
                                                    },
                                                    {
                                                        badge: "EDIT-RESOURCE-DELETE-FIELDS",
                                                        value: "edit-resource-delete-fields",
                                                        label: "Fields removed from resource",
                                                    },
                                                    {
                                                        badge: "EDIT-RESOURCE-RENAMED-FIELDS",
                                                        value: "edit-resource-renamed-fields",
                                                        label: "Fields renamed in resource",
                                                    },
                                                ].sort((a, b) => a.label.localeCompare(b.label))}
                                                isMulti
                                            />
                                        </div>
                                    </div>

                                    {history.length > 0 && <>

                                        <div className="feed-activity-list">

                                            {history.filter((item) => {
                                                if (historyFilters.length == 0) {
                                                    return true;
                                                }

                                                return historyFilters.indexOf(item.operation) > -1;
                                            }).map((item, index) => {
                                                const createdBy = users[item.createdBy];

                                                return <div className={`feed-element m-0 pt-3 pointer template ${selectedDatalogItem?._id == item._id ? "active" : ""}`} key={index} onClick={() => setSelectedDatalogItem(item)}>

                                                    {item.operation === "create" && <>
                                                        <a className="float-left m-2">
                                                            <i className="fa fa-plus fa-2x"></i>
                                                        </a>

                                                        <div className="media-body ">
                                                            <small className="float-right">{moment(item.createdOn).fromNow()}</small>
                                                            <strong>{createdBy}</strong> created a&nbsp;
                                                            <Link
                                                                className="text-primary"
                                                                to={`/app/${props.router.params.appUUID}/resources/${props.router.params.resourceName}/view/robostack_id/${item.dataChangeToApply[0].robostack_id}`}>
                                                                new record
                                                            </Link>.
                                                            <br />
                                                            <small className="text-muted">
                                                                {moment(item.createdOn).format("MMMM Do YYYY, h:mm:ss a")}
                                                            </small>

                                                        </div>
                                                    </>}

                                                    {item.operation === "update" && <>
                                                        <a className="float-left m-2">
                                                            <i className="fa fa-edit fa-2x"></i>
                                                        </a>

                                                        <div className="media-body ">
                                                            <small className="float-right">{moment(item.createdOn).fromNow()}</small>
                                                            <strong>{createdBy}</strong> edited an&nbsp;
                                                            <Link
                                                                className="text-primary"
                                                                to={`/app/${props.router.params.appUUID}/resources/${props.router.params.resourceName}/view/robostack_id/${item.existingData[0].robostack_id}`}>
                                                                existing record
                                                            </Link>. <br />
                                                            <small className="text-muted">
                                                                {moment(item.createdOn).format("MMMM Do YYYY, h:mm:ss a")}
                                                            </small>

                                                        </div>
                                                    </>}

                                                    {item.operation === "delete" && <>
                                                        <a className="float-left m-2">
                                                            <i className="fa fa-trash-alt fa-2x"></i>
                                                        </a>

                                                        <div className="media-body ">
                                                            <small className="float-right">{moment(item.createdOn).fromNow()}</small>
                                                            <strong>{createdBy}</strong> deleted an existing record. <br />
                                                            <small className="text-muted">
                                                                {moment(item.createdOn).format("MMMM Do YYYY, h:mm:ss a")}
                                                            </small>

                                                        </div>
                                                    </>}

                                                    {item.operation === "create-resource" && <>
                                                        <a className="float-left m-2">
                                                            <i className="fa fa-database fa-2x"></i>
                                                        </a>

                                                        <div className="media-body ">
                                                            <small className="float-right">{moment(item.createdOn).fromNow()}</small>
                                                            <strong>{createdBy}</strong> created this resource. <br />
                                                            <small className="text-muted">
                                                                {moment(item.createdOn).format("MMMM Do YYYY, h:mm:ss a")}
                                                            </small>

                                                        </div>
                                                    </>}

                                                    {item.operation === "drop" && <>
                                                        <a className="float-left m-2">
                                                            <i className="fa fa-trash fa-2x"></i>
                                                        </a>

                                                        <div className="media-body ">
                                                            <small className="float-right">{moment(item.createdOn).fromNow()}</small>
                                                            <strong>{createdBy}</strong> destroyed this resource. <br />
                                                            <small className="text-muted">
                                                                {moment(item.createdOn).format("MMMM Do YYYY, h:mm:ss a")}
                                                            </small>

                                                        </div>
                                                    </>}

                                                    {item.operation === "edit-resource-add-fields" && <>
                                                        <a className="float-left m-2">
                                                            <i className="fa fa- fa-2x"></i>
                                                        </a>

                                                        <div className="media-body ">
                                                            <small className="float-right">{moment(item.createdOn).fromNow()}</small>
                                                            <strong>{createdBy}</strong> added fields to this resource. <br />
                                                            <small className="text-muted">
                                                                {moment(item.createdOn).format("MMMM Do YYYY, h:mm:ss a")}
                                                            </small>

                                                        </div>
                                                    </>}

                                                    {item.operation === "edit-resource-change-fields" && <>
                                                        <a className="float-left m-2">
                                                            <i className="fa fa- fa-2x"></i>
                                                        </a>

                                                        <div className="media-body ">
                                                            <small className="float-right">{moment(item.createdOn).fromNow()}</small>
                                                            <strong>{createdBy}</strong> changed the field configuration of this resource. <br />
                                                            <small className="text-muted">
                                                                {moment(item.createdOn).format("MMMM Do YYYY, h:mm:ss a")}
                                                            </small>

                                                        </div>
                                                    </>}

                                                    {item.operation === "edit-resource-delete-fields" && <>
                                                        <a className="float-left m-2">
                                                            <i className="fa fa- fa-2x"></i>
                                                        </a>

                                                        <div className="media-body ">
                                                            <small className="float-right">{moment(item.createdOn).fromNow()}</small>
                                                            <strong>{createdBy}</strong> removed fields from this resource. <br />
                                                            <small className="text-muted">
                                                                {moment(item.createdOn).format("MMMM Do YYYY, h:mm:ss a")}
                                                            </small>

                                                        </div>
                                                    </>}

                                                    {item.operation === "edit-resource-renamed-fields" && <>
                                                        <a className="float-left m-2">
                                                            <i className="fa fa- fa-2x"></i>
                                                        </a>

                                                        <div className="media-body ">
                                                            <small className="float-right">{moment(item.createdOn).fromNow()}</small>
                                                            <strong>{createdBy}</strong> renamed fields of this resource. <br />
                                                            <small className="text-muted">
                                                                {moment(item.createdOn).format("MMMM Do YYYY, h:mm:ss a")}
                                                            </small>

                                                        </div>
                                                    </>}

                                                    <div className="">
                                                        <small>Datalog ID: {item._id}</small>
                                                    </div>

                                                </div>
                                            })}
                                        </div>
                                    </>}

                                    {history.length == 0 && <>
                                        No history found.
                                    </>}
                                </div>

                                <div className="text-center mt-2">
                                    {loading && <div className="loader"></div>}

                                    <button className="btn btn-primary" onClick={() => loadMoreHistory(7)} disabled={loading}>
                                        {loading ? "Loading..." : "Load more history"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="ibox-content client-detail ">

                            <h4>Event Details</h4>
                            {selectedDatalogItem == null ? "Select an event to view details about it" :

                                <>
                                    {selectedDatalogItem.operation === "create" && <>
                                        <RecordViewer
                                            columns={columns}
                                            data={selectedDatalogItem.dataChangeToApply}
                                            applicationUsers={users}
                                            hidePrintButton={true}
                                            hideEditButton={true}
                                            hideTitle={true}
                                        />
                                    </>}

                                    {selectedDatalogItem.operation === "delete" && <>
                                        <RecordViewer
                                            columns={columns}
                                            data={selectedDatalogItem.existingData}
                                            applicationUsers={users}
                                            hidePrintButton={true}
                                            hideEditButton={true}
                                            hideTitle={true}
                                        />
                                    </>}

                                    {selectedDatalogItem.operation === "update" && <>
                                        <RecordDiffViewer
                                            columns={columns}
                                            old={selectedDatalogItem.existingData}
                                            new={selectedDatalogItem.dataChangeToApply}
                                            applicationUsers={users}
                                        />
                                    </>}

                                    {selectedDatalogItem.operation === "create-resource" && <>
                                        <div className="">
                                            This resource was created
                                        </div>
                                    </>}

                                    {selectedDatalogItem.operation === "drop" && <>
                                        <div className="">
                                            This resource was destroyed
                                        </div>
                                    </>}

                                    {selectedDatalogItem.operation === "edit-resource-add-fields" && <>
                                        <div className="">
                                            Fields were added to this resource
                                        </div>
                                    </>}

                                    {selectedDatalogItem.operation === "edit-resource-change-fields" && <>
                                        <div className="">
                                            Field configuration was changed for this resource
                                        </div>
                                    </>}

                                    {selectedDatalogItem.operation === "edit-resource-delete-fields" && <>
                                        <div className="">
                                            Fields were removed from this resource
                                        </div>
                                    </>}

                                    {selectedDatalogItem.operation === "edit-resource-renamed-fields" && <>
                                        <div className="">
                                            Fields were renamed in this resource
                                        </div>
                                    </>}
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>;

}

export default withLoaderData(connect(DataDelegator.mapStateToProps)(ResourceHistory));